<template>
  <div id="groupvisit">
    <div class="hk-sec-wrapper">
      <VueDatePicker
        v-model="dates"
        locale="ru-RU"
        auto-apply
        inline
        @update:model-value="onDayClick"
        calendarCellClassName="form-control"
        inputClassName="form-control"
        autoPosition
        position="left"
        :dark="version == 'dark'"
        model-type="dd.MM.yyyy"
      ></VueDatePicker>
      <div id="table" class="mt-1">
        <span class="font-weight-bold">{{ dates }}</span>
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              ref="table"
              border="1"
              class="table table-bordered wb"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["version", "loadchange", "logoutweb"],
  data() {
    return {
      ocenka: "",
      dates: "",
    };
  },
  updated: function () {
    let ref = this;
    this.$nextTick(function () {
      if (typeof ref.$refs.table != "undefined") {
        if (document.querySelectorAll(".getstarostapos").length) {
          document.querySelectorAll(".getstarostapos").forEach((el) => {
            el.addEventListener("change", (ele) => {
              this.selectchange(ele.target.dataset.stud, ele.target.value);
            });
          });
        }
      }
    });
  },
  methods: {
    selectchange(stud, pos) {
      server
        .submittoserver(
          `${
            server.urlsecond.student.setstarostapos + stud.split("-").join("/")
          }/${pos}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "groupvisit", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    onDayClick() {
      this.ocenka = "";
      this.getstarostapos(this.dates);
    },

    getstarostapos(dates) {
      this.loadchange(false);
      server
        .submittoserver(
          server.urlsecond.student.getstarostapos + dates,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let vm = this;

          this.ocenka = res;
          // this.changesel(vm);
          this.loadchange(true);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
          this.loadchange(true);
        });
    },
  },
};
</script>
