<template>
  <div id="education">
    <div class="hk-sec-wrapper">
      <form>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="Dokpred">Документ предшествующего образования</label>
            <select
              class="form-control form-select"
              id="dokpred"
              name="dokpred"
              placeholder="Документ предшествующего образования"
              v-model="getobraz.Dokpred"
            >
              <option disabled></option>
              <option v-for="(e, key) in getdokpred" :key="key" :value="e.id">
                {{ e.dokpred }}
              </option>
            </select>
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group col-md-6">
            <label for="godokon">Год окончания</label>
            <input
              class="form-control"
              id="godokon"
              placeholder="Год окончания"
              name="godokon"
              type="text"
              v-model="getobraz.Godokon"
            />
            <div class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="nabaze">База поступления</label>
            <select
              class="form-control form-select"
              id="nabaze"
              name="nabaze"
              v-model="getobraz.Nabaze"
            >
              <option selected disabled></option>
              <option v-for="(e, key) in getnabaze" :key="key" :value="e.id">
                {{ e.nabaze }}
              </option>
            </select>
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group col-md-12">
            <label for="num">Образовательная организация</label>

            <multiselect
              v-model="obraz"
              deselect-label=""
              track-by="num"
              label="num"
              :disabled="blocked"
              placeholder="Выберите образовательную организацию"
              :clear-on-select="false"
              :options="getnum"
              :multiple="false"
              :close-on-select="true"
              :searchable="true"
              :allow-empty="false"
              selectLabel=""
              @select="changeobraz"
              @input="obraznew = $event.target.value"
              @close="close"
              ref="multiselect"
              selectedLabel=""
            >
              <template #noResult>
                Ничего не найдено.
                <button
                  class="btn btn-primary btn-xxs"
                  @click="addobraz"
                  type="button"
                >
                  Добавить
                </button>
              </template></multiselect
            >

            <div class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="serial" class="mb-10">Серия документа</label>
            <input
              class="form-control"
              id="serial"
              placeholder="Серия документа"
              name="serial"
              type="text"
              v-model="getobraz.Serial"
            />
            <div class="invalid-feedback"></div>
          </div>
          <div class="form-group col-md-6">
            <label for="numer">Номер документа</label>
            <input
              class="form-control"
              id="numer"
              placeholder="Номер документа"
              name="numer"
              type="text"
              v-model="getobraz.Numer"
            />
            <div class="invalid-feedback"></div>
          </div>
        </div>
        <button
          class="btn btn-primary mt-3"
          type="button"
          @click="educationupdate"
        >
          Сохранить
        </button>
      </form>
    </div>
    <div class="hk-pg-header align-items-top mt-xl-50 mt-sm-30 mt-15">
      <div>
        <h2 class="hk-pg-title font-weight-600 mb-10">Инструкция</h2>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <p>Внесите необходимые данные из документа об образовании</p>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
export default {
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      getnum: [],
      getdokpred: [],
      getnabaze: [],
      getobraz: [],
      obraznew: null,
      obraz: null,
    };
  },
  mounted() {
    this.education();
    server.dis(this.blocked, "education");
  },
  methods: {
    close() {
      this.$refs.multiselect.deactivate();
    },
    chadd(value) {
      this.obraznew = value;
    },
    changeobraz() {
      this.getobraz.Num = this.obraz.id;
    },
    addobraz() {
      server
        .submittoserver(
          server.urlsecond.abiturient.setdescrnum,
          { num: this.obraznew },
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.mess, "success");
          this.obraz = { id: res.id, num: this.obraznew };
          this.getobraz.Num = res.id;
          this.close();
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    education() {
      this.loadchange(false);
      server.messages({}, "education");

      server
        .submittoserver(server.urlsecond.abiturient.getobraz)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getobraz = res[0];
          server
            .submittoserver(server.urlsecond.abiturient.getobrazlist)
            .then((res) => {
              if (res.error && res.error == 403) {
                this.logoutweb();
              }
              this.getnum = res.num;
              this.getdokpred = res.dokpred;
              this.getnabaze = res.nabaze;
              if (this.getobraz.Num)
                this.obraz = this.getnum.find((x) => x.id == this.getobraz.Num);
            })
            .catch(() => {
              this.loadchange(true);
              server.toasts("Ошибка на сервере", "error");
            });
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });

      this.loadchange(true);
    },
    educationupdate() {
      server.dis(true, "education");

      let n = {
        dokpred: this.getobraz.Dokpred,
        godokon: this.getobraz.Godokon,
        nabaze: this.getobraz.Nabaze,
        num: this.getobraz.Num,
        numer: this.getobraz.Numer,
        serial: this.getobraz.Serial,
      };
      server
        .submittoserver(server.urlsecond.abiturient.setobraz, n, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          server.messages(res, "education", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
