<template>
  <div id="svodinforesultpos">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <select
            class="form-control"
            @change="svodinforesultpos"
            v-model="selectperiod"
          >
            <option
              :value="{ d: val.d, m: val.m }"
              v-for="val in periodss"
              :key="val.d"
            >
              {{ val.m }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper" v-if="ocenka">
      <div id="table" class="mt-1">
        <div id="resource">
          <h2 style="text-align: center">
            Сводная информация по результатам посещаемости за
            {{ selectperiod.m }}
          </h2>

          <div class="mt10 table-responsive">
            <table border="1" class="table tableexport table-bordered wb">
              <thead>
                <tr>
                  <th>Группа</th>
                  <th>Ф.И.О. классного руководителя</th>
                  <th>Количество человек по списку</th>
                  <th>Всего пропусков (час.)</th>
                  <th>Всего пропусков на 1 чел (час)</th>
                  <th>В том числе прогулов (час)</th>
                  <th>Прогулов на 1 чел (час)</th>
                </tr>
              </thead>
              <tbody v-html="ocenka.tab"></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      ocenka: "",
      selectperiod: "",
      periodss: "",
    };
  },
  mounted() {
    this.getdates();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    getdates() {
      server
        .submittoserver(
          `${
            server.urlsecond.prepod
              .hp_report_webPrepodklassruk_net_admin_allgr + this.d1
          }/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.periodss = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    svodinforesultpos() {
      this.ocenka = "";
      this.loadchange(false);
      server
        .submittoserver(
          server.urlsecond.prepod.svodinforesultpos + this.selectperiod.d,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
