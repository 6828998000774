<template >
  <div id="RegisterForm">
  <h4 class="text-center">Регистрация</h4>
  <p class="text-center">Используется только для абитуриентов</p>
  <div class="row mb-sm-3">
    <div class="col-md-6 form-group">
      <label class="form-label" for="fam">Фамилия</label>
      <input
        class="form-control"
        placeholder="Фамилия"
        value=""
        autocomplete="off"
        type="text"
        name="fam"
        if="fam"
        v-model="fam"
      />
      <div class="invalid-feedback"></div>
    </div>
    <div class="col-md-6 form-group">
      <label class="form-label" for="name">Имя</label>
      <input
        class="form-control"
        placeholder="Имя"
        value=""
        autocomplete="off"
        type="text"
        name="name"
        id="name"
        v-model="name"
      />
      <div class="invalid-feedback"></div>
    </div>
  </div>

  <div class="mb-sm-3 form-group position-relative">
    <label class="form-label" for="email">Email</label>
    <input
      type="email"
      class="form-control"
      placeholder="hello@example.com"
      id="email"
      name="email"
      v-model="email"
    />
    <div class="invalid-feedback"></div>
  </div>
  <div class="mb-sm-4 mb-3 position-relative">
    <label class="form-label" for="phone">Телефон</label>
    <input
              v-maska
              class="form-control"
              v-model="phone"
              placeholder="Телефон"
              data-maska="# (###) ###-####"
              id="phone"
              name="phone"
              type="text"
            />
    <div class="invalid-feedback"></div>
    
  </div>
  <div class="form-check custom-checkbox mb-3 checkbox-success">
    <input
      class="form-check-input"
      id="same-address"
      type="checkbox"
      v-model="agree"
      checked
      required
    />
    <label class="form-check-label" for="same-address"
      >Я ознакомился с
    </label>
    <span>
      <a
        target="_blank"
        href="https://chgpgt.ru/abiturientu/postuplenie/pravila-priema.html"
        >&nbsp;<u>правилами приёма</u></a
      ></span
    >
  </div>

  <div class="text-center">
    <button
      type="submit"
      class="btn btn-primary btn-block"
      @click.prevent="register"
    >
      Зарегистрироваться
    </button>
  </div>

  <div class="new-account mt-3">
    <p class="form-label">
      Уже есть аккаунт?
      <a class="text-primary" href="#" @click.prevent="changePage('AuthForm')"
        >Войти</a
      >
    </p>
    <p class="text-center pb-2">
      <a target="_blank" href="https://chgpgt.ru/Работа в личном кабинете.pdf"
        >Инструкция по работе в личном кабинете</a
      >
    </p>
  </div>
</div>
</template>
<script setup>
import { vMaska } from "maska";
</script>
<script>
import server from "../assets/js/server";
export default {
  props: ["changePage"],
  data() {
    return {
      fam: null,
      name: null,
      email: null,
      phone: null,
      agree: false,
    };
  },
  methods: {
    register() {
      if(!this.agree){
        server.toasts("Вы не ознакомились с правилами приёма!", "error");
        return false;
      }
      server.dis(true, "RegisterForm");
      let ob = {
        fam: this.fam,
        name: this.name,
        email: this.email,
        phone: this.phone,
      };
      server
        .submittoserver(server.urlsecond.register, ob)
        .then((res) => {
          server.messages(res, "RegisterForm", [
            { mess: "mess", class: "success" },
          ]);
          
          if (res.mess) {
            server.toasts("Проверьте почту, в том числе папку Спам", "info");
            this.changePage('AuthForm');
          }
          server.dis(false, "RegisterForm");
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
          server.dis(false, "RegisterForm");
        });
    },
  },
};
</script>
