<template>
  <div id="profile">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-xl-3 col-lg-4">
          <div class="clearfix">
            <div class="card card-bx author-profile m-b30">
              <div class="card-body">
                <div class="p-3">
                  <div class="author-profile">
                    <div class="author-media">
                      <img
                        class="avatar"
                        v-if="!avatar"
                        src="@/assets/images/user.png"
                        alt=""
                      />
                      <img class="avatar" v-else :src="avatar" />
                    </div>
                    <div class="author-info">
                      <h6 class="title">{{ fio }}</h6>
                      <span>{{ roletext }}</span>
                    </div>
                  </div>
                </div>
                <div class="mx-2">
                  <Cropperjs
                    ref="childComponent"
                    :changeavatar="changeavatar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-lg-8">
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Изменение пароля</h4>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="password">Новый пароль</label>
                    <input
                      class="form-control"
                      id="password"
                      placeholder="Новый пароль"
                      name="password"
                      v-model="password"
                      type="password"
                    />
                    <div class="invalid-feedback"></div>
                  </div>
                  <div class="form-group">
                    <label for="password_confirmation">Повторите пароль</label>
                    <input
                      class="form-control"
                      id="password_confirmation"
                      placeholder="Повторите пароль"
                      name="password_confirmation"
                      v-model="password_confirmation"
                      type="password"
                    />
                    <div class="invalid-feedback"></div>
                  </div>
                  <div
                    class="toolbar toolbar-bottom text-end mt-4"
                    role="toolbar"
                  >
                    <button
                      class="btn btn-primary mt-15"
                      type="button"
                      @click="changepassword"
                      :disabled="false"
                    >
                      Изменить
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card">
                <div class="card-header">
                  <h4 class="card-title">Изменение данных</h4>
                </div>
                <div class="card-body">
                  <div class="form-group">
                    <label for="email"
                      >Электронная почта (используется для входа и
                      восстановления доступа):</label
                    >
                    <input
                      class="form-control"
                      id="email"
                      placeholder="Электронная почта"
                      name="email"
                      v-model="email"
                      type="text"
                    />
                    <div class="invalid-feedback"></div>
                  </div>
                  <div
                    class="toolbar toolbar-bottom text-end mt-4"
                    role="toolbar"
                  >
                    <button
                      class="btn btn-primary mt-15"
                      type="button"
                      @click="changedata"
                      :disabled="false"
                    >
                      Сохранить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import server from "@/assets/js/server";
import Cropperjs from "@/components/Cropperjs.vue";
export default {
  props: ["roletext", "fio", "avatar", "logoutweb"],
  components: { Cropperjs },
  data() {
    return {
      password: null,
      password_confirmation: null,
      email: null,
    };
  },
  methods: {
    changepassword() {
      if (this.password != this.password_confirmation) {
        server.toasts("Пароли не совпадают!", "error");
        return false;
      }

      let ob = {
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      server
        .submittoserver(server.urlsecond.user.userchangepassword, ob)
        .then((res) => {
          // console.log(res);
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
          this.password = null;
          this.password_confirmation = null;
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
          server.dis(false, "profile");
        });
    },

    changeavatar() {
      let formData = new FormData();
      this.$refs.childComponent.cropImage();
      // this.$refs.childComponent.getCroppedCanvas();
      if (this.$refs.childComponent.cropImg) {
        formData.append("avatar", this.$refs.childComponent.cropImg);

        var fr = new FileReader();
        fr.onload = function (readerEvent) {
          let doc = document.querySelectorAll(".avatar");
          doc.forEach((el) => {
            el.src = fr.result;
          });
        };
        fr.readAsDataURL(this.$refs.childComponent.cropImg);
      }

      server
        .submittoserver(
          server.urlsecond.user.userchangedataavatar,
          formData,
          "POST",
          true
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
          server.dis(false, "profile");
        });
    },

    changedata() {
      server.dis(true, "profile");
      let ob = { email: this.email };

      server
        .submittoserver(server.urlsecond.user.userchangedata, ob, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "profile", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
          server.dis(false, "profile");
        });
    },
  },
};
</script>