<template>
  <div id="violationstudents">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-md-3">
          <VueDatePicker
            v-model="dates"
            locale="ru-RU"
            auto-apply
            inline
            @update:model-value="onDayClick"
            calendarCellClassName="form-control"
            inputClassName="form-control"
            autoPosition
            position="left"
            :dark="version == 'dark'"
            model-type="dd.MM.yyyy"
          ></VueDatePicker>
        </div>
        <div class="col-md-3" v-if="dates">
          <select
            class="form-control groups form-select"
            v-model="groups.selected"
            @change="onDayClick1"
          >
            <option
              v-for="(vs, k) in groups.options"
              :selected="k == 0"
              :key="vs.kod"
              :value="vs.kod"
            >
              {{ vs.naim }}
            </option>
          </select>
        </div>
      </div>

      <div id="table" class="mt-1">
        <span class="font-weight-bold">{{ dates }}</span>
        <div id="resource">
          <div class="mt10 table-responsive">
            <table border="1" v-if="ocenka" class="table table-bordered">
              <thead>
                <tr>
                  <td>Студент</td>
                  <td>Нарушение</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, k) in ocenka" :key="k">
                  <td>{{ val.FIO }}</td>
                  <td>
                    <select
                      @change="addnar(val.stud, k)"
                      class="form-control form-select"
                    >
                      <option value="0"></option>
                      <option
                        v-for="vs in nar.options"
                        :key="vs.id"
                        :value="vs.id"
                      >
                        {{ vs.vid }}
                      </option>
                    </select>
                    <div v-for="(vals, keys) in val.nar" :key="keys">
                      <a
                        class="btn btn-danger btn-xxs delnar"
                        @click="removenar(val.stud, vals.key, k, keys)"
                        >{{ vals.vid }} <vue-feather type="trash"></vue-feather
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["d1", "d2", "version", "loadchange", "logoutweb"],
  components: {},
  data() {
    return {
      ocenka: "",
      dates: "",
      groups: {
        selected: 0,
        options: [],
      },

      nar: {
        options: [],
      },
    };
  },
  mounted() {
    this.getgroups();
    this.getvidnar();
  },
  updated: function () {
    if (document.querySelectorAll(".getklasspos").length) {
      document.querySelectorAll(".getklasspos").forEach((el) => {
        el.addEventListener("change", (ele) => {
          this.selectchange(ele.target.dataset.stud, ele.target.value);
        });
      });
    }
  },
  methods: {
    addnar(stud, k) {
      if (event.target.value == 0) {
        return;
      }
      let a = {
        key: this.nar.options.filter((e) => e.id == event.target.value)[0].id,
        vid: this.nar.options.filter((e) => e.id == event.target.value)[0].vid,
      };
      server
        .submittoserver(
          `${server.urlsecond.prepod.addnar + this.dates}/${stud}/${
            event.target.value
          }`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.message == 1) {
            server.toasts("Добавлено", "success");
            this.ocenka[k].nar.push(a);
          } else {
            server.toasts("Дважды добавлять запрещено", "danger");
          }
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getvidnar() {
      server
        .submittoserver(server.urlsecond.prepod.getvidnar, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.nar.options = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getgroups() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroupsklass + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups.options = res;
          this.groups.selected = this.groups.options[0].kod;
          this.loadchange(true);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    removenar(stud, nar, k, keys) {
      server
        .submittoserver(
          `${server.urlsecond.prepod.removenar + this.dates}/${stud}/${nar}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
          this.ocenka[k].nar.splice(keys, 1);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    selectchange(stud, pos) {
      server
        .submittoserver(
          `${
            server.urlsecond.student.setstarostapos + stud.split("-").join("/")
          }/${pos}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "violationstudents", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    onDayClick() {
      this.ocenka = "";
      this.getklasspos(this.dates);
    },
    onDayClick1() {
      this.getklasspos(this.dates);
    },

    getklasspos(dates) {
      this.loadchange(false);
      server
        .submittoserver(
          `${
            server.urlsecond.prepod.getnarpos + this.groups.selected
          }/${dates}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
