import {toast} from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const server = {
    // server: "https://api.chgpgt.ru/api/",
    server: "https://newapi.chgpgt.ru/api/",
    ftp: "https://chgpgt.ru/files/",
    error: "Извините, произошла ошибка",
    urlsecond: {
        register: "register",
        login: "login",
        themeset: "themeset",
        logout: "logout",
        reset: "reset",
        searchusers: "prepod/searchusers",
        passwordemail: "password/email",
        passwordreset: "password/reset",
        show: "show",
        getproc: "getproc",
        login3: "prepod/login3",
        getstatus: "getstatus",
        allmenu: "allmenu",
        newmenu: "newmenu",
        updatemenu: "updatemenu/",
        removemenu: "removemenu/",
        addmenurole: "addmenurole/",
        removemenurole: "removemenurole/",
        allmenuuser: "allmenuuser",
        allmenuuserid: "allmenuuser/",
        menuwithrolenew: "menuwithrolenew",
        getAllDates: "getAllDates",
        getAllRaspisan: "getAllRaspisan/",
        getView: "getView/",
        downloadktp: "downloadktp/",
        downloadktpcsv: "downloadktpcsv/",
        getmerouser: "getmerouser",
        abiturient: {
            abiturientupdate1: "abiturient/update1",
            abiturient: "abiturient",
            getspec: "abiturient/getspec",
            getspec1: "abiturient/getspec1",
            getlang: "abiturient/getlang",
            getpass: "abiturient/getpass",
            getkemvidan: "abiturient/getkemvidan",
            getkemvidanstore: "abiturient/getkemvidan/store",
            setpass: "abiturient/setpass",
            getstrana: "abiturient/getstrana",
            getaddresslist: "abiturient/getaddresslist",
            getpasportlist: "abiturient/getpasportlist",
            getdescroblast: "abiturient/getdescroblast",
            getdescrrayon: "abiturient/getdescrrayon",
            getdescrgorod: "abiturient/getdescrgorod",
            getdescrulica: "abiturient/getdescrulica",
            setdescroblast: "abiturient/setdescroblast",
            setdescrrayon: "abiturient/setdescrrayon",
            setdescrgorod: "abiturient/setdescrgorod",
            setdescrulica: "abiturient/setdescrulica",
            getadr: "abiturient/getadr/",
            setadr: "abiturient/setadr",
            getobraz: "abiturient/getobraz",
            getnum: "abiturient/getnum",
            setdescrnum: "abiturient/setdescrnum",
            setobraz: "abiturient/setobraz",
            getobrazlist: "abiturient/getobrazlist",
            getocenk: "abiturient/getocenk",
            getdscrocenk: "abiturient/getdscrocenk",
            setocenk: "abiturient/setocenk",
            getavg: "abiturient/getavg",
            getphotovid: "abiturient/getphotovid",
            getphotoall: "abiturient/getphotoall",
            getphoto: "abiturient/getphoto/",
            setphoto: "abiturient/setphoto/",
            removephoto: "abiturient/removephoto/",
            addspec: "abiturient/addspec",
            removespec: "abiturient/removespec/",
            getspecall: "abiturient/getspecall",
            getreportforuser: "abiturient/getreportforuser",
            getposition: "abiturient/getposition",
            getquestionabit: "abiturient/getquestionabit",
            getquestionabitfull: "abiturient/getquestionabitfull",
            setquestionabit: "abiturient/setquestionabit",
            abitureintout: "abiturient/abitureintout",
            getsirota: "abiturient/getsirota",
            getdd: "abiturient/getdd",
            setsirota: "abiturient/setsirota",
            setdd: "abiturient/setdd",
            getinvalid: "abiturient/getinvalid",
            setinvalid: "abiturient/setinvalid",
            getrod: "abiturient/getrod",
            setrod: "abiturient/setrod"
        },
        student: {
            getocenkastud: "student/report/getocenkastud/",
            getpredvuspev: "student/report/getpredvuspev/",
            basicschedule: "student/report/basicschedule/",
            getdostigstudent: "student/report/getdostigstudent/",
            finalperformance: "student/report/finalperformance",
            violations: "student/report/violations/",
            attendance: "student/report/attendance/",
            getstarostapos: "student/report/getstarostapos/",
            setstarostapos: "set/setstarostapos/",
            setstarostapos_all: "set/setstarostapos_all/"
        },
        prepod: {
            basicschedule: "prepod/report/basicschedule/",
            uploadtheme: "prepod/uploadtheme",
            reportsem: "prepod/report/reportsem",
            savestarosta: "prepod/savestarosta",
            
            insertplanning: "prepod/insertplanning",
            getdescriptions: "prepod/getdescriptions",
            updateplanning: "prepod/updateplanning",
            schoolattestat: "prepod/report/schoolattestat/",
            setocenkaall: "prepod/setocenkaall/",
            studentproject: "prepod/studentproject",
            studentnewgto: "prepod/studentnewgto",
            reportpolyclinic: "prepod/report/reportpolyclinic",
            reporthours: "prepod/report/reporthours/",
            instructorsreportonprogressandproofreading: "prepod/report/instructorsreportonprogressandproofreading/",
            getgroups: "prepod/getgroups/",
            getfirstktp: "prepod/getfirstktp",
            hp_get_all_ktp_groups: "prepod/hp_get_all_ktp_groups/",
            updownktp: "prepod/updownktp/",
            hp_getauthorktp_select_get: "prepod/hp_getauthorktp_select_get",
            getauthorktp: "prepod/getauthorktp",
            importgo: "prepod/importgo/",
            getauthorktpselect: "prepod/getauthorktpselect",
            csvparser: "prepod/csvparser",
            hp_get_all_ktp: "prepod/hp_get_all_ktp/",
            saverazdel: "prepod/saverazdel",
            savektp: "prepod/savektp",
            reportktp: "prepod/reportktp/",
            reportktpall: "prepod/reportktpall/",
            addnewktp: "prepod/addnewktp",
            removektp: "prepod/removektp/",
            getuspev: "prepod/getuspev/",
            setocenka: "prepod/setocenka/",
            setitogocenka: "prepod/setitogocenka/",
            getword: "prepod/getword/",
            getreportkurs: "prepod/getreportkurs/",
            getreportprakt: "prepod/getreportprakt/",
            setheme: "prepod/setheme/",
            getkurs: "prepod/getkurs/",
            getktp: "prepod/getktp/",
            getlistgroupskurs: "prepod/getlistgroupskurs/",
            getthemeskurs: "prepod/getthemeskurs/",
            getocenkakurs: "prepod/getocenkakurs",
            setthemestud: "prepod/setthemestud",
            setnewthemekurs: "prepod/setnewthemekurs",
            getlistdiploms: "prepod/getlistdiploms/",
            savediplom: "prepod/savediplom",
            getlistpractic: "prepod/getlistpractic/",
            getlistgroupsprakt: "prepod/getlistgroupsprakt/",
            savepraktstud: "prepod/savepraktstud",
            getgroupsklass: "prepod/getgroupsklass/",
            getklasspos: "prepod/getklasspos/",
            getpersonaldata: "prepod/getpersonaldata/",
            savepersonaldata: "prepod/savepersonaldata",
            generatenepassword: "prepod/generatenepassword/",
            generatepass: "prepod/generatepass/",
            getplansetka: "prepod/getplansetka/",
            getgroupsklassruk: "prepod/getgroupsklassruk/",
            getstudgroups: "prepod/getstudgroups/",
            getocenkastud: "prepod/getocenkastud/",
            getdostig: "prepod/getdostig/",
            reitstudbbm: "prepod/reitstudbbm/",
            preproofreading: "prepod/preproofreading/",
            consolidatedstatement: "prepod/consolidatedstatement/",
            percentageofprogress: "prepod/percentageofprogress/",
            averageacademicperformance: "prepod/averageacademicperformance/",
            avgrepoduspev: "prepod/avgrepoduspev/",
            getnarpos: "prepod/getnarpos/",
            removenar: "prepod/removenar/",
            getvidnar: "prepod/getvidnar",
            addnar: "prepod/addnar/",
            getprepod: "prepod/getprepod/",
            reporthours_admin: "prepod/reporthours_admin/",
            preliminarygrades: "prepod/preliminarygrades/",
            getgroupsklass1: "prepod/getgroupsklass1/",
            monthlyvisit: "prepod/monthlyvisit/",
            informationaboutstudents: "prepod/informationaboutstudents/",
            studentprofile: "prepod/studentprofile/",
            grouplistwithpassportdata: "prepod/grouplistwithpassportdata/",
            achievementsofstudents: "prepod/achievementsofstudents/",
            achievementsofstudentsduringtheperiod: "prepod/achievementsofstudentsduringtheperiod/",
            violationreport: "prepod/violationreport/",
            visitduringtheperiod: "prepod/visitduringtheperiod/",
            hometeacherreport: "prepod/hometeacherreport/",
            hp_get_my_klassruk: "prepod/hp_get_my_klassruk/",
            posuspevforperiod: "prepod/posuspevforperiod/",
            svodvedprmatt: "prepod/svodvedprmatt/",
            reportklassrukindall: "prepod/reportklassrukindall/",
            finalscholarshipperformance: "prepod/finalscholarshipperformance/",
            averageacademicperformancebyyears: "prepod/averageacademicperformancebyyears",
            summarysheetofBBMattendance: "prepod/summarysheetofBBMattendance/",
            bbmsemestersummaryreport: "prepod/bbmsemestersummaryreport/",
            groupsocialpassport: "prepod/groupsocialpassport/",
            promuspev: "prepod/promuspev/",
            uchastrepotrall: "prepod/uchastrepotrall/",
            getviddocum: "prepod/getviddocum",
            removefilesuser: "prepod/removefilesuser/",
            getallgroups: "prepod/getallgroups",
            getproject: "prepod/getproject",
            menuget: "prepod/menuget/",
            removedoprole: "prepod/removedoprole/",
            adddoprole: "prepod/adddoprole/",
            planning: "prepod/planning/",
            getmero: "prepod/getmero/",
            getvidmero: "prepod/getvidmero",
            getnapravmero: "prepod/getnapravmero",
            geturovenmero: "prepod/geturovenmero",
            getuz: "prepod/UZ",
            getalluserbbm: "prepod/getalluserbbm",
            mestosall: "prepod/mestosall",
            getsvedfromprikaz: "prepod/getsvedfromprikaz/",
            removeprojectuser: "prepod/removeprojectuser/",
            getallpredmets: "prepod/getallpredmets",
            getgto: "prepod/getgto",
            removegtouser: "prepod/removegtouser/",
            getallznach: "prepod/getallznach",
            svodallreport: "prepod/svodallreport/",
            graphicalpresentationofprogress: "prepod/graphicalpresentationofprogress/",
            hp_report_webPrepodklassruk_net_admin_allgr: "prepod/hp_report_webPrepodklassruk_net_admin_allgr/",
            svodinforesultpos: "prepod/svodinforesultpos/",
            bbmrating: "prepod/bbmrating/",
            generatenepasswordrod: "prepod/generatenepasswordrod/",
            analitable: "prepod/analitable"
        },
        user: {
            userchangepassword: "user/changepassword",
            userchangedata: "user/changedata",
            userchangedataavatar: "user/changedataavatar"
        }
    },
    async submittoserver(url, data = {}, method = "POST", file = false) {
        let myHeaders = {};
        if (localStorage.getItem("token")) {
            myHeaders.Authorization = `Bearer ${
                localStorage.getItem("token")
            }`;
        }
        if (!file) {
            myHeaders["Content-Type"] = "application/json";  
            myHeaders.Accept = "application/json";
        }
        let body = file ? data : JSON.stringify(data);
        let response = await fetch(server.server + url, {
            method: method,
            headers: myHeaders,
            body: body
        });
        if (response.status == 403) {
            return {error: 403};
        }
        return await response.json();
    },
    async submittoserverblob(url, method = "POST") {
        let myHeaders = {};
        if (localStorage.getItem("token")) {
            myHeaders.Authorization = `Bearer ${
                localStorage.getItem("token")
            }`;
        }
        // if (!file) 
        //     myHeaders["Content-Type"] = "application/json";  
        // let body = file ? data : JSON.stringify(data);
        let response = await fetch(server.server + url, {
            method: method,
            headers: myHeaders
        });
        if (response.status == 403) {
            return {error: 403};
        }
        return await response.blob();
    },

    changeborder() {
        document.querySelectorAll("#table table,#table table th,#table table td").forEach(el=>{
            el.style.cssText = "border: 1px solid #000;border-collapse:collapse;";
        });
    },
    changeborder_default() {
        document.querySelectorAll("#table table,#table table th,#table table td").forEach(el=>{el.style.cssText = "border-color: var(--border);";});
    },

    closewindow(element, visible = false) {
        let mymodal = document.getElementById(element);
        let modal = bootstrap.Modal.getInstance(mymodal);

        if (! modal) {
            modal = new bootstrap.Modal(document.getElementById(element));
        }
        if (!visible) {
            modal.hide();
        } else {
            modal.show();
        }
    },

    dis(a, b) {
        document.querySelectorAll(`#${b} input, #${b} select, #${b} button`).forEach(el => {
            el.disabled = a;
        });
    },
    toasts(mess, mclass) {
        let p = {
            autoClose: 2000,
            theme: localStorage.getItem("version") ?? "light"
        };

        switch (mclass) {
            case "error":
                toast.error(mess, p);
                break;
            case "warning":
                toast.warning(mess, p);
                break;
            case "info":
                toast.info(mess, p);
                break;
            case "success":
                toast.success(mess, p);
                break;
            default:
                toast.success(mess, p);
                break;
        }
    },
    messages(res, form, mess = []) {
        server.dis(false, form);

        document.querySelectorAll(`#${form} input.form-control, #${form} select.form-control`).forEach(el => {
            el.classList.remove("is-invalid");
            if (el.nextElementSibling) 
                if (el.nextSibling.classList.contains('invalid-feedback')) {
                    el.nextSibling.innerHTML = "";
                }
            

        });

        document.querySelectorAll(`#${form} input.multiselect__input, #${form} select.multiselect__input`).forEach(el => {
            let multi = el.parentElement;
            multi.classList.remove("is-invalid");

            if (multi.parentElement.nextElementSibling) 
                if (multi.parentElement.nextSibling.classList.contains('invalid-feedback')) {
                    multi.parentElement.nextSibling.innerHTML = "";
                }
            

        });


        for (let [key, value] of Object.entries(res)) {
            if (value !== null && Array.isArray(value)) {
                let elem = document.querySelector(`#${form} [name='${key}']`);
                if (elem) {
                    elem.classList.add("is-invalid");

                    if (elem.nextElementSibling) 
                        if (elem.nextSibling.classList.contains('invalid-feedback')) {
                            elem.nextSibling.innerHTML = value[0];
                        }
                    


                    let multi = document.querySelector(`#${form} [name='${key}'].multiselect__input`);
                    if (multi) {

                        multi.parentElement.classList.add("is-invalid");
                        if (multi.parentElement.parentElement.nextElementSibling) 
                            if (elem.parentElement.parentElement.nextSibling.classList.contains('invalid-feedback')) {
                                elem.parentElement.parentElement.nextSibling.innerHTML = value[0];
                            }
                        
                    }


          
                }
            }
        }
        mess.forEach((m) => {
            if (res[m.mess] !== undefined) {
                server.toasts(res[m.mess], m.class);
            }
        });
    }
};
export default server;
