<template>
  <div id="planning">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <button
              @click="add"
              class="btn btn-primary btn-xxs"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#dialogadd"
            >
              <vue-feather type="plus"></vue-feather>
            </button>

            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div class="row py-1">
          <div class="mt10 table-responsive">
            <table class="table table-bordered" id="tabforexp">
              <thead>
                <tr>
                  <th class="p-2">№ п/п</th>
                  <th class="p-2">Мероприятие</th>
                  <th class="p-2">Даты проведения</th>
                  <th class="p-2">Уровень</th>
                  <th class="p-2">Вид</th>
                  <th class="p-2">Внёс</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(val, n, isc) in mero" :key="isc">
                  <tr
                    :data-bs-target="'#d' + (n + 1)"
                    data-bs-parent="#tabforexp"
                    data-bs-toggle="collapse"
                    class="cursor-pointer"
                    aria-expanded="false"
                    @click="getmero(val.kod)"
                    :aria-controls="'d' + (n + 1)"
                    v-if="filter == 0 || iamtrue == val.useryes"
                  >
                    <td class="p-2 text-center">{{ val.idx }}</td>
                    <td class="small p-2">
                      <span
                        v-html="
                          val.Nazv + (val.bbm == 1 ? ' (Профессионалитет)' : '')
                        "
                      ></span>
                    </td>

                    <td class="p-2 small">{{ val.di }}</td>
                    <td class="small p-2 text-center">{{ val.uroven }}</td>
                    <td class="p-2 small">{{ val.mero }}</td>
                    <td class="p-2 small" v-if="val.useryes == 0">
                      {{ val.prep }}
                    </td>
                    <td class="p-2 small" v-else>
                      <div class="btn-group">
                        <button
                          class="btn btn-primary btn-xxs"
                          data-bs-toggle="modal"
                          data-bs-target="#dialogadd"
                          @click="edit(val.kod)"
                        >
                          <vue-feather type="edit-2"></vue-feather>
                        </button>
                        <a
                          target="_blank"
                          v-if="val.linkfile"
                          class="btn btn-primary btn-xxs"
                          :href="ftp + val.linkfile"
                          ><vue-feather type="download"></vue-feather
                        ></a>
                      </div>
                    </td>
                  </tr>

                  <tr :id="'d' + (n + 1)" class="collapse">
                    <td colspan="6">
                      <div>
                        <table class="table table-bordered">
                          <tr v-if="val.uchast">
                            <th class="p-2 font-weight-bold">Участники</th>
                            <td class="p-2 small">{{ val.uchast }}</td>
                          </tr>
                          <tr v-if="val.otv">
                            <th class="p-2 font-weight-bold">Ответственные</th>
                            <td class="p-2 small">{{ val.otv }}</td>
                          </tr>
                          <tr v-if="val.isklday">
                            <th class="p-2 font-weight-bold">
                              Исключённые дни
                            </th>
                            <td class="p-2 small">{{ val.isklday }}</td>
                          </tr>
                          <tr v-if="val.Mesto">
                            <th class="p-2 font-weight-bold">
                              Место проведения
                            </th>
                            <td class="p-2 small">
                              {{ val.Mesto }}
                            </td>
                          </tr>
                          <tr v-if="val.v">
                            <th class="p-2 font-weight-bold">
                              Задействованные комплексы
                            </th>
                            <td class="p-2 small">{{ val.v }}</td>
                          </tr>
                          <tr v-if="val.comment">
                            <th class="p-2 font-weight-bold">
                              Дополнительная информация
                            </th>
                            <td class="p-2 small">
                              <span v-html="val.comment"></span>
                            </td>
                          </tr>
                          <tr v-if="val.ochno">
                            <th class="p-2 font-weight-bold">Очное/Заочное</th>
                            <td class="p-2 small">
                              {{ val.ochno == 1 ? "Очное" : "Заочное" }}
                            </td>
                          </tr>
                        </table>

                        <template v-if="merocurrent['se' + val.kod]">
                          <template v-if="merocurrent['se' + val.kod].length">
                            <table
                              class="table table-hover table-responsive table-striped table-bordered table-condensed mt-3"
                              v-if="
                                typeof merocurrent['se' + val.kod] !==
                                  'undefined' &&
                                typeof merocurrent['se' + val.kod] == 'object'
                              "
                            >
                              <tr>
                                <th>№</th>
                                <th>Файл/Тема</th>
                                <th>Документ</th>
                                <th>Студенты</th>
                                <th>Операции</th>
                              </tr>

                              <tr
                                v-for="(file, p, ksed) in merocurrent[
                                  'se' + val.kod
                                ]"
                                :key="ksed"
                              >
                                <td class="p-2 text-center">{{ p + 1 }}</td>
                                <td class="p-2 small">{{ file.naim }}</td>
                                <td class="p-2">{{ file.docum }}</td>
                                <td class="p-2 small">
                                  <span
                                    v-for="(valv, ke) in file.stud"
                                    :key="ke"
                                    >{{ valv.fio }}
                                  </span>
                                </td>
                                <td class="p-2">
                                  <div class="btn-group" v-if="file.files">
                                    <a
                                      :href="ftp + file.files"
                                      download="download"
                                      target="_blank"
                                      class="btn btn-success btn-sm"
                                      ><vue-feather
                                        type="download"
                                      ></vue-feather
                                    ></a>
                                  </div>
                                  <div class="small">
                                    Внёс: {{ file.preped }}<br /><small>{{
                                      file.updated_at
                                    }}</small>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </template>
                        </template>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="container-fluid">
            <div v-if="count">
              <ul class="pagination justify-content-center">
                <li
                  class="page-item"
                  :class="{ active: index == page }"
                  v-for="index in parseInt(count)"
                  :key="index"
                >
                  <button @click="planning(index)" class="page-link">
                    {{ index }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="dialogadd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialogadd"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <form id="formtheme" v-on:submit="addeditheme">
            <div class="modal-header">
              <h5 class="modal-title">{{ textdoc }}</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 px-0">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="naimenthen" class="m-0"
                        >Наименование мероприятия:</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        name="naimenthen"
                        id="naimenthen"
                        required
                        maxlength="200"
                        v-model="naimenthen"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="dates" class="m-0">Даты проведения:</label
                      ><br />
                      <VueDatePicker
                        v-model="dates"
                        name="dates"
                        id="dates"
                        locale="ru-RU"
                        auto-apply
                        range
                        format="dd.MM.yyyy"
                        model-type="dd.MM.yyyy"
                        calendarCellClassName="form-control"
                        inputClassName="form-control"
                        autoPosition
                        position="left"
                        :dark="version == 'dark'"
                        placeholder="Даты проведения"
                        :multi-calendars="{ solo: false }"
                      ></VueDatePicker>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="ochno"
                        id="ochno"
                        v-model="ochno"
                      />
                      <label class="custom-control-label" for="ochno"
                        >&nbsp;&nbsp;Очное мероприятие</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="bbm"
                        id="bbm"
                        v-model="bbm"
                      />
                      <label class="custom-control-label" for="bbm"
                        >&nbsp;&nbsp;Профессионалитет</label
                      >
                      <span class="px-2"></span>

                      <input
                        type="checkbox"
                        class="form-check-input"
                        name="inkl"
                        id="inkl"
                        v-model="inkl"
                      />
                      <label class="custom-control-label" for="inkl"
                        >&nbsp;&nbsp;Инклюзия</label
                      >
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="getvidmero" class="m-0"
                        >Вид мероприятия:</label
                      >
                      <select
                        name="getvidmero"
                        id="getvidmero"
                        class="form-control form-select"
                        v-model="vidmeromodel"
                        required
                      >
                        <option
                          v-for="vs in getvidmero"
                          :key="vs.id"
                          :value="vs.id"
                        >
                          {{ vs.mero }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="getnapravmero" class="m-0"
                        >Направление деятельности:</label
                      >
                      
                      <multiselect
                        v-model="vidgetnapravmero"
                        id="getnapravmero"
                        deselect-label=""
                        track-by="id"
                        label="naprav"
                        placeholder="Выберите одно или несколько направлений"
                        :clear-on-select="false"
                        :options="getnapravmero"
                        :multiple="true"
                        :close-on-select="true"
                        :searchable="false"
                        :allow-empty="true"
                        selectLabel=""
                        selectedLabel=""
                      >

                      </multiselect>
                      
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="getmodulesmero" class="m-0"
                        >Модули программы воспитания:</label
                      >
                      
                      <multiselect
                        v-model="vidgetmodulesmero"
                        id="getmodulesmero"
                        deselect-label=""
                        track-by="id"
                        label="module"
                        placeholder="Выберите один или несколько модулей"
                        :clear-on-select="false"
                        :options="getmodulesmero"
                        :multiple="true"
                        :close-on-select="true"
                        :searchable="false"
                        :allow-empty="true"
                        selectLabel=""
                        selectedLabel=""
                      >

                      </multiselect>
                      
                    </div>
                  </div>
           
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="geturovenmero" class="m-0"
                        >Уровень мероприятия:</label
                      >
                      <select
                        required
                        name="geturovenmero"
                        id="geturovenmero"
                        class="form-control form-select"
                        v-model="urovenmodel"
                      >
                        <option
                          v-for="vs in geturovenmero"
                          :key="vs.id"
                          :value="vs.id"
                        >
                          {{ vs.uroven }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="kompleks" class="m-0">Комплекс:</label>
                      <multiselect
                        v-model="uzlistmodel"
                        id="kompleks"
                        deselect-label=""
                        track-by="korpus"
                        label="korpus"
                        placeholder="Выберите один или несколько комплексов"
                        :clear-on-select="false"
                        :options="getuzlist"
                        :multiple="true"
                        :close-on-select="true"
                        :searchable="false"
                        :allow-empty="true"
                        selectLabel=""
                        selectedLabel=""
                      >
                        <!-- <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.korpus }}</strong></template
                        > -->
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="mesto" class="m-0"
                      >Место проведения мероприятия:</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      name="mesto"
                      id="mesto"
                      autocomplete="off"
                      list="mestos"
                      v-model="mestomodel"
                    />
                    <datalist id="mestos">
                      <option
                        v-for="(va, ku) in mestos"
                        :value="va.s"
                        :key="ku"
                      ></option>
                    </datalist>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="useruchast" class="m-0"
                        >Участники мероприятия:</label
                      >
                      <multiselect
                        v-model="useruchast"
                        deselect-label=""
                        track-by="f"
                        label="f"
                        placeholder="Участники мероприятия"
                        :clear-on-select="false"
                        :options="allusers"
                        :multiple="true"
                        :custom-label="nameWithLang"
                        :close-on-select="false"
                        :searchable="true"
                        :allow-empty="false"
                        selectLabel=""
                        selectedLabel=""
                      >
                        <slot :scope="{ option }"
                          ><strong>{{ option.f }}</strong>
                          <small>{{ option.expr2 }}</small></slot
                        >
                      </multiselect>

                      <div class="input-group my-2">
                        <div class="input-group">
                          <span class="input-group-text">Кураторы:</span>

                          <button
                            class="btn btn-primary btn-xxs"
                            type="button"
                            v-for="val in getuzlist"
                            @click="setklass(val.id)"
                            :key="val.id"
                          >
                            {{ val.sokr }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="userotv" class="m-0">Ответственные:</label>
                      <multiselect
                        v-model="userotv"
                        deselect-label=""
                        track-by="f"
                        label="f"
                        placeholder="Ответственные за мероприятие"
                        :custom-label="nameWithLang"
                        :clear-on-select="false"
                        :options="allusers"
                        :multiple="true"
                        :close-on-select="false"
                        :searchable="true"
                        :allow-empty="false"
                        selectLabel=""
                        selectedLabel=""
                      >
                        <!-- <template slot="singleLabel" slot-scope="{ option }"
                          ><strong>{{ option.f }}</strong>
                          <small>{{ option.expr2 }}</small></template
                        > -->
                      </multiselect>
                    </div>
                  </div>
                </div>
              
                <div class="row">
                  <div class="col-md-6">
                    <label for="times_in" class="m-0"
                      >Время проведения мероприятия (от):</label
                    >
                    <input
                      type="time"
                      class="form-control"
                      name="times_in"
                      id="times_in"
                      autocomplete="off"
                      v-model="times_in"
                    />
                  </div>

                  <div class="col-md-6">
                    <label for="times_ou" class="m-0"
                      >Время проведения мероприятия (до):</label
                    >
                    <input
                      type="time"
                      class="form-control"
                      name="times_ou"
                      id="times_ou"
                      v-model="times_ou"
                      autocomplete="off"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 iskl">
                    <label for="isklday" class="m-0">Исключить даты:</label>
                    <div class="position-relative">
                      <VueDatePicker
                        v-model="isklday"
                        name="isklday"
                        id="isklday"
                        :clearable="true"
                        clearValue="Очистить"
                        model-type="dd.MM.yyyy"
                        locale="ru-RU"
                        multi-dates
                        format="dd.MM.yyyy"
                        calendarCellClassName="form-control"
                        inputClassName="form-control"
                        autoPosition
                        position="left"
                        :dark="version == 'dark'"
                        placeholder="Исключенные даты"
                      ></VueDatePicker>
                      <button
                        type="button"
                        class="btn btn-primary btn-xxs position-absolute btniksl"
                        @click="cleariskl"
                      >
                        x
                      </button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label for="comment" class="m-0"
                      >Дополнительная информация:</label
                    ><input
                      type="text"
                      class="form-control"
                      name="comment"
                      id="comment"
                      v-model="comment"
                      autocomplete="off"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="custom-file">
                        <label class="form-label" for="validatedCustomFile"
                          >Документ (при наличии):</label
                        >
                        <input
                          type="file"
                          class="form-control form-control-sm"
                          id="validatedCustomFile"
                          name="fileformydostig"
                          ref="fileInput"
                          @change="onFilePicked"
                          accept="image/*"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Отмена
              </button>

              <button type="submit" class="btn btn-primary" @click="checkform">
                Сохранить изменения
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
const Typograf = require("typograf");

export default {
  props: [
    "periods",
    "d1",
    "d2",
    "changeperiod",
    "loadchange",
    "logoutweb",
    "version",
  ],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      mero: [],
      tags: [],
      dates: [new Date(), new Date()],
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },

      isklday: [],
      oper: 0,
      options: [],
      value: "",
      naimenthen: "",
      discipselected: "",
      merocurrent: {},
      allgroups: "",
      allstud: "",
      textdoc: "Добавить",
      comment: "",
      listgroups: "",
      viddocum: "",
      mestos: "",
      mestomodel: "",
      themes: "",
      ochno: true,
      times_in: "",
      times_ou: "",
      bbm: false,
      inkl: false,
      href: "",
      val: "",
      useruchast: [],
      userotv: "",
      getvidmero: "",
      vidmeromodel: 0,
      getnapravmero: [],
      getmodulesmero: [],
      vidgetnapravmero: [],
      vidgetmodulesmero: [],
      geturovenmero: "",
      urovenmodel: 0,
      getuzlist: [],
      uzlistmodel: "",
      count: 0,
      page: 1,
      setdocum: "",
      file: "",
      filter: 0,
      iamtrue: 0,
      allusers: [],
      ftp: server.ftp,
    };
  },
  mounted() {
    this.d2;
    this.planning();
    // this.getalluserbbm();
    
    this.getdescriptions();
    
    // this.getvidmeros();
    // this.getnapravmeros();
    // this.geturovenmeros();
    // this.getuz();
    // this.mestosall();
  },
  filters: {
    date: (d) => d.toLocaleString("ru-RU").slice(0, -10),
  },
  methods: {
    nameWithLang({ f, expr2 }) {
      return `${f} — ${expr2}`;
    },
    changeiamtrue() {
      this.iamtrue = 1 - this.iamtrue;
      this.filter = this.iamtrue == 1 ? 1 : 0;
    },

    addeditheme(e) {
      e.preventDefault();
      server.dis(true, "formtheme");
      const tp = new Typograf({ locale: ["ru", "en-US"] });
      var formData = new FormData();
      formData.append("file", this.file);
      formData.append("Nazv", tp.execute(this.naimenthen));
      formData.append("ochno", this.ochno);
      formData.append("bbm", this.bbm);
      formData.append("inkl", this.inkl);
      formData.append("oper", this.oper);
      formData.append("mero", this.vidmeromodel);
      
      formData.append("uroven", this.urovenmodel);
      formData.append("Mesto", tp.execute(this.mestomodel ?? ""));
      formData.append("times_ou", this.times_ou);
      formData.append("times_in", this.times_in);
      formData.append("comment", tp.execute(this.comment ?? ""));

      formData.append("d1", this.dates[0]);
      formData.append("d2", this.dates[1]);

      let uz = [];
      if (this.uzlistmodel.length > 0) {
        this.uzlistmodel.forEach((val) => uz.push(val.id));
      }
      formData.append("Korpus", JSON.stringify(uz));

      let napr = [];
      if (this.vidgetnapravmero.length > 0) {
        this.vidgetnapravmero.forEach((val) => napr.push(val.id));
      }
      formData.append("naprav", JSON.stringify(napr));
      
      let modules = [];
      if (this.vidgetmodulesmero.length > 0) {
        this.vidgetmodulesmero.forEach((val) => modules.push(val.id));
      }
      formData.append("modules", JSON.stringify(modules));


      if (this.useruchast.length == 0) {
        server.toasts("Укажите хотя бы 1 участника мероприятия", "danger");
        server.dis(false, "formtheme");
        return false;
      }
      let uchast = [];
      this.useruchast.forEach((val) => uchast.push(val.KOD));
      formData.append("uchast", uchast.join(";"));

      if (this.userotv.length == 0) {
        server.toasts("Укажите хотя бы 1 ответственного мероприятия", "danger");
        server.dis(false, "formtheme");
        return false;
      }
      let otv = [];
      this.userotv.forEach((val) => otv.push(val.KOD));
      formData.append("otv", otv.join(";"));

      formData.append("isklday", this.isklday ? this.isklday.join(",") : null);

      let links =
        this.oper == 0
          ? server.urlsecond.prepod.insertplanning
          : server.urlsecond.prepod.updateplanning;

      server
        .submittoserver(`${links}`, formData, "POST", true)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.dis(false, "formtheme");
          server.toasts(res.mess, "success");
          server.closewindow("dialogadd");
          this.planning();
          document
            .getElementById("formtheme")
            .classList.remove("was-validated");
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    onFilePicked(event) {
      if (event.target.files[0]) {
        const files = event.target.files;
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[0]);
        this.file = files[0];
      } else {
        this.file = "";
      }
    },

    setklass(id) {
      let fil = this.allusers.filter((val) => {
        return val.k1 == id;
      });
      let o = Object.values(this.useruchast);
      let n = o.concat(fil);
      this.useruchast = Array.from(new Set(n));
    },
    checkform(e) {
      let form = document.getElementById("formtheme");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
        e.preventDefault();
        e.stopPropagation();
      }
    },

    add() {
      document.getElementById("formtheme").classList.remove("was-validated");
      this.naimenthen = "";
      this.oper = 0;
      this.file = "";
      this.ochno = true;
      this.bbm = false;
      this.inkl = false;
      this.vidmeromodel = 0;
      this.vidgetnapravmero = [];
      this.vidgetmodulesmero = [];
      this.urovenmodel = 0;
      this.mestomodel = "";
      this.times_ou = "";
      this.times_in = "";
      this.comment = "";
      this.uzlistmodel = [];
      this.useruchast = [];
      this.userotv = [];
      this.isklday = [];
      this.textdoc = "Добавить";
    },
    cleariskl() {
      this.isklday = null;
    },
    edit(id) {
      document.getElementById("formtheme").classList.remove("was-validated");
      this.naimenthen = "";
      this.oper = id;
      this.ochno = true;
      this.bbm = false;
      this.inkl = false;
      this.vidmeromodel = 0;
      this.vidgetnapravmero = [];
      this.vidgetmodulesmero = [];
      this.urovenmodel = 0;
      this.mestomodel = "";
      this.times_ou = "";
      this.times_in = "";
      this.comment = "";
      this.uzlistmodel = [];
      this.useruchast = [];
      this.userotv = [];
      this.isklday = [];
      this.textdoc = "Редактировать";
      server
        .submittoserver(
          server.urlsecond.prepod.getsvedfromprikaz + id,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          // console.log(res);
          this.naimenthen = res[0].Nazv;
          this.ochno = res[0].ochno == 1 ? true : false;
          this.bbm = res[0].bbm == 1 ? true : false;
          this.inkl = res[0].inkl == 1 ? true : false;
          this.vidmeromodel = res[0].mero;
          
          this.urovenmodel = res[0].uroven;
          this.mestomodel = res[0].Mesto ?? "";
          this.times_ou = res[0].times_ou ?? "";
          this.times_in = res[0].times_in ?? "";
          this.comment = res[0].comment ?? "";
          this.dates = [res[0].d1, res[0].d2];
          this.isklday = res[0].isklday ? res[0].isklday.split(",") : null;

          let korpus = JSON.parse(res[0].korpus);
          korpus.forEach((vale) => {
            this.uzlistmodel.push(
              this.getuzlist.filter((val) => {
                return val.id == vale;
              })[0]
            );
          });

          // this.vidgetnapravmero = res[0].naprav;
          let naprav = JSON.parse(res[0].naprav);
          naprav.forEach((vale) => {
            this.vidgetnapravmero.push(
              this.getnapravmero.filter((val) => {
                return val.id == vale;
              })[0]
            );
          });
     
          
          let modules = JSON.parse(res[0].modules);
          modules.forEach((vale) => {
            this.vidgetmodulesmero.push(
              this.getmodulesmero.filter((val) => {
                return val.id == vale;
              })[0]
            );
          });


          res[0].uchast.split(",").forEach((vale) => {
            let fil = this.allusers.filter((val) => {
              return val.KOD == vale;
            })[0];
            if (fil) this.useruchast.push(fil);
          });
          
          res[0].otv.split(",").forEach((vale) => {
            let fil = this.allusers.filter((val) => {
              return val.KOD == vale;
            })[0];
            if (fil) this.userotv.push(fil);
          });
        })
        .catch((err) => {
          console.log(err);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    // getalluserbbm() {
    //   server
    //     .submittoserver(server.urlsecond.prepod.getalluserbbm, {}, "POST")
    //     .then((res) => {
    //       if (res.error && res.error == 403) {
    //         this.logoutweb();
    //       }
    //       let r = JSON.parse(JSON.stringify(res));
    //       r.sort((a, b) => (a.f > b.f ? 1 : -1));
    //       this.allusers = r;
    //     })
    //     .catch(() => {
    //       server.toasts("Ошибка на сервере", "error");
    //     });
    // },
    // mestosall() {
    //   server
    //     .submittoserver(server.urlsecond.prepod.mestosall, {}, "POST")
    //     .then((res) => {
    //       if (res.error && res.error == 403) {
    //         this.logoutweb();
    //       }
    //       this.mestos = res;
    //     })
    //     .catch(() => {
    //       server.toasts("Ошибка на сервере", "error");
    //     });
    // },
    getdescriptions() {
      server
        .submittoserver(server.urlsecond.prepod.getdescriptions, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }


          let r = JSON.parse(JSON.stringify(res.getvidmero));
          r.sort((a, b) => (a.mero > b.mero ? 1 : -1));
          this.getvidmero = r;
          this.getnapravmero = res.getnapravmero;
          this.getmodulesmero = res.getmodulesmero;


          let r1 = JSON.parse(JSON.stringify(res.allusers));
          r1.sort((a, b) => (a.f > b.f ? 1 : -1));
          this.allusers = r1;

          let r2 = JSON.parse(JSON.stringify(res.geturovenmero));
          r2.sort((a, b) => (a.uroven > b.uroven ? 1 : -1));
          this.geturovenmero = r2;
          this.getuzlist = JSON.parse(JSON.stringify(res.uzlist));
          this.mestos = res.mestos;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    // getvidmeros() {
    //   server
    //     .submittoserver(server.urlsecond.prepod.getvidmero, {}, "POST")
    //     .then((res) => {
    //       if (res.error && res.error == 403) {
    //         this.logoutweb();
    //       }
    //       let r = JSON.parse(JSON.stringify(res));
    //       r.sort((a, b) => (a.mero > b.mero ? 1 : -1));
    //       this.getvidmero = r;
    //     })
    //     .catch(() => {
    //       server.toasts("Ошибка на сервере", "error");
    //     });
    // },
    // geturovenmeros() {
    //   server
    //     .submittoserver(server.urlsecond.prepod.geturovenmero, {}, "POST")
    //     .then((res) => {
    //       if (res.error && res.error == 403) {
    //         this.logoutweb();
    //       }
    //       let r = JSON.parse(JSON.stringify(res));
    //       r.sort((a, b) => (a.uroven > b.uroven ? 1 : -1));
    //       this.geturovenmero = r;
    //     })
    //     .catch(() => {
    //       server.toasts("Ошибка на сервере", "error");
    //     });
    // },
    // getuz() {
    //   server
    //     .submittoserver(server.urlsecond.prepod.getuz, {}, "POST")
    //     .then((res) => {
    //       if (res.error && res.error == 403) {
    //         this.logoutweb();
    //       }
    //       this.getuzlist = JSON.parse(JSON.stringify(res));
    //     })
    //     .catch(() => {
    //       server.toasts("Ошибка на сервере", "error");
    //     });
    // },
    // getnapravmeros() {
    //   server
    //     .submittoserver(server.urlsecond.prepod.getnapravmero, {}, "POST")
    //     .then((res) => {
    //       if (res.error && res.error == 403) {
    //         this.logoutweb();
    //       }
    //       // console.log(res);
    //       // let r = JSON.parse(JSON.stringify(res));
    //       // console.log(r);
    //       // r.sort((a, b) => (a.naprav > b.naprav ? 1 : -1));
    //       this.getnapravmero = res;
    //     })
    //     .catch(() => {
    //       server.toasts("Ошибка на сервере", "error");
    //     });
    // },

    planning(page = 1) {
      this.page = page;
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.planning + this.d1}/${this.d2}/${page}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.mero = res.data;
          this.count = res.count[0].cou;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getmero(id) {
      let v = Object.keys(this.merocurrent);
      if (!v.includes(`se${id}`)) {
        server
          .submittoserver(server.urlsecond.prepod.getmero + id, {}, "POST")
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.merocurrent[`se${id}`] = JSON.parse(JSON.stringify(res));
            this.merocurrent = JSON.parse(JSON.stringify(this.merocurrent));
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
  },
};
</script>
