<template>
  <div id="averageacademicperformance">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card card-body">
          <select class="form-control" @change="changevid" v-model="vid">
            <option value="1">С группировкой по месяцу</option>
            <option value="2">С группировкой по преподавателю</option>
          </select>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <h1 style="text-align: center">
            Средняя успеваемость преподавателей в период {{ d1 }} -
            {{ d2 }}
          </h1>
          <div class="mt10 table-responsive">
            <table
              border="1"
              class="table tableexport wb table-bordered"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
import { exportdoc } from "@/assets/js/exportdoc";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  components: { seldate },
  data() {
    return {
      vid: 1,
      ocenka: "",
    };
  },
  mounted() {
    this.averageacademicperformance(1);
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    changevid() {
      this.ocenka = "";
      this.averageacademicperformance(this.vid);
    },
    averageacademicperformance(vid) {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.averageacademicperformance + this.d1}/${
            this.d2
          }/${vid}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
