<template>
  <div id="projectmanagement">
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div class="row py-1">
          <div class="col-md-12">
            <button
              @click="add"
              class="btn btn-primary btn-xxs mb-2"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#dialogadd"
            >
              <vue-feather type="plus"></vue-feather>
            </button>
            <table class="table table-bordered small" v-if="project.length > 0">
              <thead>
                <tr>
                  <th>№ п/п</th>
                  <th>Студент</th>
                  <th>Дисциплина</th>
                  <th>Дата начала</th>
                  <th>Дата окончания</th>
                  <th>Тема</th>
                  <th>Операции</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, key) in project" :key="key">
                  <td>{{ key + 1 }}</td>
                  <td>{{ val.stud }}</td>
                  <td>{{ val.predmet }}</td>
                  <td>{{ val.di }}</td>
                  <td>{{ val.do }}</td>
                  <td>{{ val.theme }}</td>
                  <td>
                    <button
                      class="btn btn-danger btn-xxs"
                      type="button"
                      @click="remove(val.KOD)"
                    >
                      <vue-feather type="trash"></vue-feather>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="dialogadd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialogadd"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ textdoc }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <form v-on:submit="addeditheme" id="formtheme">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="naimenthen">Тема</label>
                    <input
                      type="text"
                      class="form-control"
                      name="naimenthen"
                      id="naimenthen"
                      v-model="naimenthen"
                    />
                  </div>
                  <div class="form-group">
                    <label for="dates" class="m-0"
                      >Период проектной работы:</label
                    ><br />

                    <VueDatePicker
                      v-model="dates"
                      name="dates"
                      id="dates"
                      locale="ru-RU"
                      auto-apply
                      range
                      format="dd.MM.yyyy"
                      model-type="dd.MM.yyyy"
                      calendarCellClassName="form-control"
                      inputClassName="form-control"
                      autoPosition
                      position="left"
                      :dark="version == 'dark'"
                      placeholder="Период проектной работы"
                      :multi-calendars="{ solo: false }"
                    ></VueDatePicker>
                  </div>
                  <div class="form-group">
                    <label>Дисциплина:</label>

                    <multiselect
                      v-model="discipmodel"
                      deselect-label=""
                      track-by="predmet"
                      label="predmet"
                      placeholder="Выберите дисциплину"
                      :clear-on-select="false"
                      :options="allpredmet"
                      :multiple="false"
                      :close-on-select="true"
                      :searchable="true"
                      :allow-empty="false"
                      selectLabel=""
                      selectedLabel=""
                    >
                      <slot :name="singleLabel" :scope="{ option }"
                        ><strong>{{ option.predmet }}</strong></slot
                      >
                    </multiselect>
                  </div>
                </div>
                <div class="col-md-12">
                  <div>
                    <div>
                      <div class="pa-15">
                        <div class="row">
                          <div class="col-md-8">
                            <label>Укажите студента</label>
                            <div
                              class="accordion accordion-no-gutter accordion-bordered accordion-danger-solid"
                              id="accordion_3"
                            >
                              <div
                                class="accordion-item"
                                v-for="(val, key) in allgroups"
                                :key="key"
                              >
                                <h2 class="accordion-header">
                                  <button
                                    type="button"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#ed' + val.kod"
                                    role="button"
                                    class="accordion-button py-0 small"
                                  >
                                    {{ val.gr }}
                                  </button>
                                </h2>
                                <div
                                  :id="'ed' + val.kod"
                                  class="accordion-collapse collapse"
                                  data-bs-parent="#accordion_3"
                                  role="tabpanel"
                                >
                                  <div class="card-body pa-15 p-1">
                                    <div
                                      v-for="(val, key) in val.stud"
                                      :key="key"
                                    >
                                      <a
                                        href="#"
                                        @click="selstud(val.ids, val.fio)"
                                        >{{ val.fio }}</a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4 incluse">
                            <div v-for="(val, key) in selectstud" :key="key">
                              <a href="#" @click="deletekey(key)">{{
                                val.fio
                              }}</a>
                              <input
                                type="hidden"
                                name="setstud[]"
                                :value="val.ids"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Отмена
              </button>
              <button type="submit" class="btn btn-primary">
                Сохранить изменения
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["loadchange", "logoutweb", "version"],
  data() {
    return {
      ocenka: "",
      project: [],
      naimenthen: "",
      discipmodel: [],
      allgroups: "",
      allstud: "",
      dates: [new Date(), new Date()],
      selectstud: [],
      textdoc: "Добавить",
      allpredmet: [],
      viddocum: "",
      themes: "",
      href: "",
    };
  },
  mounted() {
    this.getproject();
    this.getallpredmets();
    this.getallgroups();
  },
  filters: {
    date: (d) => d.toLocaleString("ru-RU").slice(0, -10),
  },
  methods: {
    selstud(stud, fio) {
      this.selectstud = [{ ids: stud, fio: fio }];
    },
    deletekey(key) {
      this.selectstud.splice(key, 1);
    },
    addeditheme(e) {
      e.preventDefault();
      if (this.selectstud.length == 0) {
        server.toasts("Укажите хотя бы 1 обучающегося", "danger");
        server.dis(false, "formtheme");
        return false;
      }

      if (this.naimenthen == "") {
        server.toasts("Укажите тему", "danger");
        server.dis(false, "formtheme");
        return false;
      }

      if (this.discipmodel.length == 0) {
        server.toasts("Укажите дисциплину", "danger");
        server.dis(false, "formtheme");
        return false;
      }

      server
        .submittoserver(
          server.urlsecond.prepod.studentproject,
          {
            d1: this.dates[0],
            d2: this.dates[1],
            student: this.selectstud[0].ids,
            theme: this.naimenthen,
            discip: this.discipmodel.id,
          },
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.dis(false, "formtheme");
          server.toasts(res.mess, "success");
          server.closewindow("dialogadd");
          this.getproject();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    add() {
      this.naimenthen = "";
      this.textdoc = "Добавить";
      this.selectstud = [];
    },

    getallgroups() {
      server
        .submittoserver(server.urlsecond.prepod.getallgroups, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allgroups = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    remove(id) {
      if (confirm("Вы уверены?")) {
        server
          .submittoserver(
            server.urlsecond.prepod.removeprojectuser + id,
            {},
            "DELETE"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.getproject();
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    getproject() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.prepod.getproject, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.project = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getallpredmets() {
      server
        .submittoserver(server.urlsecond.prepod.getallpredmets, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let r = JSON.parse(JSON.stringify(res));
          r.sort((a, b) => (a.predmet > b.predmet ? 1 : -1));
          this.allpredmet = r;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
