<template>
  <div class="header">
    <div class="header-content">
      <nav class="navbar navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="header-left">
            <div class="dashboard_bar">{{ title }}</div>
          </div>
          <ul class="navbar-nav header-right">
            <li class="nav-item dropdown notification_dropdown">
              <button class="nav-link" @click="changeversion(version)">
                <vue-feather
                  type="moon"
                  v-if="version == 'light'"
                ></vue-feather>
                <vue-feather type="sun" v-else></vue-feather>
              </button>
            </li>

            <li class="nav-item dropdown notification_dropdown">
              <a
                class="nav-link"
                href="javascript:void(0);"
                role="button"
                data-bs-toggle="dropdown"
              >
                <vue-feather type="bell"></vue-feather>

                <span
                  class="badge light text-white bg-primary rounded-circle"
                  >{{ mero.length }}</span
                >
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <div
                  class="widget-media dlab-scroll p-3"
                  style="max-height: 380px"
                >
                  <ul class="timeline">
                    <li v-for="val in mero" :key="val">
                      <div class="timeline-panel">
                        <div class="media me-2" :class="val.color">
                          <vue-feather :type="val.icon"></vue-feather>
                        </div>
                        <div class="media-body">
                          <h6 class="mb-1">{{ val.v }}</h6>
                          <small class="d-block">{{ val.d }}</small>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>

            <li
              class="nav-item notification_dropdown"
              data-bs-toggle="modal"
              data-bs-target="#customtheme"
            >
              <button class="nav-link d-none d-lg-block">
                <vue-feather type="settings"></vue-feather>
              </button>
            </li>

            <li class="nav-item dropdown header-profile">
              <a
                class="nav-link"
                href="javascript:void(0);"
                role="button"
                data-bs-toggle="dropdown"
              >
                <img
                  class="avatar"
                  v-if="!avatar"
                  src="@/assets/images/user.png"
                  width="20"
                  alt=""
                />
                <img class="avatar" width="20" v-else :src="avatar" />
              </a>
              <MenuUser :logout="logout" :profile="profile" />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
  <div class="modal fade" id="customtheme">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Настройки</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
          ></button>
        </div>
        <div class="modal-body">
          <SideBar
            :primarycolor="primarycolor"
            :changePrimaryColor="changePrimaryColor"
            :navheaderBg="navheaderBg"
            :changenavheaderBg="changenavheaderBg"
            :header_bg="header_bg"
            :changeheader_bg="changeheader_bg"
            :sidebarBg="sidebarBg"
            :changesidebarBg="changesidebarBg"
            :changelayout="changelayout"
            :layout="layout"
            :sidebarStyle="sidebarStyle"
            :sidebarchange="sidebarchange"
            :headerPositionChange="headerPositionChange"
            :headerPosition="headerPosition"
            :sidebarPositionChange="sidebarPositionChange"
            :sidebarPosition="sidebarPosition"
            :containerLayoutChange="containerLayoutChange"
            :containerLayout="containerLayout"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary light"
            @click="resetstyle"
          >
            Сброс настроек
          </button>
          <button
            type="button"
            class="btn btn-primary light"
            data-bs-dismiss="modal"
          >
            Выход
          </button>
          <button type="button" class="btn btn-primary" @click="savetheme">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideBar from "./SideBar.vue";
import MenuUser from "./MenuUser.vue";
export default {
  props: [
    "title",
    "version",
    "changeversion",
    "logout",
    "profile",
    "primarycolor",
    "changePrimaryColor",
    "navheaderBg",
    "changenavheaderBg",
    "header_bg",
    "changeheader_bg",
    "sidebarBg",
    "changesidebarBg",
    "changelayout",
    "layout",
    "sidebarStyle",
    "sidebarchange",
    "headerPositionChange",
    "headerPosition",
    "sidebarPositionChange",
    "sidebarPosition",
    "mero",
    "containerLayoutChange",
    "containerLayout",
    "resetstyle",
    "savetheme",
    "avatar",
  ],
  components: {
    MenuUser,
    SideBar,
  },
};
</script>