var body = document.body;
export function dlabSettings({
  version,
  layout,
  navheaderBg,
  headerBg,
  sidebarStyle,
  sidebarBg,
  sidebarPosition,
  headerPosition,
  containerLayout,
  primary,
}) {
  this.version = version || "light";
  this.layout = layout || "vertical";
  this.navheaderBg = navheaderBg || "color_1";
  this.headerBg = headerBg || "color_1";
  this.sidebarStyle = sidebarStyle || "full";
  this.sidebarBg = sidebarBg || "color_1";
  this.sidebarPosition = sidebarPosition || "static";
  this.headerPosition = headerPosition || "static";
  this.containerLayout = containerLayout || "wide";
  this.primary = primary || "color_1";

  this.manageVersion();
  this.manageLayout();
  this.manageNavHeaderBg();
  this.manageHeaderBg();
  this.manageSidebarStyle();
  this.manageSidebarBg();
  this.manageSidebarPosition();
  this.manageHeaderPosition();
  this.manageContainerLayout();
  this.manageResponsiveSidebar();
  this.managePrimaryColor();
}
dlabSettings.prototype.manageVersion = function () {
  switch (this.version) {
    case "light":
      body.dataset.themeVersion = "light"; // body.dataset.theme-version= "light";
      break;
    case "dark":
      body.dataset.themeVersion = "dark";
      break;
    default:
      body.dataset.themeVersion = "light";
  }
};

dlabSettings.prototype.manageLayout = function () {
  switch (this.layout) {
    case "horizontal":
      this.sidebarStyle === "overlay"
        ? (body.dataset.sidebarStyle = "full")
        : (body.dataset.sidebarStyle = this.sidebarStyle);

      body.dataset.layout = "horizontal";
      break;
    case "vertical":
      body.dataset.layout = "vertical";
      break;
    default:
      body.dataset.layout = "vertical";
  }
};
dlabSettings.prototype.manageNavHeaderBg = function () {
  switch (this.navheaderBg) {
    case "color_1":
      body.dataset.navHeaderbg = "color_1";
      break;
    case "color_2":
      body.dataset.navHeaderbg = "color_2";
      break;
    case "color_3":
      body.dataset.navHeaderbg = "color_3";
      break;
    case "color_4":
      body.dataset.navHeaderbg = "color_4";
      break;
    case "color_5":
      body.dataset.navHeaderbg = "color_5";
      break;
    case "color_6":
      body.dataset.navHeaderbg = "color_6";
      break;
    case "color_7":
      body.dataset.navHeaderbg = "color_7";
      break;
    case "color_8":
      body.dataset.navHeaderbg = "color_8";
      break;
    case "color_9":
      body.dataset.navHeaderbg = "color_9";
      break;
    case "color_10":
      body.dataset.navHeaderbg = "color_10";
      break;
    case "color_11":
      body.dataset.navHeaderbg = "color_11";
      break;
    case "color_12":
      body.dataset.navHeaderbg = "color_12";
      break;
    case "color_13":
      body.dataset.navHeaderbg = "color_13";
      break;
    case "color_14":
      body.dataset.navHeaderbg = "color_14";
      break;
    case "color_15":
      body.dataset.navHeaderbg = "color_15";
      break;
    case "image_1":
      body.dataset.navHeaderbg = "image_1";
      break;
    case "image_2":
      body.dataset.navHeaderbg = "image_2";
      break;
    case "image_3":
      body.dataset.navHeaderbg = "image_3";
      break;
    default:
      body.dataset.navHeaderbg = "color_1";
  }
};
dlabSettings.prototype.manageHeaderBg = function () {
  switch (this.headerBg) {
    case "color_1":
      body.dataset.headerbg = "color_1";
      break;
    case "color_2":
      body.dataset.headerbg = "color_2";
      break;
    case "color_3":
      body.dataset.headerbg = "color_3";
      break;
    case "color_4":
      body.dataset.headerbg = "color_4";
      break;
    case "color_5":
      body.dataset.headerbg = "color_5";
      break;
    case "color_6":
      body.dataset.headerbg = "color_6";
      break;
    case "color_7":
      body.dataset.headerbg = "color_7";
      break;
    case "color_8":
      body.dataset.headerbg = "color_8";
      break;
    case "color_9":
      body.dataset.headerbg = "color_9";
      break;
    case "color_10":
      body.dataset.headerbg = "color_10";
      break;
    case "color_11":
      body.dataset.headerbg = "color_11";
      break;
    case "color_12":
      body.dataset.headerbg = "color_12";
      break;
    case "color_13":
      body.dataset.headerbg = "color_13";
      break;
    case "color_14":
      body.dataset.headerbg = "color_14";
      break;
    case "color_15":
      body.dataset.headerbg = "color_15";
      break;
    case "transparent":
      body.dataset.headerbg = "transparent";
      break;
    case "gradient_1":
      body.dataset.headerbg = "gradient_1";
      break;
    case "gradient_2":
      body.dataset.headerbg = "gradient_2";
      break;
    case "gradient_3":
      body.dataset.headerbg = "gradient_3";
      break;
    default:
      body.dataset.headerbg = "color_1";
  }
};
dlabSettings.prototype.manageSidebarStyle = function () {
  switch (this.sidebarStyle) {
    case "full":
      body.dataset.sidebarStyle = "full";
      break;
    case "mini":
      body.dataset.sidebarStyle = "mini";
      break;
    case "compact":
      body.dataset.sidebarStyle = "compact";
      break;
    case "modern":
      body.dataset.sidebarStyle = "modern";
      break;
    case "icon-hover":
      body.dataset.sidebarStyle = "icon-hover";
      document.querySelector(".dlabnav").addEventListener("mouseover", () => {
        document
          .querySelector("#main-wrapper")
          .classList.add("iconhover-toggle");
      });
      document.querySelector(".dlabnav").addEventListener("mouseout", () => {
        document
          .querySelector("#main-wrapper")
          .classList.remove("iconhover-toggle");
      });

      break;
    case "overlay":
      this.layout === "horizontal"
        ? (body.dataset.sidebarStyle = "full")
        : (body.dataset.sidebarStyle = "overlay");
      break;
    default:
      body.dataset.sidebarStyle = "full";
  }
};
dlabSettings.prototype.manageSidebarBg = function () {
  switch (this.sidebarBg) {
    case "color_1":
      body.dataset.sidebarbg = "color_1";
      break;
    case "color_2":
      body.dataset.sidebarbg = "color_2";
      break;
    case "color_3":
      body.dataset.sidebarbg = "color_3";
      break;
    case "color_4":
      body.dataset.sidebarbg = "color_4";
      break;
    case "color_5":
      body.dataset.sidebarbg = "color_5";
      break;
    case "color_6":
      body.dataset.sidebarbg = "color_6";
      break;
    case "color_7":
      body.dataset.sidebarbg = "color_7";
      break;
    case "color_8":
      body.dataset.sidebarbg = "color_8";
      break;
    case "color_9":
      body.dataset.sidebarbg = "color_9";
      break;
    case "color_10":
      body.dataset.sidebarbg = "color_10";
      break;
    case "color_11":
      body.dataset.sidebarbg = "color_11";
      break;
    case "color_12":
      body.dataset.sidebarbg = "color_12";
      break;
    case "color_13":
      body.dataset.sidebarbg = "color_13";
      break;
    case "color_14":
      body.dataset.sidebarbg = "color_14";
      break;
    case "color_15":
      body.dataset.sidebarbg = "color_15";
      break;
    case "image_1":
      body.dataset.sidebarbg = "image_1";
      break;
    case "image_2":
      body.dataset.sidebarbg = "image_2";
      break;
    case "image_3":
      body.dataset.sidebarbg = "image_3";
      break;
    default:
      body.dataset.sidebarbg = "color_1";
  }
};
dlabSettings.prototype.manageSidebarPosition = function () {
  switch (this.sidebarPosition) {
    case "fixed":
      (this.sidebarStyle === "overlay" && this.layout === "vertical") ||
      this.sidebarStyle === "modern"
        ? (body.dataset.sidebarPosition = "static")
        : (body.dataset.sidebarPosition = "fixed");
      break;
    case "static":
      body.dataset.sidebarPosition = "static";
      break;
    default:
      body.dataset.sidebarPosition = "static";
  }
};
dlabSettings.prototype.manageHeaderPosition = function () {
  switch (this.headerPosition) {
    case "fixed":
      body.dataset.headerPosition = "fixed";
      break;
    case "static":
      body.dataset.headerPosition = "static";
      break;
    default:
      body.dataset.headerPosition = "static";
  }
};
dlabSettings.prototype.manageContainerLayout = function () {
  switch (this.containerLayout) {
    case "boxed":
      if (this.layout === "vertical" && this.sidebarStyle === "full") {
        body.dataset.sidebarStyle = "overlay";
      }
      body.dataset.container = "boxed";
      break;
    case "wide":
      body.dataset.container = "wide";
      break;
    case "wide-boxed":
      body.dataset.container = "wide-boxed";
      break;
    default:
      body.dataset.container = "wide";
  }
};

dlabSettings.prototype.manageResponsiveSidebar = function () {
  const innerWidth = document.documentElement.clientWidth;

  if (innerWidth < 1024) {
    body.dataset.layout = "vertical";
    body.dataset.container = "wide";
  }

  if (innerWidth > 767 && innerWidth < 1024) {
    body.dataset.sidebarStyle = "mini";
  }

  if (innerWidth < 768) {
    body.dataset.sidebarStyle = "overlay";
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;

    //добавляем сворачивание меню по свайпу
    document.querySelector(".dlabnav").addEventListener(
      "touchstart",
      function (event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
      },
      false
    );
    document.querySelector(".dlabnav").addEventListener(
      "touchend",
      function (event) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        if (touchendX + 100 < touchstartX) {
          document
            .querySelector("#main-wrapper")
            .classList.remove("menu-toggle");
          document.querySelector(".hamburger").classList.remove("is-active");
        }
      },
      false
    );
  }
};
dlabSettings.prototype.managePrimaryColor = function () {
  switch (this.primary) {
    case "color_1":
      body.dataset.primary = "color_1";
      break;
    case "color_2":
      body.dataset.primary = "color_2";
      break;
    case "color_3":
      body.dataset.primary = "color_3";
      break;
    case "color_4":
      body.dataset.primary = "color_4";
      break;
    case "color_5":
      body.dataset.primary = "color_5";
      break;
    case "color_6":
      body.dataset.primary = "color_6";
      break;
    case "color_7":
      body.dataset.primary = "color_7";
      break;
    case "color_8":
      body.dataset.primary = "color_8";
      break;
    case "color_9":
      body.dataset.primary = "color_9";
      break;
    case "color_10":
      body.dataset.primary = "color_10";
      break;
    case "color_11":
      body.dataset.primary = "color_11";
      break;
    case "color_12":
      body.dataset.primary = "color_12";
      break;
    case "color_13":
      body.dataset.primary = "color_13";
      break;
    case "color_14":
      body.dataset.primary = "color_14";
      break;
    case "color_15":
      body.dataset.primary = "color_15";
      break;

    default:
      body.dataset.primary = "color_1";
  }
};
