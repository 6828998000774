<template>
  <div id="svodallreport">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body py-1">
          <div class="row">
            <div class="col-md-4">
              <label for="korpus" class="m-0">Вид</label>
              <select
                class="form-control form-select"
                @change="reporthours()"
                v-model="vid"
              >
                <option value="0">Бюджет</option>
                <option value="1">Внебюджет</option>
                <option value="2">Дополнительные образовательные услуги</option>
              </select>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="dates" class="m-0">Дата</label>

                <VueDatePicker
                  v-model="dates"
                  name="dates"
                  id="dates"
                  locale="ru-RU"
                  format="dd.MM.yyyy"
                  auto-apply
                  calendarCellClassName="form-control"
                  inputClassName="form-control"
                  autoPosition
                  position="left"
                  :dark="version == 'dark'"
                  placeholder="Дата"
                  model-type="dd.MM.yyyy"
                  @update:model-value="reporthours"
                ></VueDatePicker>
              </div>
            </div>
            <div class="col-md-4">
              <label for="korpus" class="m-0">Комплекс</label>
              <select
                class="form-control form-select"
                id="korpus"
                @change="reporthours()"
                v-model="selectkorpus"
              >
                <option :value="val.id" v-for="val in getuzlist" :key="val.id">
                  {{ val.korpus }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              border="1"
              class="table tableexport table-bordered wb"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: [
    "periods",
    "d1",
    "d2",
    "changeperiod",
    "loadchange",
    "logoutweb",
    "version",
    "mobile"
  ],
  data() {
    return {
      vid: 0,
      dates: null,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      dateRange1: {
        startDate: new Date(),
        endDate: new Date(),
      },
      getuzlist: [],
      selectkorpus: "",

      listmonth: [],
      ocenka: "",
    };
  },

  mounted() {
    this.getuz();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    getuz() {
      server
        .submittoserver(server.urlsecond.prepod.getuz, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getuzlist = JSON.parse(JSON.stringify(res));
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    reporthours() {
      this.ocenka = "";
      if (!this.selectkorpus || !this.dates) {
        return false;
      }
      this.loadchange(false);
      if (this.vid >= 0 && this.selectkorpus)
        server
          .submittoserver(
            `${server.urlsecond.prepod.svodallreport + this.vid}/${
              this.dates
            }/${this.selectkorpus}`,

            {},
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.loadchange(true);
            this.ocenka = res;
          })
          .catch(() => {
            this.loadchange(true);
            server.toasts("Ошибка на сервере", "error");
          });
    },
  },
};
</script>
