<template>
  <div id="readhours">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <select
            class="form-control form-select"
            @change="reporthours"
            v-model="vid"
          >
            <option value="1">Бюджет</option>
            <option value="0">Внебюджет</option>
            <option value="2">Дополнительные образовательные услуги</option>
            <option value="3">Сводная ведомость проведенных занятий</option>
          </select>
        </div>
      </div>
    </div>
    <div v-if="prov">
      <div class="hk-sec-wrapper">
        <div id="table" class="mt-1">
          <div id="resource">
            <div class="mt10 table-responsive">
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th>Дисциплина</th>
                    <th>Группа</th>
                    <th>Сентябрь</th>
                    <th>Октябрь</th>
                    <th>Ноябрь</th>
                    <th>Декабрь</th>
                    <th>Январь</th>
                    <th>Февраль</th>
                    <th>Март</th>
                    <th>Апрель</th>
                    <th>Май</th>
                    <th>Июнь</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in prov" :key="k">
                    <td>{{ val.Sokr_predmet }}</td>
                    <td>{{ val.naim }}</td>
                    <td>{{ val.d9 }}</td>
                    <td>{{ val.d10 }}</td>
                    <td>{{ val.d11 }}</td>
                    <td>{{ val.d12 }}</td>
                    <td>{{ val.d01 }}</td>
                    <td>{{ val.d02 }}</td>
                    <td>{{ val.d03 }}</td>
                    <td>{{ val.d04 }}</td>
                    <td>{{ val.d05 }}</td>
                    <td>{{ val.d06 }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="hk-sec-wrapper">
        <div id="table" class="mt-1">
          <div id="resource">
            <div class="mt10 table-responsive">
              <table
                border="1"
                class="table tableexport table-bordered wb"
                v-html="ocenka.tab"
              ></table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      vid: "",
      ocenka: "",
      prov: null,
    };
  },
  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    reporthours() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.reporthours + this.d1}/${this.d2}/${
            this.vid
          }`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          if (res.pro != "undefined") {
            this.prov = res.pro;
          }
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
