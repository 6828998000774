<template>
  <div id="refusaltoenroll">
    <div class="hk-sec-wrapper">
      <p>
        Нажимая кнопку "Отказ от зачисления", вы подтверждаете, что не намерены
        поступать в ГБПОУ "ЧГПГТ им.А.В.Яковлева" или же на все 100% уверены,
        что хотите поступать в другое учебное заведение.
      </p>
      <button class="btn btn-primary mt-15" @click="outf" :disabled="blocked">
        Отказ от зачисления
      </button>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
export default {
  props: ["blocked", "loadchange", "logoutweb", "changeblocked"],

  // mounted() {

  // },
  methods: {
    outf() {
      if (confirm("Вы уверены?")) {
        server
          .submittoserver(server.urlsecond.abiturient.abitureintout, {}, "PUT")
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.changeblocked(true);
            server.dis(this.blocked, "refusaltoenroll");
            if (res.message) {
              server.toasts(res.message, "success");
            }
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
  },
};
</script>
