<template>
  <div id="coursework">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <button class="btn btn-primary" @click="toword" v-if="discipselected.discip && !mobile">
              <vue-feather type="download"></vue-feather>
            </button>

            <a class="btn btn-primary" @click="addkurs" href="#" v-if="discipselected.discip">
              <vue-feather type="plus-circle"></vue-feather>
            </a>

            <seldate :periods="periods" :changeperiod="changeperiod" :dates="d1 + ' - ' + d2" />
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <select class="form-control form-select" @change="changevid($event)" v-model="discipselected"
            v-if="groups.length > 0">
            <option v-for="(item, keys) in groups" :key="keys" :value="{
              discip: item.discip,
              groups: item.groups,
            }">
              {{ item.naim }}
            </option>
          </select>
          <h4 v-else>Ничего не найдено</h4>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div class="row py-1 border-bottom" v-for="(val, isc) in listgroups" :key="isc">
          <div class="col-md-10">
            {{ val.FIO }}

            <select class="form-control form-select tema form-control-sm" :id="'tema' + isc"
              v-model="listgroups[isc].Tema">
              <option v-for="v in themes" :key="v.KOD" :value="v.KOD">
                {{ v.tema }}
              </option>
            </select>
          </div>
          <div class="col-md-2 d-flex align-items-end">
            <div class="input-group">
              <select class="form-control form-select ocenka form-control-sm" :id="'ocenka' + isc"
                v-model="listgroups[isc].Ocenka">
                <option v-for="vs in ocenka" :key="vs.Kod" :value="vs.Kod">
                  {{ vs.Sokr }}
                </option>
              </select>

              <button class="btn btn-primary btn-xxs" type="submit" @click="savekurs(isc)">
                <vue-feather type="save"></vue-feather>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
const Typograf = require("typograf");
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      blocked: false,
      ocenka: "",
      groups: "",
      discipselected: "",
      listgroups: "",
      themes: "",
      href: "",
    };
  },
  mounted() {
    // server.seladd(this);
    this.getgroups();
  },

  methods: {
    changevid() {
      this.ocenka = "";
      this.getgroupskurs();
    },
    toword() {
      server
        .submittoserverblob(`${server.urlsecond.prepod.getreportkurs + this.discipselected.groups}/${this.discipselected.discip}`, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.docx');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    addkurs() {
      if (!this.discipselected.discip) {
        server.toasts("Нет данных", "danger");
        return false;
      }
      let nt = prompt("Введите тему новой курсовой работы");
      if (nt) {
        this.setnewtheme(nt);
      }
    },

    setnewtheme(nt) {
      const tp = new Typograf({ locale: ["ru", "en-US"] });
      let ntnt = tp.execute(nt);
      let dop = {
        discip: this.discipselected.discip,
        tema: ntnt,
      };
      server
        .submittoserver(
          `${server.urlsecond.prepod.setnewthemekurs}`,
          dop,
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
          this.themes.push({ KOD: res.id, tema: ntnt });
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getgroups() {
      server
        .submittoserver(server.urlsecond.prepod.getkurs + this.d1, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    savekurs(ids) {
      let send = {
        ids: this.listgroups[ids].kods,
        Tema: this.listgroups[ids].Tema,
        Ocenka: this.listgroups[ids].Ocenka,
        discip: this.discipselected.discip,
      };
      server
        .submittoserver(`${server.urlsecond.prepod.setthemestud}`, send, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getgroupskurs() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getlistgroupskurs + this.d1}/${this.discipselected.groups
          }/${this.discipselected.discip}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.listgroups = res;
          this.getthemeskurs();
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getthemeskurs() {
      server
        .submittoserver(
          server.urlsecond.prepod.getthemeskurs + this.discipselected.discip,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.themes = res;
          this.getocenka();
        });
    },
    getocenka() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.prepod.getocenkakurs, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
