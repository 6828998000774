<template>
  <div>
    <table border="1" class="table tableexport table-bordered wb">
      <thead>
        <tr>
          <th style="text-align: center">Год рождения</th>
          <th>Всего</th>
          <th>Кол-во девочек (чел.)</th>
          <th>Кол-во мальчиков (чел.)</th>
        </tr>
      </thead>
      <tbody v-if="ocenka">
        <template v-for="(val, key) in ocenka" :key="key">
          <tr>
            <td colspan="4" style="text-align: center">{{ key }}</td>
          </tr>

          <tr v-for="(vals, keys) in val" :key="keys">
            <td class="small" style="text-align: center">{{ vals.y }}</td>
            <td class="small" style="text-align: center">{{ vals.v }}</td>
            <td class="small" style="text-align: center">{{ vals.d }}</td>
            <td class="small" style="text-align: center">{{ vals.m }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["d1", "logoutweb"],
  data() {
    return {
      ocenka: [],
    };
  },
  mounted() {
    this.reportpolyclinic();
  },

  methods: {
    reportpolyclinic() {
      server
        .submittoserver(server.urlsecond.prepod.reportpolyclinic, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
