<template>
  <div class="container">
    <h3>Администрирование</h3>
    <div class="row">
      <div class="col-md-6">


        <div class="card">
          <div class="card-header">
            <h5>Авторизация под другим пользователем</h5>
          </div>
          <div class="card-body">
       
            <multiselect v-model="selectedUsers" id="ajax" label="names" track-by="code" placeholder="Найти пользователя"
                 open-direction="bottom" :options="users" :multiple="true" :searchable="true" :loading="isLoading"
                 :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300"
                 :limit="3" :max-height="600" :show-no-results="false" :hide-selected="true"
                 @search-change="asyncFind">
      <template #option="props">
        <div class="option__desc"><span class="option__title">{{ props.option.names }}</span> - <span
          class="option__small">{{ props.option.rt=='RestStud'?'Студент':(props.option.rt=='RestPrepod'?'Преподаватель':(props.option.rt=='RestRodMama'?'Мама':'Папа')) }}</span> - 
         <span>{{props.option.email}} (<span>{{props.option.login}}</span>)</span>
        </div>
      </template>
      <template #noResult>
        <span>Ничего не найдено.</span>
      </template>
    </multiselect>
<button class="btn btn-primary mt-3" v-if="selectedUsers.length>0" @click="login3">Авторизоваться</button>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <h3>Работа с меню</h3>
    <div class="hk-sec-wrapper">
      <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#adminen" @click="clearform">
        Добавить право
      </button>
      <div class="row">
        <div class="table-wrap col-md-3">
          <div class="table-responsive">
            <table class="table mb-0 table-bordered table-sm">
              <tbody>
                <tr v-for="(e, key) in allmenuuserm" :key="key" :data-id="e.id">
                  <td @click="changeprava(e.id)">{{ e.names }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-8">
          <span class="badge badge-primary mb-3">Добавить другую роль</span>
          <div id="dost">
            <div class="btn-group m-2" v-for="(e, key) in menuget" :key="key">
              <button type="button" class="btn btn-light d-flex align-items-center" @click="removedoprole(e.id)">
                {{ e.names }}
              </button>
            </div>

            <hr />
            <div class="btn-group m-2" v-for="(es, keys) in menuaccess" :key="keys + 'edr'">
              <button type="button" class="btn btn-light d-flex align-items-center" @click="adddoprole(es.id)">
                {{ es.names }}
              </button>
            </div>
          </div>

          <span class="badge badge-primary mb-3">Права</span>
          <div id="prava">
            <div class="btn-group m-2" v-for="(e, key) in pravam" :key="key">
              <button type="button" class="btn btn-light d-flex align-items-center" @click="removemenurole(e.id)">
                <vue-feather :type="e.icon"></vue-feather>
                {{ e.title }}
              </button>
              <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="sr-only"></span>
              </button>
              <div class="dropdown-menu">
                <button class="dropdown-item" @click="removemenu(e.menu_id)">
                  Удалить
                </button>
                <button class="dropdown-item" @click="updatemenuform(e.menu_id)">
                  Редактировать
                </button>
              </div>
            </div>
          </div>
          <span class="badge badge-primary my-3">Доступные права</span>
          <div id="dostupprava">
            <div class="btn-group m-2" v-for="(e, key) in dostuppravam" :key="key">
              <button type="button" class="btn btn-light d-flex align-items-center" @click="addmenurole(e.id)">
                <vue-feather :type="e.icon"></vue-feather>
                {{ e.title }}
              </button>
              <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="sr-only"></span>
              </button>
              <div class="dropdown-menu">
                <button class="dropdown-item" @click="removemenu(e.id)">
                  Удалить
                </button>
                <button class="dropdown-item" @click="updatemenuform(e.id)">
                  Редактировать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="adminen" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Добавить право</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <form action="" id="formmenuadd">
              <input id="id" name="id" type="hidden" />
              <div class="form-group">
                <label for="title">Заголовок</label>
                <input class="form-control" id="title" placeholder="Заголовок" name="title" type="text"
                  v-model="formmenu.title" />
              </div>
              <div class="form-group">
                <label for="component">Компонент</label>
                <input class="form-control" id="component" placeholder="Компонент" name="component" type="text"
                  v-model="formmenu.component" />
              </div>
              <div class="form-group">
                <label for="icon">Иконка</label>
                <input class="form-control" id="icon" placeholder="Иконка" name="icon" type="text"
                  v-model="formmenu.icon" />
              </div>
              <div class="form-group">
                <label for="position">Позиция</label>
                <input class="form-control" id="position" placeholder="Позиция" name="position" type="text"
                  v-model="formmenu.position" />
              </div>
              <div class="form-group">
                <label for="parentid">Родитель</label>
                <select class="form-control form-select" id="parentid" name="parentid" v-model="formmenu.parentid">
                  <option selected value="0"></option>
                  <option v-for="(e, key) in allmenum" :value="e.id" :key="key">
                    {{ e.title }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="prop">Дополнительные свойства</label>
                <input type="text" class="form-control" id="prop" name="prop" v-model="formmenu.prop" />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Закрыть
            </button>
            <!-- <button
              type="button"
              class="btn btn-primary"
              @click="updatemenu"
              id="up"
            >
              Изменить
            </button> -->
            <button type="button" class="btn btn-primary" @click="newmenu" id="add">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import server from "@/assets/js/server";
import Multiselect from 'vue-multiselect';
export default {
  props: ["loadchange", "logoutweb", "addToken"],
  components: {
    Multiselect
  },
  data() {
    return {
      selectedUsers: [],
      users: [],
      isLoading: false,
      allmenuuserm: [],
      pravam: [],
      allmenum: [],
      dostupprava: [],
      menuget: [],
      menuaccess: [],
      dostuppravam: [],
      role: 0,
      formmenu: {
        title: null,
        component: null,
        icon: null,
        position: null,
        parentid: null,
        prop: null,
        id: null,
      },
    };
  },
  mounted() {
    this.allmenuuser();
  },
  methods: {
    login3(){
      server
        .submittoserver(server.urlsecond.login3, {id: this.selectedUsers[0].id})
        .then((res) => {
            localStorage.setItem("token", res.token);
            this.addToken(res.token);
        });
      
    },
    asyncFind(query) {
      this.isLoading = true;
       server
        .submittoserver(server.urlsecond.searchusers, {user: query}, "post")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.users = res;
          this.isLoading = false;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });



    },
    clearAll () {
      this.selectedUsers = []
    },
    updatemenuform(a) {
      this.formmenu = this.allmenum.find((x) => x.id == a);
      server.closewindow("adminen", true);
    },
    newmenu() {
      server
        .submittoserver(server.urlsecond.newmenu, this.formmenu, "put")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allmenu();
          this.changeprava(this.role);
          server.closewindow("adminen");
          this.clearform();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    clearform() {
      this.formmenu = {
        title: null,
        component: null,
        icon: null,
        position: null,
        parentid: null,
        prop: null,
        id: null,
      };
    },

    allmenuuser() {
      server.messages({}, "activecontent14");

      server
        .submittoserver(server.urlsecond.allmenuuser)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allmenuuserm = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
      this.allmenu();
      this.loadchange(true);
    },
    allmenu() {
      server
        .submittoserver(server.urlsecond.allmenu)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allmenum = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getmenu(a) {
      server
        .submittoserver(server.urlsecond.prepod.menuget + a, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.menuget = res.role;
          this.menuaccess = res.access;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    removedoprole(a) {
      server
        .submittoserver(
          server.urlsecond.prepod.removedoprole + a + "/" + this.role,
          {},
          "DELETE"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.menuget = res.role;
          this.menuaccess = res.access;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    adddoprole(a) {
      server
        .submittoserver(
          `${server.urlsecond.prepod.adddoprole + a}/${this.role}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.menuget = res.role;
          this.menuaccess = res.access;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    changeprava(a) {
      this.getmenu(a);
      this.role = a;
      server
        .submittoserver(server.urlsecond.allmenuuserid + a)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.pravam = res;
          this.dostuppravam = this.allmenum.filter(
            (x) => !this.pravam.some((e) => e.menu_id == x.id)
          );

          this.pravam.sort((f, s) => f.position - s.position);
          this.dostuppravam.sort((f, s) => f.position - s.position);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    addmenurole(a) {
      server
        .submittoserver(
          `${server.urlsecond.addmenurole + a}/${this.role}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.changeprava(this.role);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    removemenurole(a) {
      server
        .submittoserver(server.urlsecond.removemenurole + a, {}, "Delete")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.changeprava(this.role);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    removemenu(a) {
      server
        .submittoserver(server.urlsecond.removemenu + a, {}, "Delete")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allmenum = this.allmenum.filter((e) => e.id != a);
          this.changeprava(this.role);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
