<template>
  <div id="abiturients">
    <div class="hk-sec-wrapper card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4 form-group">
            <label for="Fam">Фамилия</label>
            <input
              class="form-control"
              id="Fam"
              placeholder="Фамилия"
              name="Fam"
              type="text"
              v-model="aball.Fam"
            />
            <div class="invalid-feedback"></div>
          </div>
          <div class="col-md-4 form-group">
            <label for="Name">Имя</label>
            <input
              class="form-control"
              id="Name"
              placeholder="Имя"
              name="Name"
              type="text"
              v-model="aball.Name"
            />
            <div class="invalid-feedback"></div>
          </div>
          <div class="col-md-4 form-group">
            <label for="Otch">Отчество</label>
            <input
              class="form-control"
              id="Otch"
              placeholder="Отчество"
              name="Otch"
              type="text"
              v-model="aball.Otch"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 form-group">
            <label class="mb-10" for="Datarojd">Дата рождения</label>
            <VueDatePicker
              v-model="aball.Datarojd"
              name="Datarojd"
              id="Datarojd"
              locale="ru-RU"
              format="dd.MM.yyyy"
              auto-apply
              calendarCellClassName="form-control"
              inputClassName="form-control"
              autoPosition
              position="left"
              :dark="version == 'dark'"
              placeholder="Дата рождения"
              model-type="dd.MM.yyyy"
            ></VueDatePicker>

            <div class="invalid-feedback"></div>
          </div>
          <div class="col-md-4 form-group">
            <label for="snils">СНИЛС (Номер страхового свидетельства)</label>
            <input
              v-maska
              class="form-control"
              v-model="aball.snils"
              data-maska="###-###-### ##"
              id="snils"
              name="snils"
              type="text"
            />

            <div class="invalid-feedback"></div>
          </div>
          <div class="col-md-4">
            <label for="POL">Пол</label>
            <select
              class="form-control form-select"
              id="POL"
              name="POL"
              v-model="aball.POL"
            >
              <option disabled></option>
              <option value="0">Женский</option>
              <option value="1">Мужской</option>
            </select>
            <div class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <label for="Lang"
              >Иностранный язык получаемый в учебном заведении</label
            >
            <select
              class="form-control form-select"
              id="Lang"
              v-model="aball.Lang"
              name="Lang"
            >
              <option disabled></option>
              <option :value="e.id" v-for="(e, key) in aball.langs" :key="key">
                {{ e.lang }}
              </option>
            </select>
            <div class="invalid-feedback"></div>
          </div>
          <div class="col-md-6 form-group">
            <label for="UINGTO">УИН ГТО</label>

            <input
              v-maska
              class="form-control"
              v-model="aball.UINGTO"
              data-maska="##-##-#######"
              id="UINGTO"
              name="UINGTO"
              type="text"
            />

            <div class="invalid-feedback"></div>
          </div>
        </div>
        <div class="row mt-15">
          <div class="col-md-6" id="contspec">
            <div>
              <label for="Kodspec"
                >Основная программа подготовки получения образования</label
              >

              <div class="input-group">
                <button
                  @click="addpolespec"
                  class="btn btn-primary btn-xxs"
                  type="button"
                >
                  <vue-feather type="plus"></vue-feather>
                </button>

                <select
                  class="form-control form-select"
                  id="Kodspec"
                  name="Kodspec"
                  v-model="aball.Kodspec"
                >
                  <option disabled></option>
                  <optgroup
                    :label="key"
                    v-for="(k, key) in aball.specallvg"
                    :key="key"
                  >
                    <option :value="e.id" v-for="(e, u) in k" :key="u">
                      {{ e.nazv }}
                    </option>
                  </optgroup>
                </select>
              </div>

              <div class="mt-10" v-for="(val, k) in aball.specall" :key="val">
                <label
                  >Дополнительная подача документов на другие программы
                  подготовки</label
                >
                <div class="input-group">
                  <button
                    class="btn btn-primary"
                    @click="removespec(k)"
                    type="button"
                  >
                    -
                  </button>

                  <select
                    class="form-control form-select"
                    v-model="aball.specall[k]"
                    name="specid"
                  >
                    <optgroup
                      :label="key"
                      v-for="(k, key) in aball.specallvg"
                      :key="key"
                    >
                      <option :value="e.id" v-for="(e, u) in k" :key="u">
                        {{ e.nazv }}
                      </option>
                    </optgroup>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label for="">Информация</label>

            <div class="form-control">
              <input
                type="checkbox"
                id="Obshej"
                name="Obshej"
                v-model="aball.Obshej"
                class="form-check-input"
              />
              <label class="custom-control-label" for="Obshej"
                >&nbsp;Требуется для проживания общежитие</label
              >
            </div>
          </div>
        </div>

        <button
          class="btn btn-primary mt-3"
          type="button"
          @click="abiturientupdate"
        >
          Сохранить
        </button>
      </div>
    </div>
    <div class="hk-pg-header align-items-top mt-xl-50 mt-sm-30 mt-15">
      <div>
        <h2 class="hk-pg-title font-weight-600 mb-10">Инструкция</h2>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <p>
        Заполните данные вашей программы обучения. Если программ, на которые вы
        хотели бы поступить несколько, выберите или добавьте их из списка. Также
        заполните остальные недостающие данные.
      </p>
    </div>
  </div>
</template>
<script setup>
import { vMaska } from "maska";
</script>
<script>
import server from "@/assets/js/server";

import { ru } from "date-fns/locale";

export default {
  props: ["blocked", "loadchange", "logoutweb", "version"],
  data() {
    return {
      aball: {},
    };
  },

  mounted() {
    this.abiturient();

    server.dis(this.blocked, "abiturients");
  },
  methods: {
    removespec(k) {
      delete this.aball.specall[k];
      this.aball.specall = this.aball.specall.filter((val) => val);
    },
    abiturient() {
      server.messages({}, "abiturients");
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.abiturient.abiturient)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.aball = res[0];
          this.aball.Obshej = this.aball.Obshej == 1;

          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    addpolespec() {
      this.aball.specall.push(null);
    },

    checkSnils(checkedValue) {
      let checkSum = parseInt(checkedValue.slice(9), 10);

      checkedValue = "" + checkedValue;
      checkedValue = checkedValue.split("");

      let sum =
        checkedValue[0] * 9 +
        checkedValue[1] * 8 +
        checkedValue[2] * 7 +
        checkedValue[3] * 6 +
        checkedValue[4] * 5 +
        checkedValue[5] * 4 +
        checkedValue[6] * 3 +
        checkedValue[7] * 2 +
        checkedValue[8] * 1;

      if (sum < 100 && sum == checkSum) {
        return true;
      } else if ((sum == 100 || sum == 101) && checkSum == 0) {
        return true;
      } else if (
        sum > 101 &&
        (sum % 101 == checkSum || (sum % 101 == 100 && checkSum == 0))
      ) {
        return true;
      } else {
        return false;
      }
    },

    abiturientupdate() {
      server.dis(true, "abiturients");
      if (!this.aball.snils) {
        server.toasts("СНИЛС не заполнен!", "error");
        return false;
      }

      this.aball.snils = this.aball.snils.replace(/[^0-9]/g, "");
      if (!this.checkSnils(this.aball.snils)) {
        server.toasts("Ошибка в номере СНИЛС", "error");
        return false;
      }
      function hasDuplicates(array, d) {
        let a = [];
        array.forEach((element) => {
          a.push(element);
        });
        a.push(d);
        return new Set(a).size !== a.length;
      }

      this.aball.specall = this.aball.specall.filter((n) => n); //пустые удалить

      if (hasDuplicates(this.aball.specall, parseInt(this.aball.Kodspec))) {
        server.toasts("Программы подготовки не могут совпадать!", "error");
        return false;
      }

      server
        .submittoserver(
          server.urlsecond.abiturient.abiturientupdate1,
          this.aball,
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.message) {
            server.messages(res, "abiturients", [
              { mess: "message", class: "success" },
            ]);
            
          } else {
            server.messages(res, "abiturients", [
              { mess: "message", class: "error" },
            ]);
          }
          server.dis(false, "abiturients");
        })
        .catch(() => {
          server.dis(false, "abiturients");
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
