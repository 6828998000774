<template>
  <div id="passport">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="form-group col-md-6">
          <label for="seria">Серия паспорта</label>
          <input
            class="form-control"
            id="seria"
            placeholder="Серия паспорта"
            name="seria"
            type="text"
            v-model="getpass.seria"
          />
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6">
          <label for="numpass">Номер паспорта</label>
          <input
            class="form-control"
            id="numpass"
            placeholder="Номер паспорта"
            name="numpass"
            type="text"
            v-model="getpass.numpass"
          />
          <div class="invalid-feedback"></div>
        </div>
      </div>
      <div class="form-group">
        <label for="kemvidan">Кем выдан паспорт</label>
        <multiselect
          v-model="kem"
          deselect-label=""
          track-by="kemvidan"
          label="kemvidan"
          name="kemvidan"
          :disabled="blocked"
          placeholder="Выберите кем выдан паспорт"
          :clear-on-select="false"
          :options="getkemvidan"
          :multiple="false"
          :close-on-select="true"
          :searchable="true"
          :allow-empty="false"
          selectLabel=""
          @select="changepass"
          @input="kemnew = $event.target.value"
          @close="close"
          ref="multiselect"
          selectedLabel=""
        >
          <template #noResult>
            Ничего не найдено.
            <button
              class="btn btn-primary btn-xxs"
              @click="addkemvidan"
              type="button"
            >
              Добавить
            </button>
          </template></multiselect
        >

        <div class="invalid-feedback"></div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="datapas" class="mb-10">Дата выдачи паспорта</label>

          <VueDatePicker
            v-model="getpass.datapas"
            name="datapas"
            id="datapas"
            locale="ru-RU"
            format="dd.MM.yyyy"
            auto-apply
            calendarCellClassName="form-control"
            inputClassName="form-control"
            autoPosition
            position="left"
            :dark="version == 'dark'"
            placeholder="Дата выдачи паспорта"
            model-type="dd.MM.yyyy"
          ></VueDatePicker>

          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6">
          <label for="kodpodrazd">Код подразделения</label>
          <input
            v-maska
            class="form-control"
            v-model="getpass.kodpodrazd"
            data-maska="###-###"
            id="kodpodrazd"
            name="kodpodrazd"
            type="text"
          />

          <div class="invalid-feedback"></div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-6">
          <label for="grajdan">Гражданство</label>
          <select
            class="form-control form-select"
            id="grajdan"
            name="grajdan"
            v-model="getpass.grajdan"
          >
            <option disabled></option>
            <option v-for="(e, key) in getstrana" :key="key" :value="e.id">
              {{ e.strana }}
            </option>
          </select>
          <div class="invalid-feedback"></div>
        </div>
        <div class="form-group col-md-6">
          <label for="mr">Место рождения</label>
          <input
            class="form-control"
            id="mr"
            placeholder="Место рождения"
            name="mr"
            type="text"
            v-model="getpass.mr"
          />
          <div class="invalid-feedback"></div>
        </div>
      </div>
      <button
        class="btn btn-primary mt-3"
        type="button"
        @click="passportupdate"
      >
        Сохранить
      </button>
    </div>
    <div class="hk-pg-header align-items-top mt-xl-50 mt-sm-30 mt-15">
      <div>
        <h2 class="hk-pg-title font-weight-600 mb-10">Инструкция</h2>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <p>Внесите данные паспорта в соответствующие поля</p>
    </div>
  </div>
</template>
<script setup>
import { vMaska } from "maska";
</script>
<script>
import server from "@/assets/js/server";
export default {
  props: ["blocked", "loadchange", "logoutweb", "version"],
  data() {
    return {
      getstrana: [],
      getkemvidan: [],
      getpass: {},
      kem: {},
      kemnew: null,
    };
  },
  mounted() {
    this.passport();
    server.dis(this.blocked, "passport");
  },
  methods: {
    close() {
      this.$refs.multiselect.deactivate();
    },
    addkemvidan() {
      server
        .submittoserver(
          server.urlsecond.abiturient.getkemvidanstore,
          { kemvidan: this.kemnew },
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.mess, "success");
          this.kem = { id: res.id, kemvidan: this.kemnew };
          this.getpass.kemvidan = res.id;
          this.close();
        })
        .catch(() => {
          server.dis(false, "passport");
          server.toasts("Ошибка сервера", "error");
        });
    },
    chadd(value) {
      this.kemnew = value;
    },
    changepass() {
      this.getpass.kemvidan = this.kem.id;
    },

    passport() {
      server.messages({}, "passport");
      server
        .submittoserver(server.urlsecond.abiturient.getpass)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getpass = res[0];
          server
            .submittoserver(server.urlsecond.abiturient.getpasportlist)
            .then((res) => {
              if (res.error && res.error == 403) {
                this.logoutweb();
              }
              this.getstrana = res.strana;
              this.getkemvidan = res.kemvidan;
              if (this.getpass.kemvidan)
                this.kem = this.getkemvidan.find(
                  (x) => x.id == this.getpass.kemvidan
                );
            })
            .catch(() => {
              this.loadchange(true);
              server.toasts("Ошибка на сервере", "error");
            });
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
    },
    passportupdate() {
      this.loadchange(false);
      server.dis(true, "passport");
      server
        .submittoserver(
          server.urlsecond.abiturient.setpass,
          this.getpass,
          "PUT"
        )
        .then((res) => {
          this.loadchange(true);
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "passport", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.dis(false, "passport");
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
