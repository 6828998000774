<template>
  <table border="1" class="table tableexport table-bordered wb">
    <tbody>
      <template v-for="(val, key) in ocenka" :key="val">
        <tr>
          <td colspan="7" style="text-align: center">
            <h1>{{ key }}</h1>
          </td>
        </tr>
        <template v-for="(vals, keys) in val" :key="keys">
          <tr>
            <td colspan="7" style="text-align: center">{{ keys }}</td>
          </tr>
          <tr>
            <th style="text-align: center">№ п/п</th>
            <th style="text-align: center">Студент</th>
            <th>Русский язык</th>
            <th>Математика</th>
            <th>Физика</th>
            <th>Химия</th>
            <th>Средний балл аттестата</th>
          </tr>
          <tr v-for="(valss, keys) in vals" :key="keys">
            <td class="small">{{ keys + 1 }}</td>
            <td class="small">{{ valss.stud }}</td>
            <td class="small" style="text-align: center">
              {{ valss.russ }}
            </td>
            <td class="small" style="text-align: center">
              {{ valss.mat }}
            </td>
            <td class="small" style="text-align: center">
              {{ valss.fiz }}
            </td>
            <td class="small" style="text-align: center">
              {{ valss.him }}
            </td>
            <td class="small" style="text-align: center">{{ valss.sr }}</td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["d1", "logoutweb"],
  data() {
    return {
      ocenka: "",
    };
  },
  mounted() {
    this.schoolattestat();
  },

  methods: {
    schoolattestat() {
      server
        .submittoserver(
          server.urlsecond.prepod.schoolattestat + this.d1,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
