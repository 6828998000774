<template>
  <div id="academicperformance2">
    <div class="col-md-4">
      <div class="card card-body">
        <div class="input-group">
          <seldate
            :periods="periods"
            :changeperiod="changeperiod"
            :dates="d1 + ' - ' + d2"
          />
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            v-if="!mobile"
          >
            Экспорт
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
              MS Word
            </a>
            <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
              MS Excel
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              ref="table"
              border="1"
              class="table tableexport table-bordered wb"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
    };
  },
  mounted() {
    this.getocenkastud();
  },
  updated: function () {
    let ref = this;
    this.$nextTick(function () {
      if (typeof ref.$refs.table != "undefined") {
        let tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    });
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    getocenkastud() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.student.getocenkastud + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;

        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
