<template>
  <div id="studentprofile">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-3">
              <select
                class="form-control form-select"
                @change="changegroups($event)"
              >
                <option value="" disabled selected>Выберите группу</option>
                <option v-for="(e, key) in groups" :key="key" :value="e.id">
                  {{ e.naim }}
                </option>
              </select>
            </div>
            <div class="col-md-9">
              <select
                class="form-control form-select"
                @change="studentprofile"
                v-model="student"
                v-if="stud"
              >
                <option v-for="(e, key) in stud" :key="key" :value="e.id">
                  {{ e.fio }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper" v-if="ocenka">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <div class="row">
              <div class="col-md-2">
                <img :src="ocenka.img" class="img-fluid" alt="" />
              </div>
              <div class="col-md-10">
                <div class="row" v-for="(value, key) in ocenka.sved" :key="key">
                  <div class="col-md-6">
                    <table
                      border="1"
                      class="table tableexport table-bordered wb"
                    >
                      <tbody>
                        <tr>
                          <td>Поименный номер</td>
                          <td>{{ value.Poimen }}</td>
                        </tr>
                        <tr>
                          <td>Фамилия</td>
                          <td>{{ value.Fam }}</td>
                        </tr>
                        <tr>
                          <td>Имя</td>
                          <td>{{ value.Name }}</td>
                        </tr>
                        <tr>
                          <td>Отчество</td>
                          <td>{{ value.Otch }}</td>
                        </tr>
                        <tr>
                          <td>Дата рождения</td>
                          <td>{{ value.datarojd }}</td>
                        </tr>
                        <tr>
                          <td>Телефон</td>
                          <td>{{ value.phone }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-md-6">
                    <table
                      border="1"
                      class="table tableexport table-bordered wb"
                    >
                      <tbody>
                        <tr>
                          <td>Адрес по прописке</td>
                          <td>{{ value.adr }}</td>
                        </tr>
                        <tr>
                          <td>Адрес проживания</td>
                          <td>{{ value.adr1 }}</td>
                        </tr>
                        <tr>
                          <td>Приказы</td>
                          <td>{{ value.prik }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <h2 class="text-center">Успеваемость</h2>
            <table border="1" class="table tableexport table-bordered wb">
              <tbody>
                <tr>
                  <th rowspan="2" class="text-center">№ п/п</th>
                  <th rowspan="2">Наименование дисциплины</th>
                  <th rowspan="2" class="text-center">Максимальная нагрузка</th>
                  <th colspan="2" class="text-center">1 курс</th>
                  <th colspan="2" class="text-center">2 курс</th>
                  <th colspan="2" class="text-center">3 курс</th>
                  <th colspan="2" class="text-center">4 курс</th>
                  <th rowspan="2" class="text-center">Итоговая оценка</th>
                </tr>

                <tr>
                  <th class="text-center">1</th>
                  <th class="text-center">2</th>
                  <th class="text-center">3</th>
                  <th class="text-center">4</th>
                  <th class="text-center">5</th>
                  <th class="text-center">6</th>
                  <th class="text-center">7</th>
                  <th class="text-center">8</th>
                </tr>

                <tr v-for="(val, k) in ocenka.usp" :key="k">
                  <td class="text-center">{{ k + 1 }}</td>
                  <td>{{ val.Predmet }}</td>
                  <td class="text-center">{{ val.chasi }}</td>
                  <td class="text-center">{{ val.s1 }}</td>
                  <td class="text-center">{{ val.s2 }}</td>
                  <td class="text-center">{{ val.s3 }}</td>
                  <td class="text-center">{{ val.s4 }}</td>
                  <td class="text-center">{{ val.s5 }}</td>
                  <td class="text-center">{{ val.s6 }}</td>
                  <td class="text-center">{{ val.s7 }}</td>
                  <td class="text-center">{{ val.s8 }}</td>
                  <td class="text-center">{{ val.ocenka }}</td>
                </tr>
              </tbody>
            </table>

            <div v-if="ocenka.prakt[0]">
              <h2 class="text-center">Практика</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th>№ п/п</th>
                    <th>Семестр</th>
                    <th>Практика</th>
                    <th>Место прохождения практики</th>
                    <th>Продолжительность (в нед.)</th>
                    <th>Оценка</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.prakt" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td class="text-center">{{ val.semestr }}</td>
                    <td>{{ val.praktika }}</td>
                    <td>{{ val.m }}</td>
                    <td class="text-center">{{ val.prodol }}</td>
                    <td class="text-center">{{ val.ocenka }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.kurs[0]">
              <h2 class="text-center">Курсовые работы (проекты)</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th class="text-center">№ п/п</th>
                    <th class="text-center">Семестр</th>
                    <th>Дисциплина</th>
                    <th>Тема</th>
                    <th class="text-center">Оценка</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.kurs" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td class="text-center">{{ val.semestr }}</td>
                    <td>{{ val.predmet }}</td>
                    <td>{{ val.tema }}</td>
                    <td class="text-center">{{ val.ocenka }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.mero[0]">
              <h2 class="text-center">Участия в мероприятиях</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <td class="text-center">№ п/п</td>
                    <td>Мероприятие</td>
                    <td class="text-center">Дата</td>
                    <td>Участие</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.mero" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td>{{ val.Nazv }}</td>
                    <td class="text-center">{{ val.di }}</td>
                    <td>{{ val.docum }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.project[0]">
              <h2 class="text-center">Индивиуальные проекты</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th class="text-center">№ п/п</th>
                    <th>Дисциплина</th>
                    <th>Тема проекта</th>
                    <th class="text-center">Дата начала</th>
                    <th class="text-center">Дата окончания</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.project" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td>{{ val.Predmet }}</td>
                    <td>{{ val.theme }}</td>
                    <td class="text-center">{{ val.d }}</td>
                    <td class="text-center">{{ val.dd }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.gto[0]">
              <h2 class="text-center">Сдача нормативов ГТО</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th class="text-center">№ п/п</th>
                    <th class="text-center">Ступень</th>
                    <th>Значок</th>
                    <th>Номер удостоверения</th>
                    <th>Приказ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.gto" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td class="text-center">{{ val.stupen }}</td>
                    <td>{{ val.znach }}</td>
                    <td>{{ val.nomerud }}</td>
                    <td>{{ val.d }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.nar[0]">
              <h2 class="text-center">Нарушения</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th class="text-center">№ п/п</th>
                    <th class="text-center">Дата</th>
                    <th>Вид нарушения</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.nar" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td class="text-center">{{ val.d }}</td>
                    <td>{{ val.vid }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.pos[0]">
              <h2 class="text-center">Посещение</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th class="text-center">№ п/п</th>
                    <th class="text-center">Период</th>
                    <th>Посещение</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.pos" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td class="text-center">{{ val.v }}</td>
                    <td>{{ val.pos }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="ocenka.sred[0]">
              <h2 class="text-center">Средняя ежемесячная успеваемость</h2>
              <table border="1" class="table tableexport table-bordered wb">
                <thead>
                  <tr>
                    <th class="text-center">№ п/п</th>
                    <th class="text-center">Период</th>
                    <th>Успеваемость</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, k) in ocenka.sred" :key="k">
                    <td class="text-center">{{ k + 1 }}</td>
                    <td class="text-center">{{ val.per }}</td>
                    <td>{{ val.sr }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      groups: [],
      stud: "",
      ocenka: "",
      student: "",
    };
  },
  mounted() {
    this.getgroups();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    getgroups() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroupsklassruk + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.groups = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },

    changegroups(event) {
      this.ocenka = "";
      server
        .submittoserver(
          server.urlsecond.prepod.getstudgroups + event.target.value,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.stud = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    studentprofile() {
      server
        .submittoserver(
          server.urlsecond.prepod.studentprofile + this.student,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
          this.loadchange(true);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
