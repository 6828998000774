<template>
  <div class="dlabnav">
    <div class="dlabnav-scroll">
      <div class="dropdown header-profile2">
        <a href="#" role="button" data-bs-toggle="dropdown">
          <div class="header-info2 d-flex align-items-center">
            <img class="avatar" v-if="!avatar" src="@/assets/images/user.png" alt="" />
            <img class="avatar" v-else :src="avatar" />
            <div class="d-flex align-items-center sidebar-info">
              <div>
                <span class="font-w400 d-block">{{ fio }}</span>
                <small class="text-end font-w400"> {{ roletext }}</small>
              </div>

              <vue-feather type="chevron-down"></vue-feather>
            </div>
          </div>
        </a>
        <MenuUser :logout="logout" :profile="profile" />
      </div>

      <ul class="metismenu" id="menu">

        <li class="nav-item" v-if="fio == 'Конобеев Виктор' && roletext == 'Администратор / Преподаватель'" :class="{
              'mm-active mm-active1': component == 'admin',
            }" >
          <a :class="{ 'mm-active nolink mm-active1': component == 'admin' }" href="#"
            @click="changecontentall('admin', 'Настройки')" data-content="admin">
            <vue-feather type="calendar"></vue-feather>

            <span class="nav-text">Настройки</span>
          </a>
        </li>


        <template v-for="(p, i) in menu">
          <li :key="i" v-if="p.child.length">
            <a class="has-arrow" :class="{ disabled: !load }" href="#" aria-expanded="false"
              :aria-controls="'lgfu' + i">
              <vue-feather :type="p.icon"></vue-feather>
              <span class="nav-text">{{ p.title }}</span>
            </a>
            <ul aria-expanded="false">
              <li v-for="(e, key) in p.child" :key="key" :class="{ 'mm-active mm-active1': component == e.component }">
                <a :class="{ 'mm-active mm-active1': component == e.component }" href="#" @click="
              changecontentall(e.component, e.title, e.prop, p.title)
              " :data-content="e.component">
                  <vue-feather :type="e.icon"></vue-feather>
                  {{ e.title }}
                </a>
              </li>
            </ul>
          </li>
          <template v-else>
            <li class="nav-item" v-if="p.component != 'admin'" :class="{
              'mm-active mm-active1': component == p.component,
            }" :key="p.component">
              <a :class="{ 'mm-active nolink mm-active1': component == p.component }" href="#"
                @click="changecontentall(p.component, p.title, p.prop)" :data-content="p.component">
                <vue-feather :type="p.icon"></vue-feather>

                <span class="nav-text">{{ p.title }} </span>
              </a>
            </li>
          </template>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import MenuUser from "./MenuUser.vue";
export default {
  props: [
    "menu",
    "fio",
    "load",
    "changecontentall",
    "component",
    "roletext",
    "logout",
    "profile",
    "avatar",
  ],
  components: {
    MenuUser,
  },
};
</script>