<template>
  <div id="ForgotForm">
    <h4 class="text-center mb-4">Сбросить пароль</h4>
    <div class="form-group mb-4">
      <label class="form-label" for="email">Email</label>
      <input type="email" class="form-control" placeholder="hello@example.com" id="email" name="email" v-model="email" />
      <div class="invalid-feedback"></div>
    </div>
    <div class="text-center">
      <button type="submit" class="btn btn-primary btn-block" @click.prevent="reset">Сбросить</button>
    </div>
  </div>
  <div class="form-row d-flex flex-wrap justify-content-between mb-2 mt-3">
    <div class="form-group mb-sm-4 mb-1">
      <p>
        <a class="text-primary" href="#" @click.prevent="changePage('AuthForm')">Вернуться на страницу входа</a>
      </p>
    </div>
    <div class="form-group ms-2"><a class="text-hover" href="#" @click.prevent="changePage('ResetForm')">Уже есть код
        восстановления доступа?</a></div>
  </div>
</template>
<script>
import server from "../assets/js/server";

export default {
  props: ["changePage", "changeEmail"],
  data() {
    return {
      email: null
    }
  },
  methods: {
    reset() {
      server.dis(true, "ForgotForm");

      let ob = {
        email: this.email,
      };
      server
        .submittoserver(server.urlsecond.passwordemail, ob)
        .then((res) => {
          console.log(res);
          if (res.errors) {
            server.messages(res.errors, "ForgotForm", [
              { mess: "error", class: "warning" },
              { mess: "success", class: "success" },
            ]);
          }
          else {
            if (res.message) {
              this.changeEmail(this.email);
              server.toasts(res.message, "success");
              this.changePage('ResetForm');
            }
          }

          // if(res.errors){
          //   // server.toasts(res.message, "success");
          // }
          server.dis(false, "ForgotForm");
        }

        )
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
          server.dis(false, "ForgotForm");
        });
    }
  },
};
</script>