<template>
  <div id="ktp">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-md-4">
          <div class="card card-body">
            <div class="input-group">
              <seldate
                :periods="periods"
                :changeperiod="changeperiod"
                :dates="d1 + ' - ' + d2"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="resource">
        <div class="all" v-if="all">
          <h3>Учебный год</h3>
          <div class="table-responsive">
            <table class="table table-sm wb table-bordered">
              <thead>
                <tr>
                  <th>№ п/п</th>
                  <th>Группа</th>
                  <th>Дисциплина</th>
                  <th>Количество часов</th>
                  <th>Самостоятельная нагрузка</th>
                  <th>Часы курсовой работы</th>
                  <th>Консультации</th>
                  <th>Экзамены</th>
                  <th>Форма контроля</th>
                  <th>Заполненные часы КТП</th>
                  <th>Разница</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, kf) in all" :key="kf">
                  <td>{{ kf + 1 }}</td>
                  <td>{{ val.naim }}</td>
                  <td>{{ val.predmet }}</td>
                  <td>{{ val.Prakt }}</td>
                  <td>{{ val.sam }}</td>
                  <td>{{ val.kurs }}</td>
                  <td>{{ val.Konsult }}</td>
                  <td>{{ val.Ekz }}</td>
                  <td>{{ val.vid }}</td>
                  <td>{{ val.ktp }}</td>
                  <td>{{ val.Prakt - val.ktp }}</td>
                  <td>
                    <button
                      v-if="val.ktp == 0"
                      class="btn btn-primary btn-xxs"
                      @click="addnewktp(val.g, val.d, val.d1, val.d2)"
                    >
                      <vue-feather type="plus-circle"></vue-feather>
                    </button>
                  </td>
                </tr>
                <tr v-if="dr && dr.length > 0">
                  <td colspan="12" class="text-center">
                    За другого преподавателя
                  </td>
                </tr>
                <tr v-for="(val, kf) in dr" :key="kf + 's'">
                  <td>{{ kf + 1 }}</td>
                  <td>{{ val.naim }}</td>
                  <td>{{ val.predmet }}</td>
                  <td>{{ val.Prakt }}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>
                    <button
                      class="btn btn-primary btn-xxs"
                      @click="addnewktp(val.g, val.d, val.d1, val.d2)"
                    >
                      <vue-feather type="plus-circle"></vue-feather>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div class="current" v-if="current">
          <h3>Ваши КТП</h3>
          <template v-if="current">
            <div v-for="(valt, kro) in current" :key="kro">
              <h4
                :class="
                  Object.values(valt)[0].cur == 1 ? 'alert alert-success' : ''
                "
              >
                {{ kro }}
              </h4>

              <div class="table-responsive" v-if="valt">
                <table
                  class="table wb table-sm table-bordered"
                  id="accordionhome"
                >
                  <thead>
                    <tr>
                      <th>№ п/п</th>
                      <th>Группа</th>
                      <th>Дисциплина</th>
                      <th>Количество часов</th>
                      <th>Период обучения</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(val, k, slo) in valt" :key="k">
                      <tr
                        class="clickable p-0 m-0"
                        role="button"
                        @click="getktpcurrent(kro, val.id)"
                        data-bs-toggle="collapse"
                        :data-bs-target="'#accordion' + k"
                        aria-expanded="false"
                        data-parent="#accordionhome"
                      >
                        <td>{{ slo + 1 }}</td>
                        <td>{{ val.groups }}</td>
                        <td>{{ val.predmet }}</td>
                        <td>{{ val.ch }}</td>
                        <td>{{ val.d }}</td>
                        <td>
                          <div class="btn-group">
                            <button
                              class="btn btn-primary btn-xxs"
                              @click="toword(val.id)"
                              target="_blank"
                              title="Скачать в DOC"
                              v-if="!mobile"
                            >
                              <vue-feather type="download"></vue-feather>
                            </button>

                            <a
                              class="btn btn-primary btn-xxs"
                              :href="serverpath+downloadktpcsv+ val.id"
                              target="_blank"
                              title="Импорт для edu-74"
                              v-if="!mobile"
                            >
                              <vue-feather type="download-cloud"></vue-feather>
                          </a>

                            <button
                              class="btn btn-primary btn-xxs"
                              data-bs-toggle="modal"
                              data-bs-target="#import"
                              @click="getauthorktp(val.id)"
                            >
                              <vue-feather type="upload-cloud"></vue-feather>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr
                        class="p-0 m-0 collapse pacient border border-danger"
                        :id="'accordion' + k"
                      >
                        <td colspan="6" class="m-0">
                          <div class="card-body">
                            <button
                              class="btn btn-primary btn-xxs mb-2"
                              data-bs-toggle="modal"
                              data-bs-target="#editrazdel"
                              @click="razdeledit(0, '', val.id, kro)"
                            >
                              <vue-feather type="plus-circle"></vue-feather>
                            </button>

                            <div
                              :id="'accordionin' + k"
                              v-if="val.res"
                              class="accordion accordion-danger-solid"
                            >
                              <template
                                v-for="(vean, klo, kl) in val.res"
                                :key="kl"
                              >
                                <div class="accordion-item">
                                  <div
                                    class="accordion-header"
                                    :id="'heading' + kl"
                                  >
                                    <button
                                      class="accordion-button collapsed"
                                      data-bs-toggle="collapse"
                                      :data-bs-target="'#collapse' + klo"
                                      aria-expanded="true"
                                      :aria-controls="'collapse' + klo"
                                    >
                                      Раздел: {{ vean[0].soder }}
                                    </button>
                                  </div>
                                  <div
                                    :id="'collapse' + klo"
                                    class="collapse accordion-body"
                                    :aria-labelledby="'heading' + klo"
                                    :data-parent="'#accordion' + k"
                                  >
                                    <div class="btn-group mb-2">
                                      <button
                                        class="btn btn-primary btn-xxs"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editrazdel"
                                        @click="
                                          razdeledit(
                                            vean[0].razdel,
                                            vean[0].soder,
                                            val.id,
                                            kro
                                          )
                                        "
                                      >
                                        <vue-feather
                                          type="edit-2"
                                        ></vue-feather>
                                      </button>
                                      <button
                                        class="btn btn-primary btn-xxs"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editktp"
                                        @click="
                                          editktp(0, kro, vean[0].id, k, klo)
                                        "
                                      >
                                        <vue-feather
                                          type="plus-circle"
                                        ></vue-feather>
                                      </button>
                                    </div>
                                    <table
                                      class="table table-sm table-bordered"
                                    >
                                      <tr>
                                        <th>№ п/п</th>
                                        <th>Тема</th>
                                        <th>Вид занятия</th>
                                        <th>Количество часов</th>
                                        <th>Самостоятельная работа</th>
                                        <th>Домашняя работа</th>
                                        <th>Наглядные пособия</th>
                                        <th></th>
                                      </tr>
                                      <template
                                        v-for="(va, kss) in vean"
                                        :key="kss"
                                      >
                                        <tr>
                                          <td>{{ va.sort }}</td>
                                          <td>{{ va.Tema }}</td>
                                          <td>{{ va.vz }}</td>
                                          <td>{{ va.chasi }}</td>
                                          <td>{{ va.Samost }}</td>
                                          <td>{{ va.dz }}</td>
                                          <td>{{ va.Nagl }}</td>
                                          <td>
                                            <div class="btn-group">
                                              <button
                                                class="btn btn-xxs btn-primary"
                                                @click="
                                                  reverse(
                                                    va.KOD,
                                                    1,
                                                    k,
                                                    va.razdel,
                                                    kro
                                                  )
                                                "
                                              >
                                                <vue-feather
                                                  type="arrow-up"
                                                ></vue-feather>
                                              </button>
                                              <button
                                                class="btn btn-xxs btn-primary"
                                                @click="
                                                  reverse(
                                                    va.KOD,
                                                    0,
                                                    k,
                                                    va.razdel,
                                                    kro
                                                  )
                                                "
                                              >
                                                <vue-feather
                                                  type="arrow-down"
                                                ></vue-feather>
                                              </button>
                                              <div
                                                class="alert alert-info m-0 px-0"
                                              ></div>
                                              <button
                                                class="btn btn-xxs btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#editktp"
                                                @click="
                                                  editktp(
                                                    va.KOD,
                                                    kro,
                                                    va.razdel,
                                                    k,
                                                    kss
                                                  )
                                                "
                                              >
                                                <vue-feather
                                                  type="edit"
                                                ></vue-feather>
                                              </button>
                                              <div
                                                class="alert alert-info m-0 px-0"
                                              ></div>
                                              <button
                                                class="btn btn-xxs btn-primary"
                                                @click="
                                                  remove(
                                                    va.KOD,
                                                    k,
                                                    kro,
                                                    va.razdel
                                                  )
                                                "
                                              >
                                                <vue-feather
                                                  type="trash"
                                                ></vue-feather>
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </template>
                                    </table>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="editrazdel"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editrazdelabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editrazdelabel">
              Редактировать/Создать раздел
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <form action="" id="formmenuadd">
              <input id="id" name="id" type="hidden" />
              <div class="form-group">
                <label for="title">Название</label>
                <input
                  class="form-control"
                  id="title"
                  placeholder="Название"
                  name="title"
                  type="text"
                  v-model="razdel.razdel"
                />
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Закрыть
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="saverazdel"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="editktp"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editktplabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editktplabel">Редактировать тему</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <input id="id" name="id" type="hidden" />
            <div class="form-group">
              <label for="Tema">Тема</label>
              <input
                class="form-control"
                id="Tema"
                placeholder="Тема"
                type="text"
                v-model="ktp.Tema"
              />
              <input type="hidden" v-model="ktp.KOD" />
            </div>
            <div class="form-group">
              <label for="VidZan">Вид занятия</label>
              <select class="form-control" v-model="ktp.VidZan" id="VidZan">
                <option :value="val.KOD" :key="val.KOD" v-for="val in vidzan">
                  {{ val.VidZan }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <label for="chasi">Количество часов</label>
                  <input
                    type="number"
                    id="chasi"
                    v-model="ktp.chasi"
                    class="form-control"
                    min="1"
                  />
                </div>
                <div class="col-md-8">
                  <label for="razdel">Раздел</label>
                  <select class="form-control" v-model="ktp.razdel" id="razdel">
                    <option :value="k" :key="k" v-for="(val, k) in razdels">
                      {{ val }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="Samost">Самостоятельная работа</label>
              <input
                class="form-control"
                id="Samost"
                placeholder="Самостоятельная работа"
                type="text"
                v-model="ktp.Samost"
              />
            </div>
            <div class="form-group">
              <label for="dz">Домашняя работа</label>
              <input
                class="form-control"
                id="dz"
                placeholder="Домашняя работа"
                type="text"
                v-model="ktp.dz"
              />
            </div>
            <div class="form-group">
              <label for="Nagl">Наглядные пособия</label>
              <input
                class="form-control"
                id="Nagl"
                placeholder="Наглядные пособия"
                type="text"
                v-model="ktp.Nagl"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Закрыть
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="savektp(ktp.per2, ktp.id)"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="import"
      tabindex="-1"
      role="dialog"
      aria-labelledby="importlabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="importlabel">Импорт</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <h5>Импортировать из файла CSV</h5>
                <input
                  type="file"
                  class="form-control"
                  @change="uploadFile"
                  ref="file"
                  id="fileup"
                />
                <a href="https://chgpgt.ru/files/tmp/ktp.csv"
                  >Пример КТП для импорта</a
                >
                <div class="table-responsive">
                  <table
                    class="table table-bordered tabls-sm"
                    style="max-height: 400px; display: block"
                    v-if="importcsv"
                  >
                    <tr>
                      <th>Раздел</th>
                      <th>Тема</th>
                      <th>Часы</th>
                      <th>Вид урока</th>
                      <th>Наглядные пособия</th>
                      <th>Сам.работа</th>
                      <th>Домашнее задание</th>
                    </tr>
                    <template v-for="(ve, ke) in importcsv" :key="ke">
                      <tr>
                        <td class="small p-0">
                          {{ ve.soder ? ve.soder : ve[0] }}
                        </td>
                        <td class="small p-0">
                          {{ ve.Tema ? ve.Tema : ve[1] }}
                        </td>
                        <td class="small p-0">
                          {{ ve.chasi ? ve.chasi : ve[2] }}
                        </td>
                        <td class="small p-0">
                          {{ ve.VidZan ? ve.VidZan : ve[3] }}
                        </td>
                        <td class="small p-0">
                          {{ ve.Nagl ? ve.Nagl : ve[4] }}
                        </td>
                        <td class="small p-0">
                          {{ ve.Samost ? ve.Samost : ve[5] }}
                        </td>
                        <td class="small p-0">{{ ve.dz ? ve.dz : ve[6] }}</td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
              <div class="col-md-6">
                <h5>Импортировать из другого КТП</h5>
                <div class="form-group">
                  <label for="authorktp">Преподаватель:</label>
                  <select
                    id="authorktp"
                    class="form-control form-select"
                    @change="selectauthorktpfunc"
                    v-model="selectauthorktp"
                  >
                    <option value=""></option>
                    <template v-for="(m, nf) in authorktp" :key="nf">
                      <option :value="m.prepod">{{ m.p }}</option>
                    </template>
                  </select>
                </div>
                <div class="form-group" v-if="authorselect">
                  <label for="authorktp">КТП:</label>
                  <select
                    id="authorktp"
                    class="form-control"
                    @change="authorselect_select_func"
                    v-model="authorselect_select"
                  >
                    <option value=""></option>
                    <template v-for="(m, nf) in authorselect" :key="nf">
                      <option :value="m.id">{{ m.d }}</option>
                    </template>
                  </select>
                </div>
                <div class="form-group" v-if="authorselect_select && !mobile">
                  <button
                    class="btn btn-primary btn-xxs"                    
                    target="_blank"
                    @click="toword(authorselect_select)"
                  >
                    <vue-feather type="download"></vue-feather>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Закрыть
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="imported"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Импортировать
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
const Typograf = require("typograf");
export default {
  props: [
    "periods",
    "d1",
    "d2",
    "changeperiod",
    "token",
    "loadchange",
    "logoutweb",
    "version",
    "mobile"
  ],
  components: { seldate },
  data() {
    return {
      all: "",
      dr: null,
      current: "",
      razdel: { razdel: "", id: 0, parent: "", ownparent: "" },
      serverpath: server.server,
      downloadktpcsv: server.urlsecond.downloadktpcsv,
      razdels: {},
      ktp: {
        KOD: 0,
        id: 0,
        per2: null,
        Tema: "",
        razdel: "",
        VidZan: "1",
        chasi: "2",
        Samost: "",
        dz: "",
        Nagl: "",
      },
      addnew: {
        groups: null,
        discip: null,
        period1: null,
        period2: null,
      },
      vidzan: {},
      csv: null,
      importcsv: null,
      chimport: null,
      authorktp: null,
      selectauthorktp: null,
      authorselect: null,
      authorselect_select: null,
      curidktp: null,
    };
  },
  mounted() {
    this.getktp();
  },

  methods: {
    toword(id){
      server
        .submittoserverblob(`${server.urlsecond.downloadktp+id}`, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.docx');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    csvdownload(id){
      server
        .submittoserverblob(`${server.urlsecond.downloadktpcsv+id}`, "GET")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
         
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.csv');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((err) => {
          console.log(err);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    uploadFile() {
      this.csv = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("csv", this.csv);
      server
        .submittoserver(
          server.urlsecond.prepod.csvparser,
          formData,
          "POST",
          true
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.importcsv = res.res;
          this.chimport = res.ch;
        });
    },
    imported() {
      if (confirm("Уверены?")) {
        server
          .submittoserver(
            `${server.urlsecond.prepod.importgo}${this.curidktp}`,
            this.importcsv,
            "PUT"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            server.toasts(res.mess, "success");
            this.getktp();
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },

    razdeledit(id, razdel, parent, ownparent) {
      this.razdel.razdel = razdel;
      this.razdel.id = id;
      this.razdel.parent = parent;
      this.razdel.ownparent = ownparent;
    },
    editktp(id, kro, razdel, os, res) {
      this.razdels = {};
      this.ktp.KOD = id;
      this.ktp.id = os;
      this.ktp.per2 = kro;
      if (id) {
        let val = this.current[kro][os].res[razdel][res];
        this.ktp.Tema = val.Tema;
        this.ktp.VidZan = val.VidZan;
        this.ktp.chasi = val.chasi;
        this.ktp.Nagl = val.Nagl;
        this.ktp.Samost = val.Samost;
        this.ktp.dz = val.dz;
      } else {
        this.ktp.Tema = "";
        this.ktp.VidZan = 1;
        this.ktp.chasi = 2;
        this.ktp.Nagl = "";
        this.ktp.Samost = "";
        this.ktp.dz = "";
      }
      Object.values(this.current[kro][os].res).forEach((e) => {
        this.razdels[e[0].id] = e[0].soder;
      });
      this.ktp.razdel = razdel;
    },
    addnewktp(groups, discip, period1, period2) {
      this.addnew.groups = groups;
      this.addnew.discip = discip;
      this.addnew.period1 = period1;
      this.addnew.period2 = period2;

      server
        .submittoserver(
          `${server.urlsecond.prepod.addnewktp}`,
          this.addnew,
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.mess, "success");
          this.getktp();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    saverazdel() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.saverazdel}`,
          this.razdel,
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.mess, "success");
          this.getktpcurrent(this.razdel.ownparent, this.razdel.parent, true);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    savektp(kro, id) {
      const tp = new Typograf({ locale: ["ru", "en-US"] });
      this.ktp.Tema = tp.execute(this.ktp.Tema);

      server
        .submittoserver(`${server.urlsecond.prepod.savektp}`, this.ktp, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.mess, "success");
          this.getktpcurrent(kro, id, true);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    remove(id, parent, kro, razdel) {
      if (confirm("Уверены?")) {
        server.dis(true, "accordion" + parent);
        server
          .submittoserver(
            `${server.urlsecond.prepod.removektp + id + "/" + razdel}`,
            {},
            "DELETE"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            server.dis(false, "accordion" + parent);
            server.toasts(res.mess, "success");
            if (res.res.length) {
              this.current[kro][parent].res[razdel] = res.res;
            } else {
              this.getktp();

              this.current[kro].parent = "";
            }
          })
          .catch(() => {
            server.dis(false, "accordion" + parent);
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    reverse(id, oper, parent, razdel, kro) {
      if (confirm("Уверены?")) {
        server.dis(true, "accordion" + parent);
        server
          .submittoserver(
            `${server.urlsecond.prepod.updownktp + id + "/" + oper}`,
            {},
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            server.dis(false, "accordion" + parent);
            server.toasts(res.mess, "success");
            this.current[kro][parent].res[razdel] = res.res;
          })
          .catch(() => {
            server.dis(false, "accordion" + parent);
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    getktp() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.getktp + this.d1}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.all = res.all;
          this.dr = res.dr;
          this.current = res.current;
          this.vid = [];
          this.vidzan = res.vidzan;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getauthorktp(id) {
      this.importcsv = null;
      this.curidktp = id;
      this.authorselect_select = null;
      document.getElementById("fileup").value = "";
      this.authorktp = null;
      this.selectauthorktp = null;
      this.authorselect = null;
      server
        .submittoserver(server.urlsecond.prepod.getauthorktp, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error) {
            server.toasts(res.error, "danger");
            return;
          }
          this.authorktp = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    selectauthorktpfunc() {
      if (this.selectauthorktp) {
        server
          .submittoserver(
            server.urlsecond.prepod.getauthorktpselect,
            { author: this.selectauthorktp },
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            if (res.error) {
              server.toasts(res.error, "danger");
              return;
            }
            this.authorselect = res;
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    authorselect_select_func() {
      if (this.authorselect_select) {
        server
          .submittoserver(
            server.urlsecond.prepod.hp_getauthorktp_select_get,
            { id: this.authorselect_select },
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            if (res.error) {
              server.toasts(res.error, "danger");
              return;
            }
            this.importcsv = Object.values(res);
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    getktpcurrent(kro, id, reload = false) {
      if (!this.current[kro][id].res || reload == true) {
        server
          .submittoserver(
            server.urlsecond.prepod.hp_get_all_ktp + id + "/" + this.d1,
            {},
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            if (res.error) {
              server.toasts(res.error, "danger");
              return;
            }
            this.current[kro][id].res = res;
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
  },
};
</script>
