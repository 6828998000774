<template>
  <table border="1" class="table tableexport table-bordered wb">
    <tbody>
      <tr>
        <th style="text-align: center">№ п/п</th>
        <th style="text-align: center">Группа</th>
        <th>Дисциплина</th>
        <th>Форма промежуточной аттестации</th>
        <th>Операции</th>
      </tr>
      <tr v-for="(val, key) in ocenka" :key="key">
        <td style="text-align: center">{{ key + 1 }}</td>
        <td class="small" style="text-align: center">{{ val.naim }}</td>
        <td class="small">{{ val.Predmet }}</td>
        <td class="small">{{ val.texte }}</td>
        <td>
          <button
          v-if="!mobile"
            @click="toword(val.Groups, val.Discip)"
            class="btn btn-primary btn-xxs"
          >
            <vue-feather type="save"></vue-feather>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["d1", "logoutweb", "mobile"],
  data() {
    return {
      ocenka: "",
      href: "",
    };
  },
  mounted() {
    this.promuspev();
  },

  methods: {
    toword(groups, discip) {
 
      server
        .submittoserverblob(`${server.urlsecond.prepod.getword + groups}/${discip}`, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.docx');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    promuspev() {
      server
        .submittoserver(server.urlsecond.prepod.promuspev + this.d1, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
