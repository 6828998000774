<template>
  <div>
    <input
      type="file"
      name="image"
      accept="image/*"
      ref="fileupload"
      class="form-control form-control-sm mb-2"
      @change="setImage"
    />
    <div style="width: 100%; border: 1px solid gray" v-if="imgSrc">
      <vue-cropper
        ref="cropper"
        :guides="true"
        :viewMode="1"
        :drag-mode="crop"
        :minContainerWidth="55"
        :minContainerHeight="55"
        :background="true"
        :cropBoxResizable="true"
        :zoomable="true"
        :rotatable="true"
        :src="imgSrc"
        alt="Source Image"
        :img-style="{ 'max-width': '200px', height: '200px' }"
        :aspect-ratio="1"
      ></vue-cropper>
    </div>
  </div>
  <div id="image-popups" class="row mt-2" v-if="imgSrc">
    <div class="col-sm-12">
      <div class="btn-group">
        <button class="btn btn-xxs btn-primary float-end" @click="change">
          Сохранить
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  props: ["changeavatar"],
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: null,
      cropImg: null,
    };
  },

  methods: {
    change() {
      this.changeavatar();

      this.$refs.fileupload.value = null;
      this.imgSrc = null;
    },

    setImage(e) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        alert("Выберите изображение!");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper)
            this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Не могу прочитать файл");
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    cropImage() {
      if (this.imgSrc) {
        this.cropImg = this.dataURLtoFile(
          this.$refs.cropper.getCroppedCanvas().toDataURL("image/webp", 0.5),
          "image.webp"
        );
      }
    },
  },
};
</script>
  
  