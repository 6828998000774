<template>
  <div id="percentageofprogress">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table border="1" class="table tableexport table-bordered wb">
              <thead>
                <tr>
                  <th>Группа</th>
                  <th>Дисциплина</th>
                  <th class="text-center">Всего</th>
                  <th class="text-center">Неатт.</th>
                  <th class="text-center">Всего на 4 и 5</th>
                  <th class="text-center">Абсолютная успеваемость</th>
                  <th class="text-center">Качественная успеваемость</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, k) in ocenka.tab" :key="k">
                  <td>{{ val.Groups }} ({{ val.nazv_1 }})</td>
                  <td>{{ val.Predmet }}</td>
                  <td class="text-center">{{ val.vs }}</td>
                  <td class="text-center">{{ val.vsneatt }}</td>
                  <td class="text-center">{{ val.vs45 }}</td>
                  <td class="text-center">{{ val.Abso }}</td>
                  <td class="text-center">{{ val.Kach }}</td>
                </tr>
                <tr>
                  <td class="text-center font-weight-bold">Итого:</td>
                  <td class="text-center font-weight-bold">{{ ocenka.i }}</td>
                  <td class="text-center font-weight-bold">
                    {{ ocenka.alls }}
                  </td>
                  <td class="text-center font-weight-bold">{{ ocenka.nea }}</td>
                  <td class="text-center font-weight-bold">{{ ocenka.otl }}</td>
                  <td class="text-center font-weight-bold">{{ ocenka.abs }}</td>
                  <td class="text-center font-weight-bold">
                    {{ ocenka.kach }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";

import seldate from "@/components/seldate.vue";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
    };
  },
  mounted() {
    this.percentageofprogress();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    percentageofprogress() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.percentageofprogress + this.d1}/${
            this.d2
          }`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
