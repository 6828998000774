<template>
  <div id="activecontent1021">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="report">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="report">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <select class="form-control" @change="changevid($event)">
            <option value="" selected disdabled></option>
            <option value="reportktp">Заполненность КТП</option>
            <option value="reportktpadd">Ведение КТП</option>
            <option value="reportpolyclinic">
              Отчёт в поликлинику по возрастам
            </option>
            <option value="promuspev">Промежуточная аттестация</option>
            <option value="schoolattestat">Школьная успеваемость</option>
          </select>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <reportktp v-if="report == 'reportktp'" :d1="d1" :key="key" />
            <reportktpadd v-if="report == 'reportktpadd'" :d1="d1" :key="key" />
            <reportpolyclinic
              v-if="report == 'reportpolyclinic'"
              :d1="d1"
              :key="key"
            />

            <promuspev v-if="report == 'promuspev'" :d1="d1" :key="key" :mobile="mobile"/>
            <schoolattestat
              v-if="report == 'schoolattestat'"
              :d1="d1"
              :key="key"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import reportktp from "../report/reportktp.vue";
import reportktpadd from "../report/reportktpadd.vue";
import reportpolyclinic from "../report/reportpolyclinic.vue";
import promuspev from "../report/promuspev.vue";
import schoolattestat from "../report/schoolattestat.vue";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      report: "",
      key: 0,
    };
  },

  methods: {
    changevid(event) {
      this.report = event.target.value;
      this.getreport(this.report);
    },
    getreport(report) {
      this.key = Math.random();
      this.report = report;
    },

    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
  },
  components: {
    reportktp,
    reportpolyclinic,
    promuspev,
    schoolattestat,
    reportktpadd,
    seldate,
  },
};
</script>
