<template>
  <div id="orphan">
    <div class="hk-sec-wrapper">
      <form v-if="sirotais">
        <div class="form-check custom-checkbox mb-3 checkbox-success">
          <input
            class="form-check-input"
            type="checkbox"
            id="sirota"
            name="sirota"
            v-model="getsirota.sirota"
          />
          <label class="custom-control-label" for="sirota"
            ><strong>Имею статус ребенка-сироты</strong></label
          >
        </div>
        <div class="form-check custom-checkbox mb-3 checkbox-success">
          <input
            class="form-check-input"
            type="checkbox"
            id="sirotapoln"
            name="sirotapoln"
            v-model="getsirota.sirotapoln"
          />
          <label class="custom-control-label" for="sirotapoln"
            >Нахожусь на полном государственном обеспечении</label
          >
        </div>
        <div class="form-check custom-checkbox mb-3 checkbox-success">
          <input
            class="form-check-input"
            type="checkbox"
            id="sirotabez"
            name="sirotabez"
            v-model="getsirota.sirotabez"
          />
          <label class="custom-control-label" for="sirotabez"
            >Являюсь сиротой без попечения родителей</label
          >
        </div>
        <div class="form-check custom-checkbox mb-3 checkbox-success">
          <input
            class="form-check-input"
            type="checkbox"
            id="reguchet"
            name="reguchet"
            v-model="getsirota.reguchet"
          />
          <label class="custom-control-label" for="reguchet"
            >Состою на регистрационном учете</label
          >
        </div>
        <div class="form-group col-md-6">
          <label for="dd">Закрепление за детским домом</label>
          <select
            class="form-control form-select"
            id="dd"
            data-url="setdd"
            name="dd"
            v-model="getsirota.dd"
          >
            <option selected disabled></option>
            <option :value="e.id" v-for="(e, key) in getdd" :key="key">
              {{ e.dd }}
            </option>
          </select>
          <div class="invalid-feedback"></div>
        </div>
        <section class="hk-sec-wrapper row align-items-end text-center">
          <div class="w-100">
            <div class="card">
              <h5 class="card-header">Документы по сиротству</h5>
              <div class="card-body">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  @vdropzone-removed-file="removefile"
                  :options="{ ...dropzoneob, ...dropzoneOptions }"
                />
              </div>
            </div>
          </div>
        </section>
        <button
          class="btn btn-primary mt-3"
          type="button"
          @click="sirotaupdate"
        >
          Сохранить
        </button>
      </form>
      <div v-if="!sirotais">
        <button class="btn btn-primary" @click="iamsirota" :disabled="blocked">
          Я подтверждаю, что имею статус ребенка-сироты.
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import server from "@/assets/js/server";
import vueDropzone from "dropzone-vue3";
export default {
  components: {
    vueDropzone,
  },
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      dropzoneob: {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        acceptedFiles: ".jpg,.png",
        addRemoveLinks: true,
        dictDefaultMessage: "Добавьте необходимые документы",
        dictInvalidFileType: "Тип файла не подходит",
        dictRemoveFile: "Удалить",
        dictCancelUpload: "Отменить",
        paramName: "photo",
        humbnailWidth: 200,
      },
      dropzoneOptions: {
        url: server.server + server.urlsecond.abiturient.setphoto + "9",
      },
      sirotais: false,
      getdd: [],
      getsirota: [],
    };
  },
  mounted() {
    this.issirotaf();
  },
  methods: {
    issirotaf() {
      server
        .submittoserver(server.urlsecond.abiturient.getsirota)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.sirota == 1) {
            this.sirotais = true;
            this.getdocs(9, "#dropzone");
          } else {
            this.sirotais = false;
          }
          this.sirota();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    removefilecustom(id) {
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    removefile(th) {
      let id = JSON.parse(th.xhr.response).id;
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    getdocs(docs, selector) {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.abiturient.getphoto + docs)
        .then((res) => {
          let parent = document.querySelector(selector);
          res.forEach((el) => {
            parent.classList.add("dz-started");
            let div = document.createElement("div");
            div.classList.add(
              "dz-preview",
              "dz-processing",
              "dz-image-preview",
              "dz-success",
              "dz-complete"
            );
            let divimage = document.createElement("div");
            divimage.classList.add("dz-image");

            let image = document.createElement("img");
            image.src = server.ftp + el.path;
            image.classList.add("img-fluid");
            divimage.appendChild(image);

            let dzdetails = document.createElement("div");
            dzdetails.classList.add("dz-details");
            div.appendChild(divimage);
            div.appendChild(dzdetails);
            let a = document.createElement("a");
            a.classList.add("dz-remove");
            a.href = "javascript:undefined;";
            a.innerHTML = "Удалить";
            div.appendChild(a);
            a.addEventListener("click", () => {
              this.removefilecustom(el.id);
              div.remove();
            });
            parent.appendChild(div);
          });
          this.loadchange(true);
        });
    },
    sirota() {
      this.loadchange(false);

      server
        .submittoserver(server.urlsecond.abiturient.getdd)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getdd = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
      server
        .submittoserver(server.urlsecond.abiturient.getsirota)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.sirota == 1) {
            this.sirotais = true;
          }

          this.getsirota = res;
          this.getsirota.sirota = this.getsirota.sirota == 1;
          this.getsirota.sirotapoln = this.getsirota.sirotapoln == 1;
          this.getsirota.sirotabez = this.getsirota.sirotabez == 1;
          this.getsirota.reguchet = this.getsirota.reguchet == 1;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
      server.dis(this.blocked, "activecontent11");
    },
    sirotaupdate() {
      server
        .submittoserver(
          server.urlsecond.abiturient.setsirota,
          this.getsirota,
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.mess, "success");
          // server.messages(res, "activecontent11", [
          //   { mess: "mess", class: "success" },
          // ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    iamsirota() {
      this.sirotais = true;
      this.sirota();
    },
  },
};
</script>
