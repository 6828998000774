<template>
  <div id="monthlyvisit">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-md-4">
          <div class="card card-body">
            <div class="input-group">
              <seldate
                :periods="periods"
                :changeperiod="changeperiod"
                :dates="d1 + ' - ' + d2"
              />
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-if="!mobile"
              >
                Экспорт
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                  MS Word
                </a>
                <a
                  class="dropdown-item"
                  @click="toexcel"
                  href="#"
                  v-if="ocenka"
                >
                  MS Excel
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card card-body">
            <select
              class="form-control groups"
              v-model="groups.selected"
              @change="monthlyvisit"
            >
              <option
                v-for="(vs, k) in groups.options"
                :selected="k == 0"
                :key="vs.kod + vs.verj"
                :value="vs.kod + '/' + vs.verj"
              >
                {{ vs.naim }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              border="1"
              class="table tableexport wb table-bordered"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      ocenka: "",
      groups: {
        selected: 0,
        options: [],
      },
    };
  },
  mounted() {
    this.getgroups();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    getgroups() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroupsklass1 + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups.options = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    monthlyvisit() {
      this.loadchange(false);
      this.ocenka = "";
      server
        .submittoserver(
          server.urlsecond.prepod.monthlyvisit + this.groups.selected,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
