<template>
  <div id="reportsem">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate :periods="periods" :changeperiod="changeperiod" :dates="d1 + ' - ' + d2" />
            <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" v-if="!mobile">
              Экспорт
            </button>
            <div class="dropdown-menu" v-if="ocenka.gr">
              <a class="dropdown-item" @click="toword" href="#">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#">
                MS Excel
              </a>
              <a class="dropdown-item" @click="print" href="#">
                Печать
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <select class="form-control form-select groups" v-model="groups.selected" @change="reportsem">
            <option v-for="(vs, k) in groups.options" :selected="k == 0" :key="vs.kod" :value="vs.kod">
              {{ vs.naim }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource" v-if="ocenka.gr">
          <h3 class="text-center">
            <strong>Сводная ведомость</strong><br>успеваемости студентов группы {{ ocenka.all[0].gr }}, обучающихся по
            программе подготовки
            {{ ocenka.all[0].spec }}<br>за {{ ocenka.all[0].sem }} семестр {{ Math.ceil(ocenka.all[0].sem / 2) }} курс

          </h3>

          <div class="mt10 table-responsive">
            <table id="table" class="table table-bordered wb">
              <thead>
                <tr>
                  <th class="vmid text-center"><span>Фамилия Имя Отчество</span></th>
                  <th class="verth" v-for="index in parseInt(ocenka.all[0].vs)"><span class="vert" v-if="ocenka.gr[index - 1]">{{
                    ocenka.discip[ocenka.gr[index - 1].discip] }}</span>
                   
                  </th>
                  <th class="verth" ><span class="vert">Абсолютная успеваемость</span></th>
                  <th class="verth" ><span class="vert">Качественная успеваемость</span></th>
                  <th class="verth" ><span class="vert">Средний балл</span></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, k) in ocenka.all">
                  <td>
                    {{ k + 1 }}. {{ val.fio }}
                  </td>
                  <td v-for="index in JSON.parse(val.oc)" class="text-center" :class="{'text-danger fw-bold': index.oc=='2'}">
                    {{ index.oc||'' }}
                  </td>
                  <td class="text-center fw-bold">
                    {{ parseFloat(val.abss).toString().replace(".", ",") }}
                  </td>
                  <td class="text-center fw-bold">
                    {{ parseFloat(val.kach).toString().replace(".", ",") }}
                  </td>
                  <td class="text-center fw-bold">
                    {{ val.vsavg ? parseFloat(val.vsavg).toString().replace(".", ",") : 0 }}
                  </td>

                </tr>
                <tr v-for="index in 4">
                  <td class="fw-bold">{{ index - 1 == 0 ? "На 4 и 5" : (index - 1 == 1 ? "Абсолютная успеваемость":(index-1==2?"Качественная успеваемость":"Средний балл")) }}</td>
                  <td v-for="index1 in ocenka.gr" class="text-center fw-bold">
                    {{
                      index - 1 == 0 ? index1.vs45 : (index - 1 == 1 ? index1.abs.toString().replace(".", ",") : (index - 1
                        == 2 ? index1.kach.toString().replace(".", ",") : index1.sr.toString().replace(".", ",")))
                    }}

                  </td>
                  <td class="text-center fw-bold">{{ index == 2 ? abs : "-" }}</td>
                  <td class="text-center fw-bold">{{ index == 3 ? kach : "-" }}</td>
                  <td class="text-center fw-bold">{{ index == 4 ? sr : "-" }}</td>
                </tr>

              </tbody>
            </table>
            <p>_____________/ {{ ocenka.all[0].pr }} /</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
import printHtmlBlock from 'print-html-block';



export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      abs: 0,
      kach: 0,
      sr: 0,
      ocenka: [],
      groups: {
        selected: 0,
        options: [],
      },
    };
  },
  mounted() {
    this.getgroups();
  },

  methods: {


    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    getgroups() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroupsklass + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups.options = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    print() {
      const selector = '#table';
      const options = {
        importStyle: true,
        debug: false,
      };

      printHtmlBlock(selector, options);
    },

    reportsem() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.reportsem}`,
          {
            groups: this.groups.selected,
            data_in: this.d1

          },
          "POST"
        )
        .then((res) => {

          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
          // console.log(res);
          let abs = [];
          let kach = [];
          let sr = [];
          res.all.forEach(el => {
            abs.push(parseFloat(el.abss));
            kach.push(parseFloat(el.kach));
            sr.push(parseFloat(el.vsavg ?? 0));
          });
          const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;

          this.abs = (Math.round(average(abs) * 100) / 100).toString().replace(".", ",");
          this.kach = (Math.round(average(kach) * 100) / 100).toString().replace(".", ",");
          this.sr = (Math.round(average(sr) * 100) / 100).toString().replace(".", ",");
          this.$nextTick().then(() => {


            // function setupCanvas(canvas) {
            //   let dpr = window.devicePixelRatio || 2;
            //   let rect = canvas.getBoundingClientRect();
            //   canvas.width = rect.width * dpr;
            //   canvas.height = rect.height * dpr;
            //   var ctx = canvas.getContext('2d');
            //   ctx.scale(dpr, dpr);
            //   return ctx;
            // }
            // function wrapText(context, text, x, y, maxWidth, lineHeight) {
            //   let words = text.split(' ');
            //   let line = '';
            //   var sv = y || 0;
            //   for (var n = 0; n < words.length; n++) {
            //     var testLine = line + words[n] + ' ';
            //     var metrics = context.measureText(testLine);
            //     var testWidth = metrics.width;
            //     if (testWidth > maxWidth && n > 0) {
            //       // context.textAlign = 'center';
            //       context.fillText(line, x, y);
            //       line = words[n] + ' ';
            //       y += lineHeight;
            //       sv = y;
            //     } else {
            //       line = testLine;
            //     }
            //   }
            //   context.textAlign = 'center';
            //   context.fillText(line, x, y);

            // }
            // document.querySelectorAll("canvas").forEach(el => {

            //   // el.style.width = 1600;
            //   // el.style.height = 400;
            //   el.width = el.getBoundingClientRect().width;
            //   el.height = el.getBoundingClientRect().height;
            //   let ct = setupCanvas(el);
            //   ct.clearRect(0, 0, ct.width, ct.height);
            //   ct.font = '10pt Times New Roman';
            //   ct.fillStyle = "#000";
            //   // ct.rotate((45 * Math.PI) / 180);
            //   wrapText(ct, el.dataset.value, 0, 200, 200, 70);
            // })
          });
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
  