<template>
  <div id="parents">
    <div class="hk-sec-wrapper">
      <form>
        <div class="form-group">
          <label for="countall">Количество человек в семье</label>
          <input
            class="form-control"
            id="countall"
            placeholder="Количество человек в семье"
            name="countall"
            type="number"
            v-model="getrod.countall"
          />
          <div class="invalid-feedback"></div>
        </div>
        <hr />

        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="fam_mom">Фамилия мамы</label>
              <input
                class="form-control"
                id="fam_mom"
                placeholder="Фамилия мамы"
                name="fam_mom"
                type="text"
                v-model="getrod.fam_mom"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="name_mom">Имя мамы</label>
              <input
                class="form-control"
                id="name_mom"
                placeholder="Имя мамы"
                name="name_mom"
                type="text"
                v-model="getrod.name_mom"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="surename_mom">Отчество мамы</label>
              <input
                class="form-control"
                id="surename_mom"
                placeholder="Отчество мамы"
                name="surename_mom"
                type="text"
                v-model="getrod.surename_mom"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="job_mom">Место работы мамы, должность</label>
              <input
                class="form-control"
                id="job_mom"
                placeholder="Место работы мамы, должность"
                name="job_mom"
                type="text"
                v-model="getrod.job_mom"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="phone_mom">Телефон мамы</label>
              <input
                v-maska
                class="form-control"
                v-model="getrod.phone_mom"
                placeholder="Телефон"
                data-maska="# (###) ###-####"
                id="phone_mom"
                name="phone_mom"
                type="text"
              />

              <div class="invalid-feedback"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="fam_fat">Фамилия отца</label>
              <input
                class="form-control"
                id="fam_fat"
                placeholder="Фамилия отца"
                name="fam_fat"
                type="text"
                v-model="getrod.fam_fat"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="name_fat">Имя отца</label>
              <input
                class="form-control"
                id="name_fat"
                placeholder="Имя отца"
                name="name_fat"
                type="text"
                v-model="getrod.name_fat"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="surename_otec">Отчество отца</label>
              <input
                class="form-control"
                id="surename_otec"
                placeholder="Отчество отца"
                name="surename_otec"
                type="text"
                v-model="getrod.surename_otec"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="job_fat">Место работы отца, должность</label>
              <input
                class="form-control"
                id="job_fat"
                placeholder="Место работы отца, должность"
                name="job_fat"
                type="text"
                v-model="getrod.job_fat"
              />
              <div class="invalid-feedback"></div>
            </div>
            <div class="form-group">
              <label for="phone_fat">Телефон отца</label>
              <input
                v-maska
                class="form-control"
                v-model="getrod.phone_fat"
                placeholder="Телефон"
                data-maska="# (###) ###-####"
                id="phone_fat"
                name="phone_fat"
                type="text"
              />

              <div class="invalid-feedback"></div>
            </div>
          </div>
        </div>
        <button class="btn btn-primary mt-3" type="button" @click="rodupdate">
          Сохранить
        </button>
      </form>
    </div>
  </div>
</template>
<script setup>
import { vMaska } from "maska";
</script>
<script>
import server from "@/assets/js/server.js";
export default {
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      getrod: [],
    };
  },
  mounted() {
    this.rodf();
    server.dis(this.blocked, "parents");
  },
  methods: {
    rodf() {
      this.loadchange(false);
      server.messages({}, "parents");
      server
        .submittoserver(server.urlsecond.abiturient.getrod)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getrod = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
    },
    rodupdate() {
      server.dis(true, "parents");

      let n = {
        countall: this.getrod.countall,
        fam_mom: this.getrod.fam_mom,
        name_mom: this.getrod.name_mom,
        surename_mom: this.getrod.surename_mom,
        job_mom: this.getrod.job_mom,
        phone_mom: this.getrod.phone_mom,

        fam_fat: this.getrod.fam_fat,
        name_fat: this.getrod.name_fat,
        surename_otec: this.getrod.surename_otec,
        job_fat: this.getrod.job_fat,
        phone_fat: this.getrod.phone_fat,
      };
      server
        .submittoserver(server.urlsecond.abiturient.setrod, n, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "parents", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.dis(false, "parents");
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>