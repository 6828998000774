<template>
  <div id="diploms">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div
          class="row py-1 border-bottom"
          v-for="(val, isc) in groups"
          :key="isc"
        >
          <div class="col-md-10">
            {{ val.stud }}
            <input
              type="text"
              class="form-control form-control-sm"
              v-model="groups[isc].tema"
            />
          </div>
          <div class="col-md-2 d-flex align-items-end">
            <div class="input-group">
              <select
                class="form-control form-select ocenka form-control-sm"
                v-model="groups[isc].Ocenka"
              >
                <option v-for="vs in ocenka" :key="vs.Kod" :value="vs.Kod">
                  {{ vs.Sokr }}
                </option>
              </select>

              <button
                class="btn btn-primary btn-xxs"
                type="submit"
                @click="savediplom(isc)"
              >
                <vue-feather type="save"></vue-feather>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
const Typograf = require("typograf");
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      groups: "",
      discipselected: "",
      listgroups: "",
      themes: "",
    };
  },
  mounted() {
    this.getgroups();
  },

  methods: {
    getgroups() {
      server
        .submittoserver(
          server.urlsecond.prepod.getlistdiploms + this.d1,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups = res;
          this.getocenka();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getocenka() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.prepod.getocenkakurs, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },

    savediplom(stud) {
      const tp = new Typograf({ locale: ["ru", "en-US"] });
      this.groups[stud].tema = tp.execute(this.groups[stud].tema);
      let dip = {
        tema: this.groups[stud].tema,
        Ocenka: this.groups[stud].Ocenka,
        id: this.groups[stud].id,
      };
      server
        .submittoserver(`${server.urlsecond.prepod.savediplom}`, dip, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
