<template>
  <div id="readhours_admin">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <div class="row">
            <div class="col-md-6">
              <select
                class="form-control form-select"
                @change="reporthours"
                v-model="vid"
              >
                <option value="0">Бюджет</option>
                <option value="1">Внебюджет</option>
                <option value="2">Дополнительные образовательные услуги</option>
                <option value="3">Сводный отчет по датам</option>
                <option value="4">Сводный отчет КТП</option>
              </select>
            </div>
            <div class="col-md-6">
              <select
                class="form-control form-select"
                @change="reporthours"
                v-model="selectprepod"
              >
                <option v-for="(e, key) in prepod" :key="key" :value="e.Prepod">
                  {{ e.prepod }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              border="1"
              class="table tableexport table-bordered wb"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      prepod: [],
      selectprepod: null,
      vid: 0,
      ocenka: "",
    };
  },
  mounted() {
    this.getprepod();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    getprepod() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getprepod + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.prepod = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    reporthours() {
      if (this.selectprepod) {
        this.ocenka = "";
        this.loadchange(false);
        server
          .submittoserver(
            `${server.urlsecond.prepod.reporthours_admin + this.d1}/${
              this.d2
            }/${this.vid}/${this.selectprepod}`,
            {},
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.ocenka = res;
            this.loadchange(true);
          })
          .catch(() => {
            this.loadchange(true);
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
  },
};
</script>
