<template>
  <div id="posuspevforperiod">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-md-4">
          <div class="card card-body">
            <div class="input-group">
              <seldate
                :periods="periods"
                :changeperiod="changeperiod"
                :dates="d1 + ' - ' + d2"
              />
              <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                v-if="!mobile"
              >
                Экспорт
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                  MS Word
                </a>
                <a
                  class="dropdown-item"
                  @click="toexcel"
                  href="#"
                  v-if="ocenka"
                >
                  MS Excel
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card card-body">
            <div class="form-group">
              <div class="col-md-12">
                <VueDatePicker
                  v-model="dates"
                  name="dates"
                  id="dates"
                  locale="ru-RU"
                  auto-apply
                  range
                  format="dd.MM.yyyy"
                  model-type="dd.MM.yyyy"
                  calendarCellClassName="form-control"
                  inputClassName="form-control"
                  autoPosition
                  position="left"
                  :dark="version == 'dark'"
                  @update:model-value="posuspevforperiod"
                  placeholder="Даты"
                  :multi-calendars="{ solo: false }"
                ></VueDatePicker>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              border="1"
              class="table tableexport table-bordered wb"
              v-if="ocenka"
            >
              <tbody>
                <tr>
                  <th colspan="3">
                    Выставление оценок и посещаемости преподавателями в период
                    {{ ocenka.d }}
                  </th>
                </tr>
                <tr>
                  <th>Преподаватель</th>
                  <th>Количество оценок</th>
                  <th colspan="2">Количество посещений</th>
                </tr>
                <tr v-for="(value, key) in ocenka.pos" :key="key">
                  <td>{{ value.FIO }}</td>
                  <td class="text-center">{{ value.oc }}</td>
                  <td class="text-center">{{ value.pos }}</td>
                </tr>
                <tr>
                  <th colspan="3">
                    Выставление оценок и посещаемости преподавателями в период
                    {{ ocenka.d }}
                  </th>
                </tr>
                <tr>
                  <th>Преподаватель</th>
                  <th class="text-center">Всего уложившихся в 30%</th>
                  <th class="text-center">Количество баллов</th>
                </tr>
                <tr v-for="(value, key) in ocenka.pos1" :key="key">
                  <td>{{ value.prepod }}</td>
                  <td class="text-center">{{ value.pro }}</td>
                  <td class="text-center">{{ value.b }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: [
    "periods",
    "d1",
    "d2",
    "changeperiod",
    "loadchange",
    "logoutweb",
    "version",
    "mobile"
  ],
  data() {
    return {
      ocenka: "",
      dates: [new Date(), new Date()],
    };
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    posuspevforperiod() {
      this.loadchange(false);
      this.ocenka = "";
      let d1 = this.dates[0];
      let d2 = this.dates[1];

      server
        .submittoserver(
          `${server.urlsecond.prepod.posuspevforperiod + d1}/${d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
