<template>
  <div id="analitable">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <template v-for="(vales, k) in month">
            <template>
              <div class="mt10 table-responsive" :key="k">
                <table class="table table-hover table-bordered wb">
                  <thead>
                    <tr>
                      <td colspan="44" class="text-center h3">{{ vales.m }}</td>
                    </tr>
                    <tr>
                      <td>№ п/п</td>
                      <td>Программа подготовки</td>
                      <td>План</td>
                      <td v-for="val in day" :key="val">
                        {{ val }}
                      </td>
                      <td>всего поданых заявлений</td>
                      <td>
                        забрали документы или перевелись на другие специальности
                      </td>
                      <td>итого поданых заявлений на текущую дату</td>
                      <td>% выполнения плана приема</td>
                      <td>Конкурс</td>
                      <td>Средний балл аттестата</td>
                      <td>Конкурс 2020</td>
                      <td>Средний балл аттестата 2020</td>
                      <td>Конкурс (отклонение)</td>
                      <td>Ср.Балл (отклонение)</td>
                    </tr>
                  </thead>

                  <tbody v-html="ocenka[k]"></tbody>
                </table>
              </div>
            </template>
          </template>
          <table class="table table-hover table-bordered wb">
            <thead>
              <tr>
                <td>№ п/п</td>
                <td>Программа подготовки</td>
                <td>План</td>
                <td>Всего поданых заявлений</td>
                <td>Всего поданных заявлений с документом об образовании</td>
                <td>Конкурс</td>
                <td>Средний балл аттестата</td>
                <td>Конкурс 2020</td>
                <td>Средний балл аттестата 2020</td>
              </tr>
            </thead>
            <tbody v-html="ocenka[3]"></tbody>
          </table>
          <table id="tableanketa" class="table table-hover table-bordered wb">
            <thead>
              <tr>
                <td>№ п/п</td>
                <td>Вопрос из анкеты</td>
                <td>По всем программам подготовки</td>
                <td>Профессионалитет</td>
              </tr>
            </thead>
            <tbody v-html="ocenka[4]"></tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
import { exportdoc } from "@/assets/js/exportdoc";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      month: [
        { m: "Июнь", k: 6 },
        { m: "Июль", k: 7 },
        { m: "Август", k: 8 },
      ],
      day: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],
      groups: {
        selected: 0,
        options: [],
      },
    };
  },
  mounted() {
    this.analitable();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    analitable() {
      this.loadchange(false);
      this.ocenka = "";
      server
        .submittoserver(server.urlsecond.prepod.analitable, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
