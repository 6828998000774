<template lang="">
    <h4 class="text-center mb-4">Войдите в свой аккаунт</h4>
    <div action="#" id="AuthForm">
      <div class="form-group mb-4">
        <label class="form-label" for="email">Имя пользователя/Email</label>
        <input
          type="text"
          class="form-control"
          placeholder="Имя пользователя"
          id="email"
          name="email"
          v-model="email"
          v-on:keyup.enter="auth"
        />
        <div class="invalid-feedback"></div>
      </div>
      <div class="mb-sm-4 mb-3 position-relative">
        <label class="form-label" for="dlab-password">Пароль</label>
        <input
          type="password"
          id="dlab-password"
          class="form-control"
          name="password"
          v-model="password"
          v-on:keyup.enter="auth"
        />
        <div class="invalid-feedback"></div>        
      </div>
      <div
        class="form-row d-flex flex-wrap justify-content-between mb-2"
      >
        <div class="form-group mb-sm-4 mb-1">
          
        </div>
        <div class="form-group ms-2">
          <a class="text-hover" href="#" @click.prevent="changePage('ForgotForm')"
            >Сброс пароля</a
          >
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-block" @click="auth">
          Войти
        </button>
      </div>
    </div>
    <div class="new-account mt-3">
      <p>
        <span class="form-label">Нет аккаунта? </span>
        <a class="text-primary" href="#" @click.prevent="changePage('RegisterForm')">Зарегистрироваться</a>
      </p>
    </div>
</template>
<script>
import server from "../assets/js/server";
export default {
  props: ["changePage", "addToken", "setPeriod"],

  data() {
    return {
      email: null,
      password: null,
    };
  },
  methods: {
    auth(){
        server.dis(true, "AuthForm");
        server
        .submittoserver(server.urlsecond.login, {email: this.email, "password": this.password})
        .then((res) => {
       

            server.messages(res, "AuthForm", [
            { mess: "error", class: "error" },
          ]);
          if (res.token !== undefined) {
            
            localStorage.setItem("blocked", res.blocked || 0);
            localStorage.setItem("token", res.token);
            this.addToken(res.token);
            
          }
        })
        .catch((res) => {
          console.log(res);
          server.toasts("Ошибка сервера", "error");
            server.dis(false, "AuthForm");
        });
    },
  },
};
</script>