<template>
  <table border="1" class="table tableexport table-bordered wb">
    <thead>
      <tr>
        <th style="text-align: center">№ п/п</th>
        <th style="text-align: center">Корпус</th>
        <th>Преподаватель</th>
        <th>Группа</th>
        <th>Дисциплина</th>
        <th>Количество незаполненных занятий</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(val, key) in ocenka" :key="key">
        <td style="text-align: center">{{ key + 1 }}</td>
        <td class="small" style="text-align: center">{{ val.sokr_setka }}</td>
        <td class="small">{{ val.p }}</td>
        <td class="small">{{ val.groups }}</td>
        <td class="small">{{ val.Predmet }}</td>
        <td class="small">{{ val.v }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["d1", "logoutweb"],
  data() {
    return {
      ocenka: "",
    };
  },
  mounted() {
    this.reportktp();
  },

  methods: {
    reportktp() {
      server
        .submittoserver(
          server.urlsecond.prepod.reportktpall + this.d1,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
