<script setup>
defineProps(["modelValue", "periods", "changeperiod", "dates"]);
defineEmits(["update:modelValue"]);
</script>

<template>
  <select
    class="form-control form-select"
    @change="
      changeperiod(
        $event.target.value.split(' - ')[0],
        $event.target.value.split(' - ')[1]
      )
    "
  >
    <option
      v-for="e in periods"
      :value="e"
      :key="e"
      :selected="dates == e"
    >
      {{ e }}
    </option>
  </select>

</template>


<script >
export default {
  props: ["periods"],
};
</script>