<template>
  <div id="groupvisitklass">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-md-3">
          <VueDatePicker
            v-model="dates"
            locale="ru-RU"
            auto-apply
            inline
            @update:model-value="onDayClick"
            calendarCellClassName="form-control"
            inputClassName="form-control"
            autoPosition
            position="left"
            :dark="version == 'dark'"
            model-type="dd.MM.yyyy"
          ></VueDatePicker>
        </div>
        <div class="col-md-3" v-if="dates">
          <select
            class="form-control groups"
            v-model="groups.selected"
            @change="onDayClick1"
          >
            <option
              v-for="(vs, k) in groups.options"
              :selected="k == 0"
              :key="vs.kod"
              :value="vs.kod"
            >
              {{ vs.naim }}
            </option>
          </select>
        </div>
      </div>

      <div id="table" class="mt-1">
        <span class="font-weight-bold">{{ dates }}</span>
        <div id="resource">
          <div class="mt10 table-responsive">
            <table
              border="1"
              class="table tableexport table-bordered wb"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";

export default {
  props: ["d1", "d2", "version", "loadchange", "logoutweb"],
  data() {
    return {
      ocenka: "",
      dates: "",

      groups: {
        selected: 0,
        options: [],
      },
    };
  },
  mounted() {
    this.getgroups();
  },

  updated: function () {
    if (document.querySelectorAll(".getklasspos").length) {
      document.querySelectorAll(".getklasspos").forEach((el) => {
        el.addEventListener("change", (ele) => {
          this.selectchange(ele.target.dataset.stud, ele.target.value);
        });
      });
    }
    if (document.querySelectorAll(".getklasspos_all").length) {
      document.querySelectorAll(".getklasspos_all").forEach((el) => {
        el.addEventListener("change", (ele) => {
          this.selectchange_all(ele.target.dataset.stud, ele.target.value);
        });
      });
    }
  },

  methods: {
    getgroups() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroupsklass + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups.options = res;
          this.groups.selected = this.groups.options[0].kod;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    selectchange(stud, pos) {
      server
        .submittoserver(
          `${
            server.urlsecond.student.setstarostapos + stud.split("-").join("/")
          }/${pos}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "groupvisitklass", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    selectchange_all(stud, pos) {
      this.loadchange(false);
      server
        .submittoserver(
          `${
            server.urlsecond.student.setstarostapos_all + this.dates
          }/${stud}/${pos}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let allsel = document.querySelectorAll(
            "body tr#str" + stud + " select"
          );
          allsel.forEach((el) => {
            el.value = pos;
          });
          server.messages(res, "groupvisitklass", [
            { mess: "message", class: "success" },
          ]);
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    onDayClick() {
      this.ocenka = "";
      this.getklasspos(this.dates);
    },
    onDayClick1() {
      this.getklasspos(this.dates);
    },

    getklasspos(dates) {
      this.loadchange(false);
      server
        .submittoserver(
          `${
            server.urlsecond.prepod.getklasspos + this.groups.selected
          }/${dates}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
