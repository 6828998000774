<template>
  <div id="doc">
    <div class="hk-sec-wrapper">
      <p>
        Необходимые документы для поступления необходимо отсканировать или
        сфотографировать с высоким разрешением и качеством
      </p>
    </div>

    <section class="hk-sec-wrapper row align-items-end text-center">
      <div class="row">
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">
              Уведомление обучаться только в ГБПОУ ЧГПГТ им.А.В.Яковлева
            </h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone1"
                id="dropzone1"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions1 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">Заявление на общежитие</h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone2"
                id="dropzone2"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions2 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">
              Согласие на обработку персональных данных
            </h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone3"
                id="dropzone3"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions3 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">
              Документ об образовании - титульный лист
            </h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone8"
                id="dropzone8"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions8 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">Документ об образовании - оценки</h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone4"
                id="dropzone4"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions4 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">Фотография пользователя</h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone5"
                id="dropzone5"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions5 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">Паспорт (2-3 страница с фотографией)</h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone7"
                id="dropzone7"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions7 }"
              />
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card">
            <h5 class="card-header">Паспорт (4-5 страница)</h5>
            <div class="card-body">
              <vue-dropzone
                ref="myVueDropzone6"
                id="dropzone6"
                @vdropzone-removed-file="removefile"
                :options="{ ...dropzoneob, ...dropzoneOptions6 }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="card">
          <h5 class="card-header">
            Дополнительные документы (в том числе заявление на поступление)
          </h5>
          <div class="card-body">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              @vdropzone-removed-file="removefile"
              :options="{ ...dropzoneob, ...dropzoneOptions }"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="hk-pg-header align-items-top mt-xl-50 mt-sm-30 mt-15">
      <div>
        <h2 class="hk-pg-title font-weight-600 mb-10">Инструкция</h2>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <h5 class="text-center mb-15">Перечень документов для поступления</h5>
      <h5 class="text-center">Основные документы</h5>
      <ol>
        <li>
          Документ об образовании или его копия (оригинал предоставить до 16.08
          до 16.00)
        </li>
        <li>Копия паспорта 2 шт</li>
        <li>Фото 3х4 — 4 шт</li>
      </ol>
      <h5 class="text-center">Дополнительные документы</h5>
      <ol>
        <li>
          Медицинская справка - 086у (с заключение о профригодности к обучению с
          указанием профессии, специальности) предоставляется по форме
          утвержденной приказом Министерства здравоохранения РФ от 15.12.2014 №
          834н)
        </li>
        <li>Медицинская карта школьника по форме № 026/У (карта прививок)</li>
        <li>Копия страхового свидетельства</li>
        <li>Копия медицинского полиса</li>
        <li>Копия свидетельства ИНН</li>
        <li>Согласие на обработку персональных данных</li>
      </ol>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import vueDropzone from "dropzone-vue3";
export default {
  props: ["blocked", "loadchange", "logoutweb"],
  components: {
    vueDropzone,
  },
  data() {
    return {
      dropzoneob: {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        acceptedFiles: ".jpg,.png",
        addRemoveLinks: true,
        dictDefaultMessage: "Добавьте необходимые документы",
        dictInvalidFileType: "Тип файла не подходит",
        dictRemoveFile: "Удалить",
        dictCancelUpload: "Отменить",
        paramName: "photo",
        humbnailWidth: 200,
      },
      dropzoneOptions: {
        url: server.server + server.urlsecond.abiturient.setphoto + "5",
      },
      dropzoneOptions1: {
        url: server.server + server.urlsecond.abiturient.setphoto + "1",
        maxFiles: 1,
      },
      dropzoneOptions2: {
        url: server.server + server.urlsecond.abiturient.setphoto + "13",
        maxFiles: 1,
      },
      dropzoneOptions3: {
        url: server.server + server.urlsecond.abiturient.setphoto + "8",
        maxFiles: 1,
      },
      dropzoneOptions4: {
        url: server.server + server.urlsecond.abiturient.setphoto + "7",
        maxFiles: 1,
      },
      dropzoneOptions5: {
        url: server.server + server.urlsecond.abiturient.setphoto + "6",
        maxFiles: 1,
      },
      dropzoneOptions6: {
        url: server.server + server.urlsecond.abiturient.setphoto + "3",
        maxFiles: 1,
      },
      dropzoneOptions7: {
        url: server.server + server.urlsecond.abiturient.setphoto + "2",
        maxFiles: 1,
      },
      dropzoneOptions8: {
        url: server.server + server.urlsecond.abiturient.setphoto + "1",
        maxFiles: 1,
      },
    };
  },
  mounted() {
    this.doc();
  },
  methods: {
    removefilecustom(id) {
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    removefile(th) {
      let id = JSON.parse(th.xhr.response).id;
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    getdocs(docs, selector) {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.abiturient.getphoto + docs)
        .then((res) => {
          let parent = document.querySelector(selector);
          res.forEach((el) => {
            parent.classList.add("dz-started");
            let div = document.createElement("div");
            div.classList.add(
              "dz-preview",
              "dz-processing",
              "dz-image-preview",
              "dz-success",
              "dz-complete"
            );
            let divimage = document.createElement("div");
            divimage.classList.add("dz-image");

            let image = document.createElement("img");
            image.src = server.ftp + el.path;
            image.classList.add("img-fluid");
            divimage.appendChild(image);

            let dzdetails = document.createElement("div");
            dzdetails.classList.add("dz-details");
            div.appendChild(divimage);
            div.appendChild(dzdetails);
            let a = document.createElement("a");
            a.classList.add("dz-remove");
            a.href = "javascript:undefined;";
            a.innerHTML = "Удалить";
            div.appendChild(a);
            a.addEventListener("click", () => {
              this.removefilecustom(el.id);
              div.remove();
            });
            parent.appendChild(div);
          });
          this.loadchange(true);
        });
    },
    doc() {
      this.getdocs(5, "#dropzone");
      this.getdocs(14, "#dropzone1");
      this.getdocs(13, "#dropzone2");
      this.getdocs(8, "#dropzone3");
      this.getdocs(7, "#dropzone4");
      this.getdocs(6, "#dropzone5");
      this.getdocs(3, "#dropzone6");
      this.getdocs(2, "#dropzone7");
      this.getdocs(1, "#dropzone8");
    },
  },
};
</script>
