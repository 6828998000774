<template>
  <Preloader v-if="!load" />
  <div v-if="!token">
    <Auth :addToken="addToken" :loadchange="loadchange" :logoutweb="logoutweb" :logo="logo" />
  </div>
  <div id="main-wrapper" v-else>
    <NavHeader :logo="logo" />
    <Header :mero="mero" :profile="myprofile" :title="title" :logout="logout" :version="version"
      :changeversion="changeversion" :changePrimaryColor="changePrimaryColor" :primarycolor="primarycolor"
      :navheaderBg="navheaderBg" :changenavheaderBg="changenavheaderBg" :header_bg="header_bg"
      :changeheader_bg="changeheader_bg" :sidebarBg="sidebarBg" :changesidebarBg="changesidebarBg"
      :changelayout="changelayout" :layout="layout" :sidebarStyle="sidebarStyle" :sidebarchange="sidebarchange"
      :headerPositionChange="headerPositionChange" :headerPosition="headerPosition"
      :sidebarPositionChange="sidebarPositionChange" :sidebarPosition="sidebarPosition" :savetheme="savetheme"
      :containerLayoutChange="containerLayoutChange" :containerLayout="containerLayout" :resetstyle="resetstyle"
      :avatar="avatar" />
    <Menu :fio="fio" :component="component" :load="load" :profile="myprofile" :menu="menu" :roletext="roletext"
      :avatar="avatar" :changecontentall="changecontentall" :logout="logout" />

    <div class="content-body position-relative1">
      <div class="container-fluid">
        <div class="row page-titles">
          <Breadcrumb :title="title" :parent="parent" />
        </div>
        <div class="row">
          <div class="col-xl-12">
            <raspisanie :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'raspisanie'"
              :version="version" />
            <academicperformance :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'academicperformance' && renderComponent" :mobile="mobile" />
            <profile :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'profile'" :roletext="roletext"
              :fio="fio" :avatar="avatar" />
            <coursework :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'coursework' && renderComponent" :mobile="mobile" />
            <diploms :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'diploms' && renderComponent" />
            <practicemanagement :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'practicemanagement' && renderComponent" :mobile="mobile" />
            <violationstudents :d1="d1" :d2="d2" :version="version" :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'violationstudents' && renderComponent" />
            <groupvisitklass :d1="d1" :d2="d2" :version="version" :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'groupvisitklass' && renderComponent" />
            <personaldata :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'personaldata' && renderComponent" />
            <mydostig :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'mydostig' && renderComponent" :mobile="mobile" />
            <projectmanagement :loadchange="loadchange" :logoutweb="logoutweb" :version="version"
              v-if="component == 'projectmanagement' && renderComponent" />
            <planning :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'planning' && renderComponent" />
            <gto :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'gto' && renderComponent" />
            <ktp :periods="periods" :d1="d1" :d2="d2" :token="token" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'ktp' && renderComponent" :mobile="mobile" />
            <averageacademicperformance :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'averageacademicperformance' && renderComponent
                " :mobile="mobile" />
            <preproofreading :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'preproofreading' && renderComponent" :mobile="mobile"/>
            <percentageofprogress :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'percentageofprogress' && renderComponent" :mobile="mobile" />
            <basicschedule :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'basicschedule' && renderComponent" :mobile="mobile" />
            <readhours :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'readhours' && renderComponent" :mobile="mobile" />
            <instructorsreportonprogressandproofreading :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'instructorsreportonprogressandproofreading' &&
                renderComponent
                " :mobile="mobile" />
            <preliminarygrades :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'preliminarygrades' && renderComponent" :mobile="mobile" />
            <monthlyvisit :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'monthlyvisit' && renderComponent" :mobile="mobile" />
            <visitduringtheperiod :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'visitduringtheperiod' && renderComponent" :mobile="mobile" />
            <informationaboutstudents :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'informationaboutstudents' && renderComponent" :mobile="mobile" />
            <graphicalpresentationofprogress :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'graphicalpresentationofprogress' &&
                renderComponent
                " :mobile="mobile"/>
            <achievementsofstudents :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'achievementsofstudents' && renderComponent" :mobile="mobile" />
            <achievementsofstudentsduringtheperiod :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'achievementsofstudentsduringtheperiod' &&
                renderComponent
                " :mobile="mobile" />
            <hometeacherreport :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'hometeacherreport' && renderComponent" :mobile="mobile" />
            <grouplistwithpassportdata :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'grouplistwithpassportdata' && renderComponent" :mobile="mobile"/>
            <violationreport :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'violationreport' && renderComponent" :mobile="mobile" />
            <studentprofile :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'studentprofile' && renderComponent" :mobile="mobile" />
            <groupsocialpassport :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'groupsocialpassport' && renderComponent" :mobile="mobile" />
            <summarysheetofBBMattendance :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'summarysheetofBBMattendance' && renderComponent
                " :mobile="mobile" />
            <academicperformance3 :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'academicperformance3' && renderComponent" :mobile="mobile" />
            <bbmsemestersummaryreport :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'bbmsemestersummaryreport' && renderComponent" :mobile="mobile" />
            <bbmrating :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'bbmrating' && renderComponent" :mobile="mobile" />
            <reportsem :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'reportsem' && renderComponent" :mobile="mobile"/>
            <averageacademicperformancebyyears :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" :mobile="mobile" v-if="component == 'averageacademicperformancebyyears' &&
                renderComponent
                " />
            <finalscholarshipperformance :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" v-if="component == 'finalscholarshipperformance' && renderComponent
                " :mobile="mobile"/>
            <reitstudbbm :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'reitstudbbm' && renderComponent" :mobile="mobile" />
            <analitable :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'analitable' && renderComponent" :mobile="mobile" />
            <plansetka :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'plansetka' && renderComponent" :mobile="mobile" />
            <readhours_admin :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'readhours_admin' && renderComponent" :mobile="mobile" />
            <reportklassrukind :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'reportklassrukind' && renderComponent" :mobile="mobile" />
            <posuspevforperiod :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'posuspevforperiod' && renderComponent" :mobile="mobile" />
            <svodvedprmatt :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'svodvedprmatt' && renderComponent" :mobile="mobile"/>
            <reportklassrukindall :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb" :version="version"
              v-if="component == 'reportklassrukindall' && renderComponent" :mobile="mobile" />
            <instructorsreportonprogressandproofreadingadmin :periods="periods" :d1="d1" :d2="d2"
              :changeperiod="changeperiod" :loadchange="loadchange" :logoutweb="logoutweb" v-if="component ==
                'instructorsreportonprogressandproofreadingadmin' &&
                renderComponent
                " :mobile="mobile" />

            <uchastrepotrall :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'uchastrepotrall' && renderComponent" :mobile="mobile" />
            <svodallreport :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" :version="version" v-if="component == 'svodallreport' && renderComponent" :mobile="mobile" />
            <avgrepoduspev :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'avgrepoduspev' && renderComponent" :mobile="mobile"/>
            <svodinforesultpos :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'svodinforesultpos' && renderComponent" :mobile="mobile" />
            <reportadmin :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'reportadmin' && renderComponent" :mobile="mobile"/>
            <academicperformance2 :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod"
              :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'academicperformance2' && renderComponent" :mobile="mobile"/>
            <preliminarygrades2 :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'preliminarygrades2' && renderComponent" :mobile="mobile" />
            <achievements :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'achievements' && renderComponent" :mobile="mobile" />
            <violations :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'violations' && renderComponent" :mobile="mobile" />
            <attendance :periods="periods" :d1="d1" :d2="d2" :changeperiod="changeperiod" :loadchange="loadchange"
              :logoutweb="logoutweb" v-if="component == 'attendance' && renderComponent" :mobile="mobile" />
            <finalperformance :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'finalperformance' && renderComponent" :mobile="mobile" />
            <abiturient :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked" :version="version"
              v-if="component == 'abiturient' && renderComponent" />
            <home :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked" :token="token"
              v-if="component == 'home' && renderComponent" />
            <passport :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked" :version="version"
              v-if="component == 'passport' && renderComponent" />
            <education :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'education' && renderComponent" />
            <refusaltoenroll :loadchange="loadchange" :logoutweb="logoutweb" :changeblokced="changeblocked"
              :blocked="blocked" v-if="component == 'refusaltoenroll' && renderComponent" />
            <questionnaire :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'questionnaire' && renderComponent" />
            <inddostig :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'inddostig' && renderComponent" />
            <disability :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'disability' && renderComponent" />
            <orphan :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'orphan' && renderComponent" />
            <doc :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'doc' && renderComponent" />
            <parents :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'parents' && renderComponent" />
            <marks :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked"
              v-if="component == 'marks' && renderComponent" />
            <address2 :loadchange="loadchange" :logoutweb="logoutweb" :blocked="blocked" :version="version"
              v-if="component == 'address2' && renderComponent" />
            <groupvisit :version="version" :loadchange="loadchange" :logoutweb="logoutweb"
              v-if="component == 'groupvisit' && renderComponent" />
            <admin :version="version" :loadchange="loadchange" :addToken="addToken" :logoutweb="logoutweb"
              v-if="component == 'admin' && renderComponent" />
          
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="copyright">
        <p>
          Powered by

          ГБПОУ ЧГПГТ им.А.В.Яковлева
          © {{ new Date().getFullYear() }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { dlabSettings } from "@/assets/js/custom";

import Preloader from "./components/Preloader.vue";
import Menu from "./components/Menu.vue";
import NavHeader from "./components/NavHeader.vue";
import Header from "./components/Header.vue";
import Breadcrumb from "./components/Breadcrumb.vue";
import Auth from "./page/Auth.vue";
import server from "@/assets/js/server";
import chgpgt from "@/assets/js/custom.min";
import raspisanie from "./content/raspisanie.vue";
import academicperformance from "./content/prepod/academicperformance.vue";
import profile from "./content/profile.vue";
import coursework from "./content/prepod/coursework.vue";
import diploms from "./content/prepod/diploms.vue";
import practicemanagement from "./content/prepod/practicemanagement.vue";
import violationstudents from "./content/klass/violationstudents.vue";
import groupvisitklass from "./content/klass/groupvisitklass.vue";
import personaldata from "./content/klass/personaldata.vue";
import mydostig from "./content/prepod/mydostig.vue";
import projectmanagement from "./content/prepod/projectmanagement.vue";
import planning from "./content/admin/planning.vue";
import gto from "./content/prepod/gto.vue";
import ktp from "./content/prepod/ktp.vue";
import averageacademicperformance from "./content/prepod/averageacademicperformance.vue";
import preproofreading from "./content/prepod/preproofreading.vue";
import percentageofprogress from "./content/prepod/percentageofprogress.vue";
import basicschedule from "./content/prepod/basicschedule.vue";
import readhours from "./content/prepod/readhours.vue";
import instructorsreportonprogressandproofreading from "./content/prepod/instructorsreportonprogressandproofreading.vue";
import preliminarygrades from "./content/klass/preliminarygrades.vue";
import monthlyvisit from "./content/klass/monthlyvisit.vue";
import visitduringtheperiod from "./content/klass/visitduringtheperiod.vue";
import informationaboutstudents from "./content/klass/informationaboutstudents.vue";
import graphicalpresentationofprogress from "./content/klass/graphicalpresentationofprogress.vue";
import achievementsofstudents from "./content/klass/achievementsofstudents.vue";
import achievementsofstudentsduringtheperiod from "./content/klass/achievementsofstudentsduringtheperiod.vue";
import hometeacherreport from "./content/klass/hometeacherreport.vue";
import grouplistwithpassportdata from "./content/klass/grouplistwithpassportdata.vue";
import violationreport from "./content/klass/violationreport.vue";
import studentprofile from "./content/klass/studentprofile.vue";
import groupsocialpassport from "./content/klass/groupsocialpassport.vue";
import summarysheetofBBMattendance from "./content/klass/summarysheetofBBMattendance.vue";
import academicperformance3 from "./content/klass/academicperformance3.vue";
import bbmsemestersummaryreport from "./content/admin/bbmsemestersummaryreport.vue";
import bbmrating from "./content/klass/bbmrating.vue";
import averageacademicperformancebyyears from "./content/admin/averageacademicperformancebyyears.vue";
import finalscholarshipperformance from "./content/admin/finalscholarshipperformance.vue";
import reitstudbbm from "./content/admin/reitstudbbm.vue";
import analitable from "./content/admin/analitable.vue";
import plansetka from "./content/prepod/plansetka.vue";
import readhours_admin from "./content/admin/readhours_admin.vue";
import reportklassrukind from "./content/klass/reportklassrukind.vue";
import reportsem from "./content/klass/reportsem.vue";
import posuspevforperiod from "./content/admin/posuspevforperiod.vue";
import svodvedprmatt from "./content/admin/svodvedprmatt.vue";
import reportklassrukindall from "./content/admin/reportklassrukindall.vue";
import instructorsreportonprogressandproofreadingadmin from "./content/admin/instructorsreportonprogressandproofreadingadmin.vue";
import uchastrepotrall from "./content/admin/uchastrepotrall.vue";
import svodallreport from "./content/admin/svodallreport.vue";
import avgrepoduspev from "./content/admin/avgrepoduspev.vue";
import svodinforesultpos from "./content/admin/svodinforesultpos.vue";
import reportadmin from "./content/admin/reportadmin.vue";
import academicperformance2 from "./content/studentsparents/academicperformance2.vue";
import preliminarygrades2 from "./content/studentsparents/preliminarygrades2.vue";
import achievements from "./content/studentsparents/achievements.vue";
import violations from "./content/studentsparents/violations.vue";
import attendance from "./content/studentsparents/attendance.vue";
import finalperformance from "./content/studentsparents/finalperformance.vue";
import home from "./content/abiturient/home.vue";
import abiturient from "./content/abiturient/abiturient.vue";
import passport from "./content/abiturient/passport.vue";
import education from "./content/abiturient/education.vue";
import refusaltoenroll from "./content/abiturient/refusaltoenroll.vue";
import questionnaire from "./content/abiturient/questionnaire.vue";
import inddostig from "./content/abiturient/inddostig.vue";
import disability from "./content/abiturient/disability.vue";
import orphan from "./content/abiturient/orphan.vue";
import doc from "./content/abiturient/doc.vue";
import parents from "./content/abiturient/parents.vue";
import marks from "./content/abiturient/marks.vue";
import address2 from "./content/abiturient/address2.vue";
import groupvisit from "./content/starosta/groupvisit.vue";
import admin from "./content/admin/admin.vue";
export default {
  components: {
    Preloader,
    Menu,
    NavHeader,
    Header,
    Breadcrumb,
    Auth,
    raspisanie,
    academicperformance,
    profile,
    coursework,
    diploms,
    practicemanagement,
    violationstudents,
    groupvisitklass,
    personaldata,
    mydostig,
    projectmanagement,
    planning,
    gto,
    ktp,
    averageacademicperformance,
    preproofreading,
    percentageofprogress,
    basicschedule,
    readhours,
    instructorsreportonprogressandproofreading,
    preliminarygrades,
    monthlyvisit,
    visitduringtheperiod,
    informationaboutstudents,
    graphicalpresentationofprogress,
    achievementsofstudents,
    achievementsofstudentsduringtheperiod,
    hometeacherreport,
    grouplistwithpassportdata,
    violationreport,
    studentprofile,
    groupsocialpassport,
    summarysheetofBBMattendance,
    academicperformance3,
    bbmsemestersummaryreport,
    bbmrating,
    averageacademicperformancebyyears,
    finalscholarshipperformance,
    reitstudbbm,
    analitable,
    plansetka,
    readhours_admin,
    reportklassrukind,
    posuspevforperiod,
    svodvedprmatt,
    reportklassrukindall,
    instructorsreportonprogressandproofreadingadmin,
    uchastrepotrall,
    svodallreport,
    avgrepoduspev,
    svodinforesultpos,
    reportadmin,
    academicperformance2,
    preliminarygrades2,
    achievements,
    violations,
    attendance,
    finalperformance,
    home,
    abiturient,
    passport,
    education,
    refusaltoenroll,
    questionnaire,
    inddostig,
    disability,
    orphan,
    doc,
    parents,
    marks,
    address2,
    groupvisit,
    admin,
    reportsem,
  },
  data() {
    return {
      token: null,
      fio: null,
      load: true,
      component: "",
      renderComponent: true,
      title: "",
      periods: null,
      parent: "",
      menu: [],
      mero: [],
      blocked: false,
      d1: null,
      d2: null,
      role: null,
      roletext: null,
      avatar: null,
      mobile: false,
      primarycolor: localStorage.getItem("primarycolor") || "color_1",
      navheaderBg: localStorage.getItem("navheaderBg") || "color_1",
      header_bg: localStorage.getItem("header_bg") || "color_1",
      version: localStorage.getItem("version") || "light",
      sidebarBg: localStorage.getItem("sidebarBg") || "color_1",
      layout: localStorage.getItem("layout") || "vertical",
      headerPosition: localStorage.getItem("headerPosition") || "fixed",
      sidebarStyle: localStorage.getItem("sidebarStyle") ?? "full",
      sidebarPosition: localStorage.getItem("sidebarPosition") ?? "fixed",
      containerLayout: localStorage.getItem("containerLayout") ?? "wide",
      logo: "/img/logo-full.png",
    };
  },
  created() {
    this.loadchange(false);
    if (localStorage.getItem("token")) {
      this.addToken(localStorage.getItem("token"));
    }
    this.logo =
      this.version == "light" ? "/img/logo-full.png" : "/img/logo-full2.png";
    this.isMobile();
  },
  methods: {
    savetheme() {
      let send = {
        version: this.version,
        layout: this.layout,
        primarycolor: this.primarycolor,
        headerBg: this.headerBg,
        navheaderBg: this.navheaderBg,
        sidebarBg: this.sidebarBg,
        sidebarStyle: this.sidebarStyle,
        sidebarPosition: this.sidebarPosition,
        headerPosition: this.headerPosition,
        containerLayout: this.containerLayout,
      };
      server
        .submittoserver(server.urlsecond.themeset, send)
        .then((res) => {
          server.toasts(res.message, "success");

          // chgpgt.init();
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
    addToken(token) {
      this.token = token;
      this.getmenu();
    },

    changeblocked(change) {
      this.blocked = change;
    },

    changecontentall(a, b, prop = "", parent = "") {
      if (
        this.menu.some(
          (e) => e.component == a || e.child.some((ee) => ee.component == a)
        ) ||
        a == "profile"||
        a == "admin"
      ) {
        this.component = a;
        this.title = b;
        this.parent = parent;
        localStorage.setItem("component", a);
        localStorage.setItem("title", b);
        localStorage.setItem("parent", parent);
        localStorage.setItem("prop", prop || localStorage.getItem("prop"));
      } else {
        this.changecontentall(
          this.menu[0].component,
          this.menu[0].title,
          this.menu[0].prop
        );
      }


      if (document.documentElement.clientWidth < 768) {
        document
          .querySelector("#main-wrapper")
          .classList.remove("menu-toggle");
        document.querySelector(".hamburger").classList.remove("is-active");
      }
      // $(".nav-control").click();
      // document.querySelector(".nav-control").onclick();
    },
    openmenu(a) {
      let r = JSON.parse(a);

      function menucreate(i = 0) {
        let rrr = [];
        r.forEach((e) => {
          if (e.parentid == i) {
            e.child = menucreate(e.id);
            rrr.push(e);
          }
        });
        rrr.sort((f, s) => f.position - s.position);
        return rrr;
      }
      this.menu = menucreate();

      this.changecontentall(
        localStorage.getItem("component") || "home",
        localStorage.getItem("title") || "Главная",
        "",
        localStorage.getItem("parent")
      );
      setTimeout(function () {
        chgpgt.menu();
      }, 1e3);
    },
    myprofile() {
      this.changecontentall("profile", "Мой профиль", "", "");
    },
    isMobile() {
      if (screen.width <= 760) {
        this.mobile=true;
      } else {
        this.mobile=false;
      }
    },

    changeperiod(d1, d2) {
      this.d1 = d1;
      this.d2 = d2;
      this.forceRerender();
    },
    themeset(
      version = "light",
      layout = "vertical",
      primarycolor = "color_1",
      headerBg = "color_1",
      navheaderBg = "color_1",
      sidebarBg = "color_1",
      sidebarStyle = "full",
      sidebarPosition = "fixed",
      headerPosition = "fixed",
      containerLayout = "wide"
    ) {
      this.version = version;
      document.body.dataset.themeVersion = version;
      localStorage.setItem("version", version);
      this.layout = layout;
      document.body.dataset.layout = layout;
      localStorage.setItem("layout", layout);
      this.primarycolor = primarycolor;
      document.body.dataset.primary = primarycolor;
      localStorage.setItem("primarycolor", primarycolor);
      this.headerBg = headerBg;
      document.body.dataset.headerbg = headerBg;
      localStorage.setItem("header_bg", headerBg);
      this.navheaderBg = navheaderBg;
      document.body.dataset.navHeaderbg = navheaderBg;
      localStorage.setItem("navheaderBg", navheaderBg);
      this.sidebarBg = sidebarBg;
      document.body.dataset.sidebarbg = sidebarBg;
      localStorage.setItem("sidebarBg", sidebarBg);
      this.sidebarStyle = sidebarStyle;
      document.body.dataset.sidebarStyle = sidebarStyle;
      localStorage.setItem("sidebarStyle", sidebarStyle);
      this.sidebarPosition = sidebarPosition;
      document.body.dataset.sidebarPosition = sidebarPosition;
      localStorage.setItem("sidebarPosition", sidebarPosition);
      this.headerPosition = headerPosition;
      document.body.dataset.headerPosition = headerPosition;
      localStorage.setItem("headerPosition", headerPosition);
      this.containerLayout = containerLayout;
      document.body.dataset.container = containerLayout;
      localStorage.setItem("containerLayout", containerLayout);
      let dlabSettingsOptions = {
        version: localStorage.getItem("version") ?? "light",
        layout: localStorage.getItem("layout") ?? "vertical",
        primary: localStorage.getItem("primarycolor") ?? "color_1",
        headerBg: localStorage.getItem("header_bg") ?? "color_1",
        navheaderBg: localStorage.getItem("navheaderBg") ?? "color_1",
        sidebarBg: localStorage.getItem("sidebarBg") ?? "color_1",
        sidebarStyle: localStorage.getItem("sidebarStyle") ?? "full",
        sidebarPosition: localStorage.getItem("sidebarPosition") ?? "fixed",
        headerPosition: localStorage.getItem("headerPosition") ?? "fixed",
        containerLayout: localStorage.getItem("containerLayout") ?? "wide",
      };
      new dlabSettings(dlabSettingsOptions);
    },
    resetstyle() {
      this.themeset();
      this.savetheme();
    },

    getmenu() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.menuwithrolenew)
        .then((res) => {
          // console.log(res);
          this.openmenu(JSON.stringify(Object.values(res.menu)));
          let d = [];
          res.dates.forEach((e) => {
            if (e.f) {
              this.d1 = e.data_in;
              this.d2 = e.data_ou;
            }
            d.push(e.data_in + " - " + e.data_ou);
          });
          if (!this.d1) {
            let dd = d[0].split(" - ");
            this.d1 = dd[0];
            this.d2 = dd[1];
          }
          this.periods = d;
          this.fio = `${res.user.Fam} ${res.user.Name}`;
          this.roletext = res.user.Roletext;
          this.avatar = res.user.avatar;
          this.role = res.user.role;
          this.blocked = res.user.blocked;

          if (res.theme) {
            let theme = JSON.parse(res.theme.theme);
            this.themeset(
              theme.version,
              theme.layout,
              theme.primarycolor,
              theme.headerBg,
              theme.navheaderBg,
              theme.sidebarBg,
              theme.sidebarStyle,
              theme.sidebarPosition,
              theme.headerPosition,
              theme.containerLayout
            );
          } else {
            this.themeset();
          }
          this.mero = res.mero;
          chgpgt.init();
          document.getElementById("main-wrapper").classList.add("show");

          this.loadchange(true);
        })
        .catch((err) => {
          console.log(err);
          this.loadchange(true);
          server.toasts("Ошибка авторизации или подключения", "error");
          this.logoutweb();
        });
    },
    loadchange(load) {
      this.load = load;
    },
    logoutweb() {
      localStorage.removeItem("token");
      localStorage.removeItem("content");
      localStorage.removeItem("title");
      localStorage.removeItem("blocked");
      localStorage.removeItem("component");
      localStorage.removeItem("prop");
      localStorage.removeItem("parent");
      this.menu = [];
      this.token = null;
    },
    logout() {
      server
        .submittoserver(server.urlsecond.logout)
        .then((res) => {
          if (res.error === undefined) {
            this.logoutweb();
          }
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },
    changeversion(version) {
      let v = version == "light" ? "dark" : "light";
      this.version = v;
      localStorage.setItem("version", v);
      document.body.dataset.themeVersion = v;
      this.logo = v == "light" ? "/img/logo-full.png" : "/img/logo-full2.png";
    },
    changePrimaryColor(color) {
      this.primarycolor = color;
      document.body.dataset.primary = color;
      localStorage.setItem("primarycolor", color);
    },
    changenavheaderBg(color) {
      this.navheaderBg = color;
      document.body.dataset.navHeaderbg = color;
      localStorage.setItem("navheaderBg", color);
    },
    changeheader_bg(color) {
      this.header_bg = color;
      document.body.dataset.headerbg = color;
      localStorage.setItem("header_bg", color);
    },
    changesidebarBg(color) {
      this.sidebarBg = color;
      document.body.dataset.sidebarbg = color;
      localStorage.setItem("sidebarBg", color);
    },
    changelayout(layout) {
      if (document.body.dataset.sidebarStyle == "overlay") {
        document.body.dataset.sidebarStyle == "full";
        this.sidebarStyle = "full";
        localStorage.setItem("sidebarStyle", "full");
      }
      this.layout = layout.target.value;
      document.body.dataset.layout = layout.target.value;
      localStorage.setItem("layout", layout.target.value);
    },

    headerPositionChange(event) {
      this.headerPosition = event.target.value;
      document.body.dataset.headerPosition = event.target.value;
      localStorage.setItem("headerPosition", event.target.value);
    },

    sidebarchange(sidebar) {
      if (this.layout == "horizontal") {
        if (sidebar.target.value === "overlay") {
          alert("Наложение невозможно в горизонтальном расположении.");
          return;
        }
      }

      if (this.layout == "vertical") {
        if (
          document.body.dataset.container == "boxed" &&
          sidebar.target.value == "full"
        ) {
          alert("Полное меню недоступно в макете с вертикальным блоком.");
          return;
        }

        if (
          sidebar.target.value == "modern" &&
          document.body.dataset.sidebarPosition === "fixed"
        ) {
          alert(
            "Современный макет боковой панели недоступен в фиксированном положении. Пожалуйста, измените положение боковой панели на Статическое."
          );
          return;
        }
      }
      document.body.dataset.sidebarStyle = sidebar.target.value;

      if (document.body.dataset.sidebarStyle == "icon-hover") {
        document.querySelector(".dlabnav").addEventListener("mouseover", () => {
          document.querySelector("#main-wrapper").classList.add("iconhover-toggle");
        });
        document.querySelector(".dlabnav").addEventListener("mouseout", () => {
          document.querySelector("#main-wrapper").classList.remove("iconhover-toggle");
        });

      }
      this.sidebarStyle = sidebar.target.value;
      localStorage.setItem("sidebarStyle", sidebar.target.value);
    },
    sidebarPositionChange(event) {
      if (
        this.layout == "vertical" &&
        event.target.value == "fixed" &&
        this.sidebarStyle == "modern"
      ) {
        alert(
          "Современный макет боковой панели не поддерживает фиксированное положение!"
        );
        return;
      }
      document.body.dataset.sidebarPosition = event.target.value;
      this.sidebarPosition = event.target.value;
      localStorage.setItem("sidebarPosition", event.target.value);
    },
    containerLayoutChange(event) {
      if (event.target.value === "boxed") {
        if (
          this.layout == "vertical" &&
          document.body.dataset.sidebarStyle == "full"
        ) {
          document.body.dataset.sidebarStyle = "overlay";
          this.sidebarStyle = "overlay";
          localStorage.setItem("sidebarStyle", "overlay");
        }
      }
      this.containerLayout = event.target.value;
      document.body.dataset.container = event.target.value;
      localStorage.setItem("containerLayout", event.target.value);

    },
  },
};
</script>