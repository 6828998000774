<template>
  <div id="mydostig">
    <div class="row">
      <div class="col-md-3">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div class="row py-1">
          <div class="col-md-12">
            <div
              class="accordion accordion-bordered accordion-primary-solid"
              id="accordion_2"
            >
              <div
                class="accordion-item"
                v-for="(val, n, isc) in groups"
                :key="isc"
              >
                <div class="accordion-header">
                  <button
                    v-html="'<strong>' + val.di + '</strong>&nbsp;' + val.Nazv"
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#s' + n"
                    aria-expanded="false"
                    :aria-controls="'#s' + n"
                  ></button>
                </div>
                <div
                  :id="'s' + n"
                  class="collapse"
                  data-parent="#accordion_2"
                  role="tabpanel"
                >
                  <div class="accordion-body">
                    <table class="table table-bordered small">
                      <tbody>
                        <tr>
                          <th class="p-2">Вид</th>
                          <td class="p-2">{{ val.mero }}</td>
                        </tr>
                        <tr>
                          <th class="p-2">Направление</th>
                          <td class="p-2">{{ val.n }}</td>
                        </tr>
                        <tr>
                          <th class="p-2">Уровень</th>
                          <td class="p-2">{{ val.uroven }}</td>
                        </tr>
                        <tr>
                          <th class="p-2">Дата и время проведения</th>
                          <td class="p-2">
                            {{ val.di }}
                            <span v-if="val.isklday"> - {{ val.isklday }}</span>
                            <span v-if="val.times_in"
                              >({{ val.times_in }} - {{ val.times_ou }})</span
                            >
                          </td>
                        </tr>
                        <tr>
                          <th class="p-2">Место проведения</th>
                          <td class="p-2" v-html="val.Mesto"></td>
                        </tr>
                        <tr>
                          <th class="p-2">Корпус</th>
                          <td class="p-2">{{ val.korpus }}</td>
                        </tr>
                        <tr>
                          <th class="p-2">Дополнительная информация</th>
                          <td class="p-2">
                            <span v-html="val.comment"></span
                            ><a
                              target="_blank"
                              v-if="val.linkfile"
                              class="btn btn-primary btn-sm"
                              :href="ftp + val.linkfile"
                              ><vue-feather type="save"></vue-feather
                            ></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <button
                      @click="add(val.kod)"
                      class="btn btn-primary btn-xs"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#dialogadd"
                    >
                      <vue-feather type="plus"></vue-feather>
                    </button>
                    <table
                      class="table table-hover table-responsive table-striped table-bordered table-condensed mt-3"
                    >
                      <thead>
                        <tr v-if="val.files.length > 0">
                          <th>№</th>
                          <th>Файл/Тема</th>
                          <th>Документ</th>
                          <th>Студенты</th>
                          <th>Операции</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(file, ks) in val.files" :key="ks">
                          <td class="p-2 text-center">{{ ks + 1 }}</td>
                          <td class="p-2 small">{{ file.naim }}</td>
                          <td class="p-2">{{ file.docum }}</td>
                          <td class="p-2 small">
                            <span v-for="(valv, ke) in file.stud" :key="ke"
                              >{{ valv.fio }}
                            </span>
                          </td>
                          <td class="p-2">
                            <div
                              class="btn-group"
                              v-if="file.prepedid == file.prep"
                            >
                              <button
                                @click="
                                  edit(file.naim, file.iid, file.id, n, ks)
                                "
                                class="btn btn-primary btn-xxs"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#dialogadd"
                              >
                                <vue-feather type="edit-2"></vue-feather>
                              </button>

                              <a
                                :href="ftp + file.files"
                                v-if="file.files && !mobile"
                                download="download"
                                target="_blank"
                                class="btn btn-primary btn-xxs"
                                ><vue-feather type="save"></vue-feather
                              ></a>
                              <button
                                @click="remove(file.id)"
                                class="btn btn-danger btn-xxs"
                                type="button"
                              >
                                <vue-feather type="trash"></vue-feather>
                              </button>
                            </div>
                            <div class="small" v-else>
                              Внёс: {{ file.preped }}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="dialogadd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialogadd"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ textdoc }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <form v-on:submit="addeditheme" id="formtheme">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="naimenthen">Наименование/Тема</label>
                    <input
                      type="text"
                      class="form-control"
                      name="naimenthen"
                      id="naimenthen"
                      v-model="naimenthen"
                    />
                  </div>
                  <div class="form-group">
                    <label for="documentfordostig">Вид документа</label>
                    <select
                      name="documentfordostig"
                      id="documentfordostig"
                      class="form-control form-select"
                      v-model="selvid"
                    >
                      <option
                        v-for="vs in viddocum"
                        :key="vs.ID"
                        :value="vs.ID"
                      >
                        {{ vs.Docum }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="fileformydostig">Документ (при наличии):</label>
                    <input
                      type="file"
                      name="fileformydostig"
                      id="fileformydostig"
                      ref="fileInput"
                      class="form-control"
                      @change="onFilePicked"
                      accept="image/*"
                    />
                  </div>
                </div>

                <div class="col-md-12 mt-2">
                  <div
                    class="accordion accordion-bordered accordion-primary-solid"
                    id="accordion_1"
                  >
                    <div class="accordion-item">
                      <h2 class="accordion-header">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#default-collapseOne"
                          aria-expanded="false"
                          aria-controls="default-collapseOne"
                        >
                          Участники
                        </button>
                      </h2>
                      <div
                        id="default-collapseOne"
                        class="accordion-collapse collapse"
                        data-bs-parent="#accordion_1"
                      >
                        <div class="accordion-body" id="accordion_3">
                          <div class="row">
                            <div class="col-md-8">
                              <div
                                class="accordion accordion-bordered accordion-primary-solid accordion-no-gutter"
                              >
                                <div
                                  class="accordion-item"
                                  v-for="(val, key) in allgroups"
                                  :key="key"
                                >
                                  <div class="accordion-header">
                                    <button
                                      class="accordion-button collapsed py-0 small"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      :data-bs-target="'#ed' + val.kod"
                                      aria-expanded="false"
                                      :aria-controls="'#ed' + val.kod"
                                    >
                                      {{ val.gr }}
                                    </button>
                                  </div>

                                  <div
                                    :id="'ed' + val.kod"
                                    class="collapse"
                                    data-parent="#accordion_3"
                                    role="tabpanel"
                                  >
                                    <div class="card-body pa-15 p-1">
                                      <div
                                        v-for="(val, key) in val.stud"
                                        :key="key"
                                      >
                                        <a
                                          href="#"
                                          @click.prevent="
                                            selstud(val.ids, val.fio)
                                          "
                                          >{{ val.fio }}</a
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-4 incluse">
                              <div v-for="(val, key) in selectstud" :key="key">
                                <a href="#" @click="deletekey(key)">{{
                                  val.fio
                                }}</a>
                                <input
                                  type="hidden"
                                  name="setstud[]"
                                  :value="val.ids"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <input
                type="hidden"
                name="fileeditid"
                id="fileeditid"
                :value="idfile"
              />
              <input type="hidden" name="oper" id="oper" :value="oper" />
              <button type="submit" class="btn btn-primary">
                Сохранить изменения
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      groups: "",
      idfile: "",
      oper: "",
      selvid: 0,
      naimenthen: "",
      discipselected: "",
      allgroups: "",
      allstud: "",
      selectstud: [],
      textdoc: "Добавить",
      listgroups: "",
      viddocum: "",
      themes: "",
      href: "",
      setdocum: "",
      file: "",
      ftp: server.ftp,
    };
  },
  mounted() {
    this.getdostig();
    this.getviddocum();
    this.getallgroups();
  },

  methods: {
    onFilePicked(event) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.file = files[0];
    },

    selstud(stud, fio) {
      this.selectstud.push({ ids: stud, fio: fio });
    },
    deletekey(key) {
      this.selectstud.splice(key, 1);
    },
    addeditheme(e) {
      e.preventDefault();
      server.dis(true, "formtheme");
      let students = [];
      let q = Array.prototype.slice.call(
        document.getElementsByName("setstud[]")
      );
      q.forEach((element) => {
        students.push(element.value);
      });

      var formData = new FormData();
      formData.append("file", this.file);
      formData.append("oper", this.oper);
      formData.append("idfile", this.idfile);
      formData.append("theme", this.naimenthen);
      formData.append("vid", this.selvid);
      formData.append("students", students);

      server
        .submittoserver(
          `${server.urlsecond.prepod.uploadtheme}`,
          formData,
          "POST",
          true
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.dis(false, "formtheme");
          server.toasts(res.mess, "success");
          server.closewindow("dialogadd");
          this.getdostig();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    add(id) {
      this.naimenthen = "";
      this.selvid = 0;
      this.idfile = id;
      this.oper = "0";
      this.textdoc = "Добавить";
      this.selectstud = [];
    },
    edit(naim, doc, id, docen, num) {
      this.naimenthen = naim;
      this.selvid = doc;
      this.idfile = id;
      this.oper = "1";
      this.textdoc = "Редактировать";

      if (this.groups[docen]) {
        this.selectstud = this.groups[docen].files[num].stud ?? [];
      } else {
        this.selectstud = [];
      }
    },
    getallgroups() {
      server
        .submittoserver(server.urlsecond.prepod.getallgroups, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allgroups = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    remove(id) {
      if (confirm("Вы уверены?")) {
        server
          .submittoserver(
            server.urlsecond.prepod.removefilesuser + id,
            {},
            "DELETE"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.getdostig();
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    getviddocum() {
      server
        .submittoserver(server.urlsecond.prepod.getviddocum, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.viddocum = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getdostig() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.getdostig + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.groups = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
