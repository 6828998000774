<template>
  <div id="questionnaire">
    <div class="hk-sec-wrapper">
      <div v-if="anketais">
        <h5 class="mb-15">Откуда вы узнали о техникуме?</h5>

        <div
          class="form-check custom-checkbox mb-3 checkbox-success"
          v-for="(e, i) in getquestionabit"
          :key="i"
        >
          <input
            class="form-check-input"
            type="checkbox"
            name="quest_id"
            :id="'inlineCheckbox' + i"
            :value="e.id"
          />
          <label class="custom-control-label" :for="'inlineCheckbox' + i">{{
            e.question
          }}</label>
        </div>
        <button
          class="btn btn-primary mt-15"
          type="button"
          @click="anketaupdate"
          :disabled="false"
        >
          Сохранить
        </button>
      </div>
      <div v-else>
        <h5>Спасибо за участие в опросе!</h5>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
export default {
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      anketais: false,
      getquestionabit: [],
    };
  },
  mounted() {
    this.anketa();
  },
  methods: {
    anketa() {
      server.messages({}, "questionnaire");
      server
        .submittoserver(server.urlsecond.abiturient.getquestionabitfull)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }

          if (res.length == 0) {
            this.anketais = true;
            server
              .submittoserver(server.urlsecond.abiturient.getquestionabit)
              .then((res) => {
                if (res.error && res.error == 403) {
                  this.logoutweb();
                }
                this.getquestionabit = res;
              })
              .catch(() => {
                server.toasts("Ошибка на сервере", "error");
              });
          }
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    anketaupdate() {
      server.dis(true, "questionnaire");
      let anketa = [];
      document
        .querySelectorAll("input[name=quest_id]:checked")
        .forEach((el) => {
          anketa.push(el.value);
        });
      if (anketa.length == 0) {
        server.toasts("Выберите хотя бы один ответ!", "error");
        server.dis(false, "questionnaire");
        return false;
      }

      server
        .submittoserver(
          server.urlsecond.abiturient.setquestionabit,
          { anketa: anketa },
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "questionnaire", [
            { mess: "mess", class: "success" },
            { mess: "error", class: "danger" },
          ]);
          if (res.mess) {
            this.anketais = false;
          }
        })
        .catch(() => {
          server.dis(false, "questionnaire");
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
