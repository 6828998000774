<template>
  <div id="raspisanie">
    <div class="row mb-2">
      <div class="col-md-6 col-lg-6 col-xl-3 col-sm-12 mb-2">
        <VueDatePicker
          id="vuedate"
          v-model="dates"
          locale="ru-RU"
          auto-apply
          inline
          @update:model-value="onDayClick"
          calendarCellClassName="form-control"
          inputClassName="form-control"
          autoPosition
          position="left"
          :dark="version == 'dark'"
          model-type="dd.MM.yyyy"
        ></VueDatePicker>
        <select class="form-select form-control mt-2" @change="getall($event.target.value)" v-if="dates.length > 0">
          <option value="1">Расписание групп на {{ dates }}</option>
          <option value="2">Расписание преподавателей на {{ dates }}</option>
          <option value="3">Сетка преподавателей на {{ dates }}</option>
        </select>
      </div>

     
      <div class="col-md-12 col-xl-9">
        <div v-for="(one, i) in view" :key="i">
          <div v-if="i.length > 0" class="my-2">
            <span class="badge badge-primary">{{ i }}</span
            ><br />
          </div>
          <a
            v-for="(onen, ss) in one"
            :key="ss"
            class="mx-1 fw-bold border-bottom border-danger border-4 cursor-pointer d-inline-block fs-5"
            @click="scrollTo(onen.id, isclicked)"
            :class="isclicked==onen.id?'bg-primary bg-gradient rounded text-white':'text-primary'"
            >{{ onen.nazv }}</a
          >
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table
        class="table table-hover table-striped table-bordered table-condensed"
        v-if="isActive == 1 && dates && raspisan"
      >
        <thead>
          <tr>
            <th>Группа</th>
            <th>Пара</th>
            <th>Дисциплина</th>
            <th>Кабинет</th>
            <th>Преподаватель</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(one, i) in raspisan">
            <tr v-for="(onen, z) in one" :id="'s' + i" :key="z + '1s' + i">
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                :rowspan="one.length"
                v-if="z == 0"
                class="text-center align-middle p-1"
              >
                <h2 :class="{ 'bg-primary text-white': isActive2 == i }">
                  {{ onen.Groups }}
                </h2>
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.Para }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="p-1"
              >
                {{ onen.discip }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.cab }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="p-1"
              >
                {{ onen.prep }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="table-responsive">
      <table
        class="table table-striped table-bordered table-condensed"
        v-if="isActive == 2 && dates && raspisan"
      >
        <thead>
          <tr>
            <th>Преподаватель</th>
            <th>Пара</th>
            <th>Группа</th>
            <th>Дисциплина</th>
            <th>Кабинет</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(one, i, zz) in raspisan">
            <tr v-for="(onen, z) in one" :id="'s' + i" :key="z + '3s' + zz">
              <td
                :rowspan="one.length"
                v-if="z == 0"
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center align-middle p-1"
              >
                {{ onen.prep }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.para }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.groups }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="p-1"
              >
                {{ onen.discip }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.cab }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="table-responsive">
      <table
        class="table table-hover table-striped table-bordered table-condensed"
        v-if="isActive == 3 && dates && raspisan"
      >
        <thead>
          <tr>
            <th>№</th>
            <th>Преподаватель</th>
            <th colspan="2">1 пара</th>
            <th colspan="2">2 пара</th>
            <th colspan="2">3 пара</th>
            <th colspan="2">4 пара</th>
            <th colspan="2">5 пара</th>
            <th colspan="2">6 пара</th>
            <th colspan="2">7 пара</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(one, i, zz) in raspisan">
            <tr v-for="(onen, z) in one" :id="'s' + i" :key="z + '217s' + i">
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                :key="z"
                class="p-1"
              >
                {{ ++zz }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="p-1"
              >
                {{ onen.prep }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p1 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c1 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p2 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c2 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p3 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c3 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p4 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c4 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p5 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c5 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p6 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c6 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.p7 }}
              </td>
              <td
                :class="{ 'bg-primary text-white': isActive2 == i }"
                class="text-center p-1"
              >
                {{ onen.c7 }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    
  </div>
</template>

<script>
import server from "@/assets/js/server";
export default {
  props: ["version", "loadchange", "logoutweb"],
  name: "app",
  data() {
    return {
      error: "",
      dates: "",
      date: [],
      view: {},
      isActive: 1,
      isActive2: 1,
      isclicked: null,
      raspisan: {},
      urlsecond: {
        getView: "getView",
        getAllDates: "getAllDates",
        getAllRaspisan: "getAllRaspisan",
      },
    };
  },


  methods: {
    getall(a = this.isActive) {
      this.isclicked = null;
      this.isActive = a;
      this.getAllnewnew(a);
      this.getAllgetView(a);

      document.querySelectorAll(`#raspisanie table tr td`).forEach(el=>{ el.classList.remove("d-none") });

    },
    getAllnewnew() {
      this.isclicked = null;
      server
        .submittoserver(
          `${server.urlsecond.getAllRaspisan + this.dates}/7${this.isActive}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.raspisan = res;
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },
    getAllgetView() {
      this.isclicked = null;
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.getView + this.dates}/7${this.isActive}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.view = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка сервера", "error");
        });
    },

    onDayClick() {
      this.getall();
    },

 

    scrollTo(id, iskl) {
      if(id==iskl){
        this.isclicked = null;
        document.querySelectorAll(`#raspisanie table tr td`).forEach(el=>{ el.classList.remove("d-none") });
      }
      else{
      this.isclicked = id;
      document.querySelectorAll(`#raspisanie table tr td`).forEach(el=>{ el.classList.add("d-none") });
      document.querySelectorAll(`#raspisanie table #s${id} td`).forEach(el=>{ el.classList.remove("d-none") });
      }
      // let y = document.getElementById("s" + id);
      // this.isActive2 = id;
      // let a =
      //   y.getBoundingClientRect().top + pageYOffset - window.innerHeight / 2;
      // window.scrollTo({
      //   top: a,
      //   behavior: "smooth",
      // });
    },
  },
};
</script>
