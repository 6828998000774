<template>
  <div id="inddostig">
    <div class="hk-sec-wrapper">
      <p>
        Необходимые документы отсканируйте или сфотографируйте с высоким
        разрешением и качеством
      </p>
    </div>
    <section class="hk-sec-wrapper row align-items-end text-center">
      <div class="w-100">
        <div class="card">
          <h5 class="card-header">Индивидуальные достижения</h5>
          <div class="card-body">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              @vdropzone-removed-file="removefile"
              :options="{ ...dropzoneob, ...dropzoneOptions }"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import vueDropzone from "dropzone-vue3";
export default {
  components: {
    vueDropzone,
  },
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      dropzoneob: {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        acceptedFiles: ".jpg,.png",
        addRemoveLinks: true,
        dictDefaultMessage: "Добавьте необходимые документы",
        dictInvalidFileType: "Тип файла не подходит",
        dictRemoveFile: "Удалить",
        dictCancelUpload: "Отменить",
        paramName: "photo",
        humbnailWidth: 200,
      },
      dropzoneOptions: {
        url: server.server + server.urlsecond.abiturient.setphoto + "15",
      },
    }
  },
  mounted() {
    this.doc();
  },
  methods: {
    doc() {
      this.getdocs(15, "#dropzone");
    },
    removefilecustom(id) {
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    removefile(th) {
      let id = JSON.parse(th.xhr.response).id;
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    getdocs(docs, selector) {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.abiturient.getphoto + docs)
        .then((res) => {
          let parent = document.querySelector(selector);
          res.forEach((el) => {
            parent.classList.add("dz-started");
            let div = document.createElement("div");
            div.classList.add(
              "dz-preview",
              "dz-processing",
              "dz-image-preview",
              "dz-success",
              "dz-complete"
            );
            let divimage = document.createElement("div");
            divimage.classList.add("dz-image");

            let image = document.createElement("img");
            image.src = server.ftp + el.path;
            image.classList.add("img-fluid");
            divimage.appendChild(image);

            let dzdetails = document.createElement("div");
            dzdetails.classList.add("dz-details");
            div.appendChild(divimage);
            div.appendChild(dzdetails);
            let a = document.createElement("a");
            a.classList.add("dz-remove");
            a.href = "javascript:undefined;";
            a.innerHTML = "Удалить";
            div.appendChild(a);
            a.addEventListener("click", () => {
              this.removefilecustom(el.id);
              div.remove();
            });
            parent.appendChild(div);
          });
          this.loadchange(true);
        });
    },
  },
};
</script>
