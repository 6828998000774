import * as Vue from "vue";
import App from "./App.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/style.css";

const bootstrap = require("bootstrap");
window.bootstrap = bootstrap;

import "@/assets/js/custom";
import "@/assets/js/custom.min";

import VueFeather from "vue-feather";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const app = Vue.createApp(App);


app.component(VueFeather.name, VueFeather);
app.component("VueDatePicker", VueDatePicker);
app.component("multiselect", Multiselect);

app.mount("#app");
