<template>
  <div id="home">
    <div class="row">
      <section class="hk-sec-wrapper mx-10" v-if="getpos.length > 0">
        <h5 class="hk-sec-title">Ход приемной компании</h5>
        <div class="row">
          <table class="table table-bordered wb">
            <thead>
              <tr>
                <th rowspan="2">Программа подготовки</th>
                <th rowspan="2">
                  Средний балл вашего документа об образовании
                </th>
                <th rowspan="2">Количество бюджетных мест</th>
                <th rowspan="2">
                  Ориентировочная позиция в рейтинге абитуриентов
                </th>
                <th colspan="3">Программа подготовки</th>
              </tr>
              <tr>
                <th>Минимальный балл</th>
                <th>Максимальный балл</th>
                <th>Средний балл</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(p, key) in getpos" :key="key">
                <th>{{ p.nazv }}</th>
                <th>{{ p.sr }}</th>
                <th>{{ p.vs }}</th>
                <th>{{ p.pos }}</th>
                <th>{{ p.mn }}</th>
                <th>{{ p.mx }}</th>
                <th>{{ p.av }}</th>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-sm py-1 px-3 bg-gradient-primary">
              <div class="row m-0">
                <h5 v-if="st">
                  Статус:&nbsp;<strong>{{ st.status }}</strong>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-md-12" v-for="(p, key) in proc" :key="key">
            <div class="widget-stat card bg-info">
              <div class="card-body p-4">
                <div class="media">
                  <span class="me-3">
                    <vue-feather type="heart"></vue-feather>
                  </span>
                  <div class="media-body text-white text-end">
                    <p class="mb-1">{{ p.field }}</p>
                    <h3 class="text-white">{{ p.procent }}%</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <section class="hk-sec-wrapper">
          <div class="row">
            <div class="col-xl-12 col-xxl-12 col-lg-12">
              <div class="card border-0 pb-0">
                <div class="card-header border-0 pb-0">
                  <h4 class="card-title">Доступные документы для скачивания</h4>
                </div>
                <div class="card-body px-0">
                  <div class="widget-media dlab-scroll height370 px-4">
                    <ul class="timeline" v-if="report">
                      <li v-for="val in report" :key="val">
                        <div class="timeline-panel">
                          <div class="media-body">
                            <button
                            @click="pdfdown(val.url)"
                              :class="blocked ? 'nolink' : ''"
                              class="btn btn-primary w-100"
                              ><vue-feather type="download"></vue-feather> {{ val.name }}</button
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div v-else class="alert alert-danger solid alert-square">
                      Для получения документов заполните основные данные и
                      данные паспорта.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="hk-sec-wrapper">
          <h5 class="hk-sec-title">День открытых дверей ONLINE</h5>
          <div class="row">
            <div class="col-sm">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  class="embed-responsive"
                  src="https://www.youtube.com/embed/4tuHF_c8Sls"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section class="hk-sec-wrapper">
          <h5 class="mb-20">Примеры заполненных заявлений</h5>
          <div class="row m-0 ourgallery">
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/1.jpg"
                d
              >
                <img src="@/assets/images/11.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/2.jpg"
              >
                <img src="@/assets/images/22.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/3.jpg"
              >
                <img src="@/assets/images/33.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/4.jpg"
              >
                <img src="@/assets/images/44.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/5.jpg"
              >
                <img src="@/assets/images/55.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/6.jpg"
              >
                <img src="@/assets/images/66.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/7.jpg"
              >
                <img src="@/assets/images/77.webp" alt="" />
              </a>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4 col-6 mb-10">
              <a
                class="fancybox img-thumbnail d-block"
                target="_blank"
                href="https://chgpgt.ru/assets/apifiles/8.jpg"
              >
                <img src="@/assets/images/88.webp" alt="" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import server from "@/assets/js/server";
export default {
  props: ["blocked", "loadchange", "logoutweb", "token"],
  data() {
    return {
      proc: [],
      getpos: [],
      report: [],
      st: null,
      server: server.server,
    };
  },
  mounted() {
    this.gl();
  },
  methods: {
    pdfdown(url){
      server
        .submittoserverblob(url, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    gl() {
      this.loadchange(false);
      server.messages({}, "home");

      server
        .submittoserver(server.urlsecond.abiturient.getposition)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getpos = res.position;
          this.report = res.report;
          this.proc = res.proc;
          this.st = res.status[0] ? res.status[0] : "на рассмотрении";
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
      server.dis(this.blocked, "home");
    },
  },
};
</script>
