<template>
    <div id="ResetForm">
      <h4 class="text-center mb-4">Сбросить пароль)</h4>
      <div class="form-group mb-4">
        <label class="form-label" for="email">Email</label>
        <input type="email" class="form-control" placeholder="hello@example.com" id="email" name="email" v-model="emailn" />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group mb-4">
        <label class="form-label" for="code">Код подтверждения (с почты)</label>
        <input type="text" class="form-control" placeholder="" id="code" name="code" v-model="code" />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group mb-4">
        <label class="form-label" for="password">Новый пароль</label>
        <input type="password" class="form-control" placeholder="" id="password" name="password" v-model="password" />
        <div class="invalid-feedback"></div>
      </div>
      <div class="form-group mb-4">
        <label class="form-label" for="password_confirmation">Подтверждение нового пароля</label>
        <input type="password" class="form-control" placeholder="" id="password_confirmation" name="password_confirmation" v-model="password_confirmation" />
        <div class="invalid-feedback"></div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-block" @click.prevent="reset">Сбросить</button>
      </div>
    </div>
    <div class="form-row d-flex flex-wrap justify-content-between mb-2 mt-3">
      <div class="form-group mb-sm-4 mb-1">
        <p>
        <a class="text-primary" href="#" @click.prevent="changePage('AuthForm')">Вернуться на страницу входа</a>
      </p>
      </div>
      <div class="form-group ms-2"><a class="text-hover" href="#">Уже есть код восстановления доступа?</a></div>
    </div>
  
  </template>
  <script>
  import server from "../assets/js/server";
  
  export default {
    props: ["changePage", "email", "addToken"],
    data() {
      return {
        emailn: null,
        code: null,
        password: null,
        password_confirmation: null,
      }
    },
    mounted() {
        this.emailn=this.email;
    },
    methods: {
      reset() {
        server.dis(true, "ResetForm");
  
        let ob = {
          email: this.emailn,
          code: this.code,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };
        server
          .submittoserver(server.urlsecond.passwordreset, ob)
          .then((res) => {
            console.log(res);
            if(res.errors){
            server.messages(res.errors, "ResetForm", [
              { mess: "error", class: "warning" },
              { mess: "success", class: "success" },
            ]);
            }
            else{
                if (res.token !== undefined) {
            
            localStorage.setItem("blocked", res.blocked || 0);
            localStorage.setItem("token", res.token);
            this.addToken(res.token);
            
          }
            }
            
            // if(res.errors){
            //   // server.toasts(res.message, "success");
            // }
            server.dis(false, "ResetForm");
          }
  
          )
          .catch(() => {
            server.toasts("Ошибка сервера", "error");
            server.dis(false, "ResetForm");
          });
      }
    },
  };
  </script>