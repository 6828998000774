// import jQuery from "jquery";
// window.jQuery = jQuery;
// let $ = jQuery;
//$("#menu").unbind().metisMenu(),
import { MetisMenu } from 'metismenujs';
import 'metismenujs/style';
var chgpgt = (function () {
    "use strict";
    var a = function () {
           document.querySelectorAll("#menu").length > 0 && new MetisMenu("#menu"),
            document.querySelectorAll(".metismenu .mm-active ").forEach(el => {
              let parent = el.parentElement;
              if (parent.classList.contains("mm-collapse")){
                parent.parentElement.classList.add("mm-active");
                parent.parentElement.querySelector(".has-arrow").setAttribute('aria-expanded', 'true');
                parent.classList.add('mm-show');
              }
                !el.querySelectorAll("ul").length > 0 && el.classList.add("active-no-child");
            })
        },
        i = function () {
            document.querySelector(".hamburger").addEventListener("click", () => {
                document.querySelector("#main-wrapper").classList.toggle("menu-toggle"),
                document.querySelector(".hamburger").classList.toggle("is-active");
            })
        },
        s = function () {
            var e = window.outerHeight,
                e = window.outerHeight;
               let w = window.innerHeight - 55;
            (e > 0 ? e : screen.height); 
            let el = document.querySelector(".content-body")
                if(el)document.querySelector(".content-body").style.cssText =
                `min-height:${w}px`
            ;
        },
        d = function () {
            window.addEventListener('scroll', function () {
                "horizontal" === document.body.dataset.layout && "static" === document.body.dataset.headerPosition && "fixed" === document.body.dataset.sidebarPosition && (window.pageYOffset >= document.querySelector(".header").clientHeight ? document.querySelector(".dlabnav").classList.add("fixed") : document.querySelector(".dlabnav").classList.remove("fixed"));
            });
        }
    return {
        menu: function () {
            a();
        },
        init: function () {
            i(),
            s(),
            d();
        },
        resize: function () {
            s();
        }

    };
})();

window.addEventListener('resize', () => {
    chgpgt.resize();
}, true);

export default chgpgt;
