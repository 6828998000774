<template>
  <div class="dropdown-menu dropdown-menu-end">
    <a href="#" @click="profile" class="dropdown-item ai-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="text-success"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
        <circle cx="12" cy="7" r="4"></circle>
      </svg>
      <span class="ms-2">Профиль </span>
    </a>
  
    <a href="#" @click="logout" class="dropdown-item ai-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="text-danger"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
        <polyline points="16 17 21 12 16 7"></polyline>
        <line x1="21" y1="12" x2="9" y2="12"></line>
      </svg>
      <span class="ms-2">Выход </span>
    </a>
  </div>
</template>
<script>
export default {
  props: ["logout", "profile"]
};
</script>