<template>
  <div id="gto">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div class="row py-1">
          <div class="col-md-12">
            <button
              @click="add"
              class="btn btn-primary btn-xxs mb-2"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#dialogadd"
            >
              <vue-feather type="plus"></vue-feather>
            </button>
            <table class="table table-bordered small" v-if="gto.length > 0">
              <tbody>
                <tr>
                  <th>№ п/п</th>
                  <th>Студент</th>
                  <th>Группа</th>
                  <th>Ступень</th>
                  <th>Значок ГТО</th>
                  <th>Номер удостоверения</th>
                  <th>Приказ</th>
                  <th>Операции</th>
                </tr>
                <tr v-for="(val, key) in gto" :key="key">
                  <td>{{ key + 1 }}</td>
                  <td>{{ val.stud }}</td>
                  <td>{{ val.Groups }}</td>
                  <td>{{ val.stupen }}</td>
                  <td>{{ val.znach }}</td>
                  <td>{{ val.nomerud }}</td>
                  <td>{{ val.nomerprik + " от " + val.do }}</td>
                  <td>
                    <button
                      class="btn btn-danger btn-xxs"
                      type="button"
                      @click="remove(val.KOD)"
                    >
                      <vue-feather type="trash"></vue-feather>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="dialogadd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialogadd"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ textdoc }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <form v-on:submit="addeditheme" id="formtheme">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-6">
                  <label for="prikaz">Номер приказа</label>
                  <input
                    type="text"
                    class="form-control"
                    name="prikaz"
                    id="prikaz"
                    v-model="prikaz"
                  />
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="dates" class="m-0">Дата приказа:</label><br />

                    <VueDatePicker
                      v-model="dates"
                      name="dates"
                      id="dates"
                      locale="ru-RU"
                      format="dd.MM.yyyy"
                      auto-apply
                      calendarCellClassName="form-control"
                      inputClassName="form-control"
                      autoPosition
                      position="left"
                      :dark="version == 'dark'"
                      placeholder="Дата приказа"
                      model-type="dd.MM.yyyy"
                    ></VueDatePicker>
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="nomerud">Номер удостоверения</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nomerud"
                    id="nomerud"
                    v-model="nomerud"
                  />
                </div>
                <div class="col-md-6">
                  <label for="stupen">Ступень</label>
                  <input
                    type="number"
                    class="form-control"
                    name="stupen"
                    id="stupen"
                    min="1"
                    max="10"
                    v-model="stupen"
                  />
                </div>

                <div class="col-md-6">
                  <label for="znach">Вид значка:</label>
                  <select
                    name="znach"
                    id="znach"
                    class="form-control form-select"
                    v-model="znachmodel"
                  >
                    <option
                      v-for="val in allznach"
                      :key="val.id"
                      :value="val.id"
                    >
                      {{ val.znach }}
                    </option>
                  </select>
                </div>

                <div class="col-md-12">
                  <div class="accordion" id="accordion_1">
                    <div class="row">
                      <div class="col-md-8">
                        <label>Укажите студента</label>
                        <div
                          class="accordion accordion-bordered accordion-no-gutter accordion-danger-solid"
                          id="accordion_3"
                        >
                          <div
                            class="accordion-item"
                            v-for="(val, key) in allgroups"
                            :key="key"
                          >
                            <h2 class="accordion-header">
                              <button
                                role="button"
                                type="button"
                                data-bs-toggle="collapse"
                                class="accordion-button collapsed py-0 small"
                                :data-bs-target="'#ed' + val.kod"
                                aria-expanded="true"
                              >
                                {{ val.gr }}
                              </button>
                            </h2>
                            <div
                              :id="'ed' + val.kod"
                              class="collapse"
                              data-parent="#accordion_3"
                              role="tabpanel"
                            >
                              <div class="card-body pa-15 p-1">
                                <div v-for="(val, key) in val.stud" :key="key">
                                  <a
                                    href="#"
                                    @click="selstud(val.ids, val.fio)"
                                    >{{ val.fio }}</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 incluse">
                        <div v-for="(val, key) in selectstud" :key="key">
                          <a href="#" @click="deletekey(key)">{{ val.fio }}</a>
                          <input
                            type="hidden"
                            name="setstud[]"
                            :value="val.ids"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Отмена
              </button>
              <button type="submit" class="btn btn-primary">
                Сохранить изменения
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
export default {
  props: [
    "periods",
    "d1",
    "d2",
    "changeperiod",
    "loadchange",
    "logoutweb",
    "version",
  ],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      gto: [],
      stupen: null,
      prikaz: null,
      znachmodel: null,
      allgroups: "",
      allstud: "",
      nomerud: "",
      dates: "",
      selectstud: [],
      textdoc: "Добавить",
      allznach: [],
      viddocum: "",
      themes: "",
      href: "",
    };
  },
  mounted() {
    this.d2;
    this.getgto();
    this.getallznach();
    this.getallgroups();
  },
  filters: {
    date: (d) => d.toLocaleString("ru-RU").slice(0, -10),
  },
  methods: {
    selstud(stud, fio) {
      this.selectstud = [{ ids: stud, fio: fio }];
    },
    deletekey(key) {
      this.selectstud.splice(key, 1);
    },
    addeditheme(e) {
      e.preventDefault();
      if (this.selectstud.length == 0) {
        server.toasts("Укажите хотя бы 1 обучающегося", "danger");
        server.dis(false, "formtheme");
        return false;
      }

      if (!this.prikaz) {
        server.toasts("Укажите номер приказа", "danger");
        server.dis(false, "formtheme");
        return false;
      }
      if (!this.stupen) {
        server.toasts("Укажите ступень", "danger");
        server.dis(false, "formtheme");
        return false;
      }
      if (!this.znachmodel) {
        server.toasts("Укажите вид значка", "danger");
        server.dis(false, "formtheme");
        return false;
      }
      if (!this.nomerud) {
        server.toasts("Укажите номер удостоверения", "danger");
        server.dis(false, "formtheme");
        return false;
      }

      server
        .submittoserver(
          server.urlsecond.prepod.studentnewgto,
          {
            stupen: this.stupen,
            nomerud: this.nomerud,
            nomerprik: this.prikaz,
            znach: this.znachmodel,
            student: this.selectstud[0].ids,
            dates: this.dates,
          },
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.dis(false, "formtheme");
          server.toasts(res.mess, "success");
          server.closewindow("dialogadd");
          this.getgto();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    add() {
      this.prikaz = "";
      this.textdoc = "Добавить";
      this.selectstud = [];
    },

    getallgroups() {
      server
        .submittoserver(server.urlsecond.prepod.getallgroups, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allgroups = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    remove(id) {
      if (confirm("Вы уверены?")) {
        server
          .submittoserver(
            server.urlsecond.prepod.removegtouser + id,
            {},
            "DELETE"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            this.getgto();
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },
    getgto() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.prepod.getgto, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.gto = res;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getallznach() {
      server
        .submittoserver(server.urlsecond.prepod.getallznach, {}, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.allznach = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
