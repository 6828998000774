<template>
  <div id="practicemanagement">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <button
              class="btn btn-primary"
              @click="toword"
              v-if="discipselected.discip && !mobile"
            >
              <vue-feather type="save"></vue-feather>
            </button>

            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-body">
          <select
            class="form-control form-select"
            @change="getgroupsprakt"
            v-model="discipselected"
            v-if="groups.length > 0"
          >
            <option
              v-for="(item, keys) in groups"
              :key="keys"
              :value="{
                discip: item.Discip,
                groups: item.Groups,
                cikls: item.cikls,
                semestr: item.Semestr,
              }"
            >
              {{ item.p }}
            </option>
          </select>
          <h4 v-else>Ничего не найдено</h4>
        </div>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <div id="resource">
        <div
          class="row py-1 border-bottom"
          v-for="(val, isc) in listgroups"
          :key="isc"
        >
          <div class="col-md-10">
            {{ val.stud }} Место практики:
            <input
              type="text"
              :id="'mesto' + val.ids"
              class="form-control form-control-sm"
              v-model="listgroups[isc].mesto"
            />
          </div>
          <div class="col-md-2 d-flex align-items-end">
            <div class="input-group">
              <select
                class="form-control ocenka form-select form-control-sm"
                v-model="listgroups[isc].Ocenka"
                :id="'ocenka' + val.ids"
              >
                <option
                  v-for="vs in ocenka"
                  :selected="vs.Kod == val.Ocenka"
                  :key="vs.Kod"
                >
                  {{ vs.Sokr }}
                </option>
              </select>

              <button
                class="btn btn-primary btn-xxs"
                type="submit"
                @click="saveprakt(isc)"
              >
                <vue-feather type="save"></vue-feather>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
const Typograf = require("typograf");
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      groups: "",
      discipselected: "",
      listgroups: "",
      themes: "",
    };
  },
  mounted() {
    this.getgroups();
  },

  methods: {
    toword() {
      // this.href = `${
      //   server.server +
      //   server.urlsecond.prepod.getreportprakt +
      //   localStorage.getItem("token")
      // }/${this.discipselected.groups}/${this.discipselected.semestr}/${
      //   this.discipselected.discip
      // }/${this.discipselected.cikls}`;

      server
        .submittoserverblob(`${server.urlsecond.prepod.getreportprakt + this.discipselected.groups}/${this.discipselected.semestr}/${
        this.discipselected.discip
      }/${this.discipselected.cikls}`, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.docx');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getgroups() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getlistpractic + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    getgroupsprakt() {
      this.ocenka = "";
      server
        .submittoserver(
          `${
            server.urlsecond.prepod.getlistgroupsprakt +
            this.discipselected.groups
          }/${this.discipselected.semestr}/${this.discipselected.discip}/${
            this.discipselected.cikls
          }`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.listgroups = res;
          this.getocenka();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getocenka() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.prepod.getocenkakurs, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },

    saveprakt(ids) {
      const tp = new Typograf({ locale: ["ru", "en-US"] });
      this.listgroups[ids].mesto = this.listgroups[ids].mesto
        ? tp.execute(this.listgroups[ids].mesto)
        : "";
      let send = {
        ids: this.listgroups[ids].ids,
        mesto: this.listgroups[ids].mesto,
        Ocenka: this.listgroups[ids].Ocenka,
        discip: this.discipselected.discip,
        semestr: this.discipselected.semestr,
        cikls: this.discipselected.cikls,
      };
      server
        .submittoserver(`${server.urlsecond.prepod.savepraktstud}`, send, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, res.class);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
