<template>
  <div id="academicperformance">
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <div class="input-group">
              <seldate
                :periods="periods"
                :changeperiod="changeperiod"
                :dates="d1 + ' - ' + d2"
              />
              <button
                class="btn btn-primary"
                @click="toword"
                v-if="discipselected.final && !mobile"
     
                ><vue-feather type="save"></vue-feather
              ></button>
              <button @click="setpos" v-if="ocenka.tab" class="btn btn-primary">
                <vue-feather
                  :type="pos == 1 ? 'user-check' : 'users'"
                ></vue-feather>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <select
              class="form-control form-select"
              @change="getuspev"
              v-model="discipselected"
              v-if="groups"
            >
              <option
                v-for="(item, keys) in groups"
                :key="keys"
                :value="{
                  discip: item.discip,
                  groups: item.value,
                  vsa: item.vsa,
                  prakt: item.prakt,
                  final: item.final,
                  snat: item.snat,
                }"
              >
                {{ item.naim }}
              </option>
            </select>

            <div
              class="alert alert-alt p-0 p-1 alert-success mt-1 mb-0"
              v-if="discipselected"
            >
              <span
                >Всего вычитано часов:
                <strong>{{ parseFloat(discipselected.vsa) }}</strong
                >. По учебному плану:
                <strong>{{ parseFloat(discipselected.prakt) }}</strong
                >.
                <span v-if="discipselected.snat"
                  >Снято: <strong>{{ discipselected.snat }}</strong
                  >.</span
                ></span
              ><span v-if="discipselected.final"
                >Форма контроля: <strong>{{ discipselected.final }}</strong
                >.</span
              >
              <div
                class="progress"
                v-if="discipselected.vsa > 0 && discipselected.prakt > 0"
              >
                <div
                  class="progress-bar progress-bar-primary progress-bar-striped progress-animated"
                  :style="{
                    width:
                      parseFloat(
                        ((parseInt(discipselected.vsa) +
                          parseInt(discipselected.snat || 0)) *
                          100) /
                          discipselected.prakt
                      ).toFixed(2) + '%',
                    height: '12px',
                  }"
                  style=""
                  role="progressbar"
                >
                  <span class="sr-only"
                    >{{
                      parseFloat(
                        ((parseInt(discipselected.vsa) +
                          parseInt(discipselected.snat || 0)) *
                          100) /
                          discipselected.prakt
                      ).toFixed(2)
                    }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1 position-relative d-block">
        <div id="resource d-block">
          <div class="mt10 table-responsive d-block">
            <table
              border="1"
              class="ocenfig table tableexport table-hover table-responsive table-striped table-bordered table-condensed wb"
              v-html="ocenka.tab"
              ref="table"
            ></table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="ktpModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="ktpModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ktpModalLabel">КТП</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered table-responsive-sm">
                <thead>
                  <tr>
                    <td>№</td>
                    <td>Дата (пара)</td>
                    <td>Часы</td>
                    <td>Тема</td>
                    <td>Вид</td>
                    <td>ДЗ</td>
                    <td>СР</td>
                    <td>Комментарий</td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(val, key) in ktp.res"
                    :key="key"
                    :class="!val.Tema ? 'table-primary' : ''"
                  >
                    <td class="p-0 text-center small">{{ key + 1 }}</td>
                    <td class="p-0 small">{{ val.d }}&nbsp;({{ val.para }})</td>
                    <td class="p-0 text-center">{{ val.chas }}</td>
                    <td class="p-0 small">{{ val.Tema }}</td>
                    <td class="p-0 small">{{ val.vid }}</td>
                    <td class="p-0 small">{{ val.dz }}</td>
                    <td class="p-0 small">{{ val.Samost }}</td>
                    <td class="p-0">{{ val.Comm }}</td>
                    <td class="p-0 text-center">
                      <button
                        class="btn btn-primary btn-xxs p-1"
                        title="Получить"
                        @click="getfirstktp(val.d, val.para)"
                        v-if="val.my && val.os != 's' && !val.prep"
                      >
                        <vue-feather type="upload"></vue-feather></button
                      >{{ val.os == "s" ? "Снятие" : "" }}
                      {{ val.prep ? "Др.Преподаватель: " + val.prep : "" }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2" class="font-weight-bold">Всего</td>
                    <td class="text-center font-weight-bold">{{ ktp.vs }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";

export default {
  props: [
    "periods",
    "d1",
    "d2",
    "changeperiod",
    "loadchange",
    "logoutweb",
    "logoutweb",
    "mobile"
  ],
  components: { seldate },
  data() {
    return {
      discipselected: "",
      getcurrentdate: this.d1,
      groups: "",
      ocenka: "",
      href: "",
      ktp: { res: null, vs: null },
      pos: 1,
    };
  },

  mounted() {
    this.getgroups(this.pos);
  },
  updated: function () {
    let ref = this;
    this.$nextTick(function () {
      if (typeof ref.$refs.table != "undefined") {
        if (document.querySelectorAll(".getuspev").length) {
          document.querySelectorAll(".getuspev").forEach((el) => {
            el.addEventListener("change", (ele) => {
              this.selectchange(ele.target.dataset.prop, ele.target.value);
              let tr = ele.target.parentElement.parentElement;
              let sel = tr.querySelectorAll(".getuspev");
              let sr = tr.querySelector(".rpb");
              let pas = [];
              if (this.pos) {
                sel.forEach((el) => {
                  if (el.options[el.selectedIndex].text) {
                    pas.push(parseInt(el.options[el.selectedIndex].text));
                  }
                });
                const sum = pas.reduce((a, b) => a + b, 0);
                const avg = sum / pas.length || 0;
                sr.innerHTML = avg.toFixed(2);
              }
            });
          });
        }
        if (document.querySelectorAll(".setitog").length) {
          document.querySelectorAll(".setitog").forEach((el) => {
            el.addEventListener("change", (ele) => {
              this.selectchangeitog(ele.target.dataset.prop, ele.target.value);
            });
          });
        }

        if (document.querySelectorAll(".saveTheme").length) {
          document.querySelectorAll(".saveTheme").forEach((el) => {
            el.addEventListener("click", () => {
              this.setheme(
                el.dataset.dates,
                el.dataset.para,
                el.parentElement.querySelector("input").value
              );
            });
          });
        }
        if (document.querySelectorAll(".ocall").length) {
          document.querySelectorAll(".ocall").forEach((el) => {
            el.addEventListener("click", () => {
              this.setoc(
                el.dataset.oc,
                el.dataset.dates,
                el.dataset.para,
                el.dataset.pos
              );
            });
          });
        }

        if (document.querySelectorAll(".editktp").length) {
          document.querySelectorAll(".editktp").forEach((el) => {
            el.addEventListener("click", () => {
              this.getktp();
            });
          });
        }

        var tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    });
  },

  methods: {
    setpos() {
      this.pos = this.pos == 1 ? 0 : 1;
      this.getuspev();
    },

    getgroups(pos) {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroups + this.d1}/${this.d2}/${pos}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    setoc(oc, dates, para, pos) {
      if (confirm("Уверены?")) {
        server
          .submittoserver(
            `${server.urlsecond.prepod.setocenkaall}${this.discipselected.groups}/${this.discipselected.discip}/${oc}/${dates}/${para}/${pos}`,
            {},
            "POST"
          )
          .then((res) => {
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            if (res.error && res.error == 403) {
              this.logoutweb();
            }
            server.toasts(res.message, "success");
            this.getuspev();
            return;
          })
          .catch(() => {
            server.toasts("Ошибка на сервере", "error");
          });
      }
    },

    getktp() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.hp_get_all_ktp_groups}${this.discipselected.groups}/${this.discipselected.discip}/${this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ktp.res = res.res;
          this.ktp.vs = res.vs[0].vs;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getfirstktp(dates, para) {
      let post = {
        dates: dates,
        para: para,
        discip: this.discipselected.discip,
        groups: this.discipselected.groups,
        d1: this.d1,
        d2: this.d2,
      };
      server
        .submittoserver(`${server.urlsecond.prepod.getfirstktp}`, post, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error !== undefined) {
            server.toasts(res.error, "danger");
            return;
          }
          if (res.mess !== undefined) {
            server.toasts(res.mess, "success");
            this.getktp();
            return;
          }
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    setheme(dates, para, val) {
      server
        .submittoserver(
          `${server.urlsecond.prepod.setheme + dates}/${para}/${
            this.discipselected.groups
          }/${this.discipselected.discip}/${val ? val : "0"}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    selectchange(stud, pos) {
      server
        .submittoserver(
          `${server.urlsecond.prepod.setocenka + this.pos}/${stud
            .split("-")
            .join("/")}/${pos}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
    selectchangeitog(stud, pos) {
      server
        .submittoserver(
          `${
            server.urlsecond.prepod.setitogocenka + stud.split("-").join("/")
          }/${pos}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.toasts(res.message, "success");
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    toword() {
      // this.href = `${
      //   server.server +
      //   server.urlsecond.prepod.getword +
      //   localStorage.getItem("token")
      // }/${this.discipselected.groups}/${this.discipselected.discip}`;
      server
        .submittoserverblob(`${server.urlsecond.prepod.getword + this.discipselected.groups}/${this.discipselected.discip}`, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          let fileURL = window.URL.createObjectURL(new Blob([res]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.docx');
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    getuspev() {
      this.loadchange(false);
      this.ocenka = "";
      server
        .submittoserver(
          `${server.urlsecond.prepod.getuspev + this.discipselected.groups}/${
            this.discipselected.discip
          }/${this.d1}/${this.d2}/${this.pos}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
