<template>
  <div id="reitstudbbm">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <table border="1" class="table tableexport table-bordered wb">
              <tbody>
                <template v-if="ocenka">
                  <tr>
                    <td colspan="11" class="text-center font-weight-bold">
                      1 курс
                    </td>
                  </tr>
                  <tr>
                    <th>№ п/п</th>
                    <th class="vertical">Место</th>
                    <th class="vertical">Студент</th>
                    <th class="vertical">Группа</th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.1 «Посещаемость теоретических дисциплин» (20 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.2 «Итоги промежуточной аттестации (сессии)» (15
                        баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.3 «Итоги независимого тестирования» (15 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        2.1.1 «Дисциплина практического обучения» (30 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        2.1.2 «Оценка за выполнение практических заданий» (20
                        баллов)
                      </div>
                    </th>
                    <th>Задолженностей</th>
                    <th class="vertical text-center">
                      <div class="vertical">Итого баллов</div>
                    </th>
                  </tr>

                  <template v-for="(val, k) in ocenka">
                    <template v-if="val.kurs == 1">
                      <tr :key="k">
                        <td class="text-center">{{ val.nums }}</td>
                        <td class="text-center">{{ val.Row }}</td>
                        <td class="small">{{ val.fio }}</td>
                        <td class="text-center small">{{ val.groups }}</td>
                        <td class="text-center">{{ val.p11 }}</td>
                        <td class="text-center">{{ val.p12 }}</td>
                        <td class="text-center">{{ val.p13 }}</td>
                        <td class="text-center">{{ val.p211 }}</td>
                        <td class="text-center">{{ val.p212 }}</td>
                        <td class="text-center">{{ val.dolg }}</td>
                        <td class="text-center font-weight-bold">
                          {{ val.vs }}
                        </td>
                      </tr>
                    </template>
                  </template>
                  <tr>
                    <td colspan="11" class="text-center font-weight-bold">
                      2 курс
                    </td>
                  </tr>
                  <tr>
                    <th>№ п/п</th>
                    <th class="vertical text-center">Место</th>
                    <th class="vertical">Студент</th>
                    <th class="vertical">Группа</th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.1 «Посещаемость теоретических дисциплин» (20 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.2 «Итоги промежуточной аттестации (сессии)» (15
                        баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.3 «Итоги независимого тестирования» (15 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        2.1.1 «Дисциплина практического обучения» (30 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        2.1.2 «Оценка за выполнение практических заданий» (20
                        баллов)
                      </div>
                    </th>
                    <th>Задолженностей</th>
                    <th class="vertical text-center">
                      <div class="vertical">Итого баллов</div>
                    </th>
                  </tr>

                  <template v-for="(val, k) in ocenka">
                    <template v-if="val.kurs == 2">
                      <tr :key="k">
                        <td class="text-center">{{ val.nums }}</td>
                        <td class="text-center">{{ val.Row }}</td>
                        <td class="small">{{ val.fio }}</td>
                        <td class="text-center small">{{ val.groups }}</td>
                        <td class="text-center">{{ val.p11 }}</td>
                        <td class="text-center">{{ val.p12 }}</td>
                        <td class="text-center">{{ val.p13 }}</td>
                        <td class="text-center">{{ val.p211 }}</td>
                        <td class="text-center">{{ val.p212 }}</td>
                        <td class="text-center">{{ val.dolg }}</td>
                        <td class="text-center font-weight-bold">
                          {{ val.vs }}
                        </td>
                      </tr>
                    </template>
                  </template>
                  <tr>
                    <td colspan="11" class="text-center font-weight-bold">
                      3 курс
                    </td>
                  </tr>
                  <tr>
                    <th>№ п/п</th>
                    <th class="vertical text-center">Место</th>
                    <th class="vertical">Студент</th>
                    <th class="vertical">Группа</th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.1 «Посещаемость теоретических дисциплин» (20 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.2 «Итоги промежуточной аттестации (сессии)» (15
                        баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        1.3 «Итоги независимого тестирования» (15 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        2.1.1 «Дисциплина практического обучения» (30 баллов)
                      </div>
                    </th>
                    <th class="vertical text-center">
                      <div class="vertical">
                        2.1.2 «Оценка за выполнение практических заданий» (20
                        баллов)
                      </div>
                    </th>
                    <th>Задолженностей</th>
                    <th class="vertical text-center">
                      <div class="vertical">Итого баллов</div>
                    </th>
                  </tr>

                  <template v-for="(val, kl) in ocenka">
                    <template v-if="val.kurs == 3">
                      <tr :key="kl">
                        <td class="text-center">{{ val.nums }}</td>
                        <td class="text-center">{{ val.Row }}</td>
                        <td class="small">{{ val.fio }}</td>
                        <td class="small text-center">{{ val.groups }}</td>
                        <td class="text-center">{{ val.p11 }}</td>
                        <td class="text-center">{{ val.p12 }}</td>
                        <td class="text-center">{{ val.p13 }}</td>
                        <td class="text-center">{{ val.p211 }}</td>
                        <td class="text-center">{{ val.p212 }}</td>
                        <td class="text-center">{{ val.dolg }}</td>
                        <td class="text-center font-weight-bold">
                          {{ val.vs }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      ocenka: "",
    };
  },
  mounted() {
    this.reitstudbbm();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    reitstudbbm() {
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.reitstudbbm + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.ocenka = res;
          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
