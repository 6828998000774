<template>
  <div id="disability">
    <div class="hk-sec-wrapper">
      <div>
        <div class="form-check custom-checkbox mb-3 checkbox-success">
          <input
            class="form-check-input"
            type="checkbox"
            id="invalid"
            v-model="getinvalid.invalid"
            name="invalid"
            @change="invalidupdate"
          />
          <label class="custom-control-label" for="invalid"
            ><strong>Имею инвалидность</strong></label
          >
        </div>
      </div>
      <section class="hk-sec-wrapper row align-items-end text-center">
        <div class="row">
          <div class="col-md-3">
            <div class="card">
              <h5 class="card-header">Копия заключения ПМПК</h5>
              <div class="card-body">
                <vue-dropzone
                  ref="myVueDropzone1"
                  id="dropzone1"
                  @vdropzone-removed-file="removefile"
                  :options="{ ...dropzoneob, ...dropzoneOptions1 }"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <h5 class="card-header">Копия ИПРА</h5>
              <div class="card-body">
                <vue-dropzone
                  ref="myVueDropzone2"
                  id="dropzone2"
                  @vdropzone-removed-file="removefile"
                  :options="{ ...dropzoneob, ...dropzoneOptions2 }"
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="card">
              <h5 class="card-header">Копия МСЭ</h5>
              <div class="card-body">
                <vue-dropzone
                  ref="myVueDropzone3"
                  id="dropzone3"
                  @vdropzone-removed-file="removefile"
                  :options="{ ...dropzoneob, ...dropzoneOptions3 }"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import vueDropzone from "dropzone-vue3";
export default {
  components: {
    vueDropzone,
  },
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      getinvalid: { invalid: false },
      dropzoneob: {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        acceptedFiles: ".jpg,.png",
        addRemoveLinks: true,
        dictDefaultMessage: "Добавьте необходимые документы",
        dictInvalidFileType: "Тип файла не подходит",
        dictRemoveFile: "Удалить",
        dictCancelUpload: "Отменить",
        paramName: "photo",
        humbnailWidth: 200,
      },
      dropzoneOptions1: {
        url: server.server + server.urlsecond.abiturient.setphoto + "12",
        maxFiles: 1,
      },
      dropzoneOptions2: {
        url: server.server + server.urlsecond.abiturient.setphoto + "11",
        maxFiles: 1,
      },
      dropzoneOptions3: {
        url: server.server + server.urlsecond.abiturient.setphoto + "10",
        maxFiles: 1,
      },
    };
  },
  mounted() {
    this.invalid();
  },
  methods: {
    getdocs(docs, selector) {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.abiturient.getphoto + docs)
        .then((res) => {
          let parent = document.querySelector(selector);
          res.forEach((el) => {
            parent.classList.add("dz-started");
            let div = document.createElement("div");
            div.classList.add(
              "dz-preview",
              "dz-processing",
              "dz-image-preview",
              "dz-success",
              "dz-complete"
            );
            let divimage = document.createElement("div");
            divimage.classList.add("dz-image");

            let image = document.createElement("img");
            image.src = server.ftp + el.path;
            image.classList.add("img-fluid");
            divimage.appendChild(image);

            let dzdetails = document.createElement("div");
            dzdetails.classList.add("dz-details");
            div.appendChild(divimage);
            div.appendChild(dzdetails);
            let a = document.createElement("a");
            a.classList.add("dz-remove");
            a.href = "javascript:undefined;";
            a.innerHTML = "Удалить";
            div.appendChild(a);
            a.addEventListener("click", () => {
              this.removefilecustom(el.id);
              div.remove();
            });
            parent.appendChild(div);
          });
          this.loadchange(true);
        });
    },
    removefilecustom(id) {
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    removefile(th) {
      let id = JSON.parse(th.xhr.response).id;
      server
        .submittoserver(
          server.urlsecond.abiturient.removephoto + id,
          {},
          "DELETE"
        )
        .then(() => {})
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },
    doc() {
      this.getdocs(12, "#dropzone1");
      this.getdocs(11, "#dropzone2");
      this.getdocs(10, "#dropzone3");
    },

    invalid() {
      server
        .submittoserver(server.urlsecond.abiturient.getinvalid)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.doc();
          this.getinvalid = res;
          this.getinvalid.invalid = this.getinvalid.invalid == 1;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });

      this.loadchange(true);
      server.dis(this.blocked, "activecontent12");
    },
    invalidupdate() {
      server.dis(true, "activecontent12");
      server
        .submittoserver(
          server.urlsecond.abiturient.setinvalid,
          this.getinvalid,
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "activecontent12", [
            { mess: "mess", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
