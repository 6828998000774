<template>
  <div id="finalperformance">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-body">
          <div class="input-group">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="hk-sec-wrapper">
        <div id="table" class="mt-1">
          <div id="resource">
            <div class="mt10 table-responsive">
              <table
                border="1"
                class="table tableexport table-bordered wb"
                v-html="ocenka.tab"
              ></table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
export default {
  props: ["loadchange", "logoutweb", "mobile"],
  data() {
    return {
      ocenka: "",
    };
  },
  mounted() {
    this.finalperformance();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),

    finalperformance() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.student.finalperformance, "POST")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
