<template>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <span>Личный кабинет</span>
    </li>
    <li class="breadcrumb-item" v-if="parent!='null'&&parent!=''">
      <span>{{ parent }}</span>
    </li>
    <li class="breadcrumb-item active">
      <span>{{ title }}</span>
    </li>
  </ol>
</template>
<script>
export default {
  props: ["title", "parent"],
};
</script>