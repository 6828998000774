<template>
  <div id="personaldata">
    <div class="hk-sec-wrapper">
      <div class="row">
        <div class="col-md-3">
          <div class="card card-body">
            <div class="input-group">
              <seldate
                :periods="periods"
                :changeperiod="changeperiod"
                :dates="d1 + ' - ' + d2"
              />
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card card-body">
            <select
              class="form-control groups"
              v-model="groups.selected"
              @change="getpersonaldata"
            >
              <option
                v-for="(vs, k) in groups.options"
                :selected="k == 0"
                :key="vs.kod"
                :value="vs.kod"
              >
                {{ vs.naim }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div id="resource" v-html="ocenka.tab"></div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import seldate from "@/components/seldate.vue";
export default {
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb"],
  components: { seldate },
  data() {
    return {
      ocenka: "",
      groups: {
        selected: 0,
        options: [],
      },
    };
  },
  mounted() {
    this.getgroups();
  },
  updated: function () {
    if (document.querySelectorAll(".savepersdata").length) {
      document.querySelectorAll(".savepersdata").forEach((el) => {
        el.addEventListener("click", () => {
          this.selectchanges(el.dataset.id);
        });
      });
    }
    if (document.querySelectorAll(".generatenewpass").length) {
      document.querySelectorAll(".generatenewpass").forEach((el) => {
        el.addEventListener("click", () => {
          this.generatenewpass(el.dataset.id);
        });
      });
    }
    if (document.querySelectorAll(".generatenewpassmom").length) {
      document.querySelectorAll(".generatenewpassmom").forEach((el) => {
        el.addEventListener("click", () => {
          this.generatenewpassrod(el.dataset.id, 0);
        });
      });
    }
    if (document.querySelectorAll(".savestarosta").length) {
      document.querySelectorAll(".savestarosta").forEach((el) => {
        el.addEventListener("click", () => {
          this.savestarosta(el.dataset.id);
        });
      });
    }
    if (document.querySelectorAll(".generatenewpassfat").length) {
      document.querySelectorAll(".generatenewpassfat").forEach((el) => {
        el.addEventListener("click", () => {
          this.generatenewpassrod(el.dataset.id, 1);
        });
      });
    }
  },

  methods: {

    getgroups() {
      server
        .submittoserver(
          `${server.urlsecond.prepod.getgroupsklass + this.d1}/${this.d2}`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.groups.options = res;
          this.groups.selected = this.groups.options[0].kod;
          this.getpersonaldata();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    selectchanges(stud) {
      let ob = {};
      ob["id"] = stud;
      document
        .querySelectorAll(
          `#resource div#sq${stud} input,#resource div#sq${stud} select`
        )
        .forEach((el) => {
          ob[el.name] = el.value;
        });
      // console.log(ob);
      server
        .submittoserver(server.urlsecond.prepod.savepersonaldata, ob, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.error) {
            server.toasts(res.error, "danger");
            return;
          }
          server.messages(res, "personaldata", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    savestarosta(stud){
      server
        .submittoserver(
          server.urlsecond.prepod.savestarosta,
          {stud: stud},
          "POST"
        )
        .then((res) => {
          
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getpersonaldata();
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
},

    generatenewpass(stud) {
      server
        .submittoserver(
          server.urlsecond.prepod.generatenepassword + stud,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          document.querySelector(
            `#resource div#sq${stud} .mess`
          ).innerHTML = `Новый пароль: ${res.message}`;
          server.messages(res, "personaldata", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    generatenewpassrod(stud, who) {
      server
        .submittoserver(
          server.urlsecond.prepod.generatenepasswordrod + stud + "/" + who,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          document.querySelector(`#resource #sqinfo${who}_${stud}`).innerHTML =
            `<span class="text-danger">Новый логин: ${res.login}<br>` +
            `Новый пароль: ${res.password}</span>`;
          server.messages(res, "personaldata", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },

    generatepass(id, vid) {
      server
        .submittoserver(
          `${server.urlsecond.prepod.generatepass + id}/${vid}`,
          {},
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          alert("Новый пароль: " + res.message);
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
    },


    getpersonaldata() {
      this.loadchange(false);
      server
        .submittoserver(
          server.urlsecond.prepod.getpersonaldata + this.groups.selected,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
