<template>
  <div class="fix-wrapper">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-6">
          <div class="card mb-0 h-auto">
            <div class="card-body">
              <div class="text-center mb-3">
                <img class="logo-auth" :src="logo" alt="" />
              </div>
              <AuthForm
                :changePage="changePage"
                :addToken="addToken"
                v-if="page == 'AuthForm'"
              />
              <ForgotForm
                :changePage="changePage"
                v-if="page == 'ForgotForm'"
                :changeEmail="changeEmail"
              />
              <RegisterForm
                :changePage="changePage"
                v-if="page == 'RegisterForm'"
              />
              <ResetForm
                :changePage="changePage"
                :email="email"
                :addToken="addToken"
                v-if="page == 'ResetForm'"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AuthForm from "./AuthForm.vue";
import ForgotForm from "./ForgotForm.vue";
import RegisterForm from "./RegisterForm.vue";
import ResetForm from "./ResetForm.vue";
export default {
  props: ["addToken", "logo", "loadchange"],
  components: {
    AuthForm,
    ForgotForm,
    RegisterForm,
    ResetForm,
  },
  data() {
    return {
      page: "AuthForm",
      email: null,
    };
  },
  mounted() {
    this.loadchange(true);
  },
  methods: {
    changePage(page) {
      this.page = page;
      // this.loadchange(true);
    },
    changeEmail(email){
      this.email=email;
    }
  },
};
</script>