<template>
  <div id="plansetka">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-body">
          <div class="input-group">
            <button
              class="btn btn-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              v-if="!mobile"
            >
              Экспорт
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="toword" href="#" v-if="ocenka">
                MS Word
              </a>
              <a class="dropdown-item" @click="toexcel" href="#" v-if="ocenka">
                MS Excel
              </a>
            </div>
            <seldate
              :periods="periods"
              :changeperiod="changeperiod"
              :dates="d1 + ' - ' + d2"
            />

            <button
              class="btn btn-primary"
              :class="{ active: bbm }"
              @click="changebbm()"
            >
              Профессионалитет
            </button>
            <select
              class="form-control form-select"
              v-model="period"
              @change="changes"
            >
              <option value="" readonly disabled>Выберите период</option>
              <option
                v-for="(item, keys) in dataperiod"
                :key="keys"
                :value="item.key"
              >
                {{ item.val }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="hk-sec-wrapper">
      <div id="table" class="mt-1">
        <div id="resource">
          <div class="mt10 table-responsive">
            <div v-if="period">
              <p style="text-align: right">
                УТВЕРЖДАЮ<br />
                Директор ГБПОУ<br />
                "ЧГПГТ им. А.В. Яковлева"<br />
                ____________ Е.Б.Валахов<br />
              </p>

              <h2 style="text-align: center">
                ПЛАН-СЕТКА НА {{ monthselect }}
              </h2>
            </div>
            <table
              border="1"
              class="table tableexport table-bordered wb"
              v-html="ocenka.tab"
            ></table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
import { exportdoc } from "@/assets/js/exportdoc";
import seldate from "@/components/seldate.vue";
export default {
  components: { seldate },
  props: ["periods", "d1", "d2", "changeperiod", "loadchange", "logoutweb", "mobile"],
  data() {
    return {
      bbm: 0,
      ocenka: "",
      zavisim: 1,
      period: "",
      dataperiod: [],
      monthselect: "",
      month: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
    };
  },
  mounted() {
    this.getper();
  },

  methods: {
    toword: () => exportdoc.toword(),
    toexcel: (event) => exportdoc.toexcel(event),
    changebbm() {
      this.bbm = 1 - this.bbm;
      this.changes();
    },
    getper() {
      let d1 = this.d1.split(".");
      let d2 = this.d2.split(".");

      for (let i = parseInt(d1[1]); i <= parseInt(d2[1]); i++) {
        this.dataperiod.push({
          key: `01.${`0${i}`.slice(-2)}.${d1[2]}`,
          val: `${this.month[i - 1]} ${d1[2]}`,
        });
      }
    },

    changes() {
      let d = this.period.split(".");
      this.monthselect = `${this.month[parseInt(d[1]) - 1]} ${d[2]}`;

      if (!this.period) return;
      this.loadchange(false);
      server
        .submittoserver(
          `${server.urlsecond.prepod.getplansetka + this.zavisim}/${this.bbm}/${
            this.period
          }`,
          {},
          "POST"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.loadchange(true);
          this.ocenka = res;
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
