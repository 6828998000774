<template>
  <div id="address2">
    <div class="row">
      <div class="col-md-6">
        <div class="hk-sec-wrapper card">
          <div class="card-body">
            <h5 class="mb-15">Адрес по прописке (по паспорту)</h5>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="strana">Страна</label>
                  <select
                    class="form-control form-select"
                    id="strana"
                    name="strana"
                    v-model="getadr0.strana"
                  >
                    <option selected disabled></option>
                    <option
                      v-for="(e, key) in getstrana"
                      :key="key"
                      :value="e.id"
                    >
                      {{ e.strana }}
                    </option>
                  </select>
                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="oblast">Регион</label>
                  <multiselect
                    v-model="region0"
                    deselect-label=""
                    track-by="oblast"
                    label="oblast"
                    :disabled="blocked"
                    placeholder="Выберите регион"
                    :clear-on-select="false"
                    :options="getoblast"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr0.oblast = region0.id"
                    @input="newvalue = $event.target.value"
                    ref="region0"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'region0',
                            'setdescroblast',
                            'oblast',
                            'getoblast',
                            'getadr0'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >
                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="gorod0">Населенный пункт</label>
                  <multiselect
                    v-model="gorod0"
                    deselect-label=""
                    track-by="gorod"
                    label="gorod"
                    :disabled="blocked"
                    placeholder="Выберите населенный пункт"
                    :clear-on-select="false"
                    :options="getgorod"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr0.gorod = gorod0.id"
                    @input="newvalue = $event.target.value"
                    ref="gorod0"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'gorod0',
                            'setdescrgorod',
                            'gorod',
                            'getgorod',
                            'getadr0'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="rayon0">Район</label>
                  <multiselect
                    v-model="rayon0"
                    deselect-label=""
                    track-by="rayon"
                    label="rayon"
                    :disabled="blocked"
                    placeholder="Выберите район"
                    :clear-on-select="false"
                    :options="getrayon"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr0.rayon = rayon0.id"
                    @input="newvalue = $event.target.value"
                    ref="rayon0"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'rayon0',
                            'setdescrrayon',
                            'rayon',
                            'getrayon',
                            'getadr0'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >

                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="ulica">Улица</label>
                  <multiselect
                    v-model="ulica0"
                    deselect-label=""
                    track-by="ulica"
                    :disabled="blocked"
                    label="ulica"
                    placeholder="Выберите улицу"
                    :clear-on-select="false"
                    :options="getulica"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr0.ulica = ulica0.id"
                    @input="newvalue = $event.target.value"
                    ref="ulica0"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'ulica0',
                            'setdescrulica',
                            'ulica',
                            'getulica',
                            'getadr0'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >

                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="indeks">Индекс</label>
                  <div class="input-group">
                    <input
                      v-maska
                      class="form-control"
                      v-model="getadr0.indeks"
                      placeholder="Индекс"
                      data-maska="######"
                      id="indeks"
                      name="indeks"
                      type="text"
                    />
                  </div>
                  <p id="errindeks"></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="dom">Дом</label>
                <input
                  class="form-control"
                  id="dom"
                  placeholder="Дом"
                  name="dom"
                  type="text"
                  v-model="getadr0.dom"
                />
                <div class="invalid-feedback"></div>
              </div>
              <div class="form-group col-md-6">
                <label for="kvartira">Квартира</label>
                <input
                  class="form-control"
                  id="kvartira"
                  placeholder="Квартира"
                  name="kvartira"
                  type="text"
                  v-model="getadr0.kvartira"
                />
                <div class="invalid-feedback"></div>
              </div>
            </div>
            <div class="form-group">
              <label for="datereg">Дата регистрации</label>

              <VueDatePicker
                v-model="getadr0.datereg"
                name="datereg"
                id="datereg"
                locale="ru-RU"
                format="dd.MM.yyyy"
                auto-apply
                calendarCellClassName="form-control"
                inputClassName="form-control"
                autoPosition
                position="left"
                :dark="version == 'dark'"
                placeholder="Дата регистрации"
                model-type="dd.MM.yyyy"
              ></VueDatePicker>

              <div class="invalid-feedback"></div>
            </div>
            <p class="h5">
              <a target="_blank" href="https://www.pochta.ru/RU/post-index">
                Найти индекс
              </a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="hk-sec-wrapper card">
          <div class="card-body">
            <h5 class="mb-15">Адрес проживания</h5>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="strana2">Страна</label>
                  <select
                    class="form-control form-select"
                    id="strana2"
                    name="strana"
                    v-model="getadr1.strana"
                  >
                    <option selected disabled></option>
                    <option
                      v-for="(e, key) in getstrana"
                      :key="key"
                      :value="e.id"
                    >
                      {{ e.strana }}
                    </option>
                  </select>
                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="oblast2">Регион</label>
                  <multiselect
                    v-model="region1"
                    deselect-label=""
                    track-by="oblast"
                    label="oblast"
                    :disabled="blocked"
                    placeholder="Выберите регион"
                    :clear-on-select="false"
                    :options="getoblast"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr1.oblast = region1.id"
                    @input="newvalue = $event.target.value"
                    ref="region1"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'region1',
                            'setdescroblast',
                            'oblast',
                            'getoblast',
                            'getadr0'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >
                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="goro1">Населенный пункт</label>
                  <multiselect
                    v-model="gorod1"
                    deselect-label=""
                    track-by="gorod"
                    :disabled="blocked"
                    label="gorod"
                    placeholder="Выберите населенный пункт"
                    :clear-on-select="false"
                    :options="getgorod"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr1.gorod = gorod1.id"
                    @input="newvalue = $event.target.value"
                    ref="gorod1"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'gorod1',
                            'setdescrgorod',
                            'gorod',
                            'getgorod',
                            'getadr1'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >
                  <div class="invalid-feedback"></div>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <label for="rayon1">Район</label>
                  <multiselect
                    v-model="rayon1"
                    deselect-label=""
                    track-by="rayon"
                    :disabled="blocked"
                    label="rayon"
                    placeholder="Выберите район"
                    :clear-on-select="false"
                    :options="getrayon"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr1.rayon = rayon1.id"
                    @input="newvalue = $event.target.value"
                    ref="rayon1"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'rayon1',
                            'setdescrrayon',
                            'rayon',
                            'getrayon',
                            'getadr1'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >
                  <div class="invalid-feedback"></div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="ulica1">Улица</label>
                  <multiselect
                    v-model="ulica1"
                    deselect-label=""
                    :disabled="blocked"
                    track-by="ulica"
                    label="ulica"
                    placeholder="Выберите улицу"
                    :clear-on-select="false"
                    :options="getulica"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="true"
                    :allow-empty="false"
                    selectLabel=""
                    @select="getadr1.ulica = ulica1.id"
                    @input="newvalue = $event.target.value"
                    ref="ulica1"
                    selectedLabel=""
                  >
                    <template #noResult>
                      Ничего не найдено.
                      <button
                        class="btn btn-primary btn-xxs"
                        @click="
                          addregion(
                            'ulica1',
                            'setdescrulica',
                            'ulica',
                            'getulica',
                            'getadr1'
                          )
                        "
                        type="button"
                      >
                        Добавить
                      </button>
                    </template></multiselect
                  >
                  <div class="invalid-feedback"></div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="indeks2">Индекс</label>
                  <div class="input-group">
                    <input
                      v-maska
                      class="form-control"
                      v-model="getadr1.indeks"
                      placeholder="Индекс"
                      data-maska="######"
                      id="indeks2"
                      name="indeks2"
                      type="text"
                    />
                  </div>
                  <p id="errindeks2"></p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-6">
                <label for="dom2">Дом</label>
                <input
                  class="form-control"
                  id="dom2"
                  placeholder="Дом"
                  name="dom"
                  type="text"
                  v-model="getadr1.dom"
                />
                <div class="invalid-feedback"></div>
              </div>
              <div class="form-group col-md-6">
                <label for="kvartira2">Квартира</label>
                <input
                  class="form-control"
                  id="kvartira2"
                  placeholder="Квартира"
                  name="kvartira"
                  type="text"
                  v-model="getadr1.kvartira"
                />
                <div class="invalid-feedback"></div>
              </div>
            </div>
            <div class="btn-group mt-5">
              <button
                class="btn btn-primary"
                type="button"
                @click="addresscopy"
              >
                Совпадает с пропиской
              </button>
              <button
                class="btn btn-primary mt-15"
                type="button"
                @click="addressupdate"
              >
                Сохранить
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="hk-pg-header align-items-top mt-xl-50 mt-sm-30 mx-15 mt-15 w-100"
    >
      <div>
        <h2 class="hk-pg-title font-weight-600 mb-10">Инструкция</h2>
      </div>
    </div>
    <div class="hk-sec-wrapper mx-15 w-100">
      <p>
        Внесите адрес по прописке с вашего документа, удостоверяющего личность,
        а также фактическое место проживания.
      </p>
    </div>
  </div>
</template>
<script setup>
import { vMaska } from "maska";
</script>
<script>
import server from "@/assets/js/server";

export default {
  props: ["blocked", "loadchange", "logoutweb", "version"],
  data() {
    return {
      getstrana: [],
      getoblast: [],
      getrayon: [],
      getgorod: [],
      getulica: [],
      getadr0: {},
      getadr1: {},

      region0: {},
      region1: {},
      gorod0: {},
      gorod1: {},
      rayon0: {},
      rayon1: {},
      ulica0: {},
      ulica1: {},
      newvalue: null,
    };
  },
  mounted() {
    this.address();
    server.dis(this.blocked, "address2");
  },
  methods: {
    addregion(refs, path, key, arrayfull, vmodel) {
      server
        .submittoserver(
          server.urlsecond.abiturient[path],
          { [key]: this.newvalue },
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.mess) {
            server.toasts(res.mess, "success");
            this[arrayfull].push({ id: res.id, [key]: this.newvalue });
            this[refs] = { id: res.id, [key]: this.newvalue };

            this[vmodel][key] = res.id;
            this.closeregion0(refs);
          } else {
            server.toasts(res[key], "error");
          }
        })
        .catch(() => {
          server.toasts("Ошибка сервера", "error");
        });
    },

    closeregion0(refs) {
      this.$refs[refs].deactivate();
    },

    addresscopy() {
      this.getadr1.strana = this.getadr0.strana;
      this.getadr1.oblast = this.getadr0.oblast;
      this.getadr1.rayon = this.getadr0.rayon;
      this.getadr1.gorod = this.getadr0.gorod;
      this.getadr1.ulica = this.getadr0.ulica;
      this.getadr1.dom = this.getadr0.kvartira;
      this.getadr1.indeks = this.getadr0.indeks;
      this.getadr1.kvartira = this.getadr0.kvartira;
      this.region1 = this.region0;
      this.gorod1 = this.gorod0;
      this.rayon1 = this.rayon0;
      this.ulica1 = this.ulica0;
    },

    address() {
      this.loadchange(false);
      server
        .submittoserver(server.urlsecond.abiturient.getaddresslist)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getstrana = res.strana;

          this.getoblast = res.oblast;
          this.getrayon = res.rayon;
          this.getgorod = res.gorod;
          this.getulica = res.ulica;
          this.getadr0 = res.address[0];
          this.getadr0.datereg = new Date(this.getadr0.datereg)
            .toLocaleString("ru-RU")
            .slice(0, -10);
          this.getadr1 = res.address[1];
          if (this.getadr0.strana)
            this.strana0 = this.getstrana.find(
              (x) => x.id == this.getadr0.strana
            );
          if (this.getadr1.strana)
            this.strana1 = this.getstrana.find(
              (x) => x.id == this.getadr1.strana
            );
          if (this.getadr0.oblast)
            this.region0 = this.getoblast.find(
              (x) => x.id == this.getadr0.oblast
            );
          if (this.getadr1.oblast)
            this.region1 = this.getoblast.find(
              (x) => x.id == this.getadr1.oblast
            );
          if (this.getadr0.gorod)
            this.gorod0 = this.getgorod.find((x) => x.id == this.getadr0.gorod);
          if (this.getadr1.gorod)
            this.gorod1 = this.getgorod.find((x) => x.id == this.getadr1.gorod);
          if (this.getadr0.rayon)
            this.rayon0 = this.getrayon.find((x) => x.id == this.getadr0.rayon);
          if (this.getadr1.gorod)
            this.rayon1 = this.getrayon.find((x) => x.id == this.getadr1.rayon);
          if (this.getadr0.ulica)
            this.ulica0 = this.getulica.find((x) => x.id == this.getadr0.ulica);
          if (this.getadr1.ulica)
            this.ulica1 = this.getulica.find((x) => x.id == this.getadr1.ulica);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
    },

    addressupdate() {
      this.loadchange(false);
      server
        .submittoserver(
          server.urlsecond.abiturient.setadr,
          { adr0: this.getadr0, adr1: this.getadr1 },
          "PUT"
        )
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          if (res.message) {
            server.toasts(res.message, "success");
          } else {
            Object.values(res).forEach((element) => {
              server.toasts(element[0], "error");
            });
          }

          this.loadchange(true);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
    },
  },
};
</script>
