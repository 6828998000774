<template>
  <div class="default-tab">
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" data-bs-toggle="tab" href="#admin-settings"
          ><vue-feather type="aperture"></vue-feather> Тема</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#profile"
          ><vue-feather type="upload"></vue-feather> Шапка сайта</a
        >
      </li>
      <li class="nav-item">
        <a class="nav-link" data-bs-toggle="tab" href="#contact"
          ><vue-feather type="sliders"></vue-feather> Контент</a
        >
      </li>
    </ul>
    <div class="sidebar-right">
      <div class="tab-content">
        <div
          class="tab-pane fade show active position-relative"
          id="admin-settings"
          role="tabpanel"
        >
          <div class="pt-4 admin-settings">
            <div class="row">
              <div class="col-sm-6">
                <p>Основной цвет</p>
                <div>
                  <span data-placement="top" data-bs-toggle="tooltip">
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_1"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_1')"
                      name="primary_bg"
                      type="radio"
                      value="color_1"
                    />
                    <label
                      for="primary_color_1"
                      class="bg-label-pattern"
                    ></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_2"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_2')"
                      name="primary_bg"
                      type="radio"
                      value="color_2"
                    />
                    <label for="primary_color_2"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_3"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_3')"
                      name="primary_bg"
                      type="radio"
                      value="color_3"
                    />
                    <label for="primary_color_3"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_4"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_4')"
                      name="primary_bg"
                      type="radio"
                      value="color_4"
                    />
                    <label for="primary_color_4"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_5"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_5')"
                      name="primary_bg"
                      type="radio"
                      value="color_5"
                    />
                    <label for="primary_color_5"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_6"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_6')"
                      name="primary_bg"
                      type="radio"
                      value="color_6"
                    />
                    <label for="primary_color_6"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_7"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_7')"
                      name="primary_bg"
                      type="radio"
                      value="color_7"
                    />
                    <label for="primary_color_7"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_9"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_9')"
                      name="primary_bg"
                      type="radio"
                      value="color_9"
                    />
                    <label for="primary_color_9"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_10"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_10')"
                      name="primary_bg"
                      type="radio"
                      value="color_10"
                    />
                    <label for="primary_color_10"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_11"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_11')"
                      name="primary_bg"
                      type="radio"
                      value="color_11"
                    />
                    <label for="primary_color_11"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_12"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_12')"
                      name="primary_bg"
                      type="radio"
                      value="color_12"
                    />
                    <label for="primary_color_12"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_13"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_13')"
                      name="primary_bg"
                      type="radio"
                      value="color_13"
                    />
                    <label for="primary_color_13"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_14"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_14')"
                      name="primary_bg"
                      type="radio"
                      value="color_14"
                    />
                    <label for="primary_color_14"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="primary_color_15"
                      v-model="primarycolor1"
                      @change="changePrimaryColor('color_15')"
                      name="primary_bg"
                      type="radio"
                      value="color_15"
                    />
                    <label for="primary_color_15"></label>
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <p>Фон логотипа</p>
                <div>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_1"
                      name="navigation_header"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_1')"
                      type="radio"
                      value="color_1"
                    />
                    <label
                      for="nav_header_color_1"
                      class="bg-label-pattern"
                    ></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_2"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_2')"
                      name="navigation_header"
                      type="radio"
                      value="color_2"
                    />
                    <label for="nav_header_color_2"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_3"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_3')"
                      name="navigation_header"
                      type="radio"
                      value="color_3"
                    />
                    <label for="nav_header_color_3"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_4"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_4')"
                      name="navigation_header"
                      type="radio"
                      value="color_4"
                    />
                    <label for="nav_header_color_4"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_5"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_5')"
                      name="navigation_header"
                      type="radio"
                      value="color_5"
                    />
                    <label for="nav_header_color_5"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_6"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_6')"
                      name="navigation_header"
                      type="radio"
                      value="color_6"
                    />
                    <label for="nav_header_color_6"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_7"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_7')"
                      name="navigation_header"
                      type="radio"
                      value="color_7"
                    />
                    <label for="nav_header_color_7"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_8"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_8')"
                      name="navigation_header"
                      type="radio"
                      value="color_8"
                    />
                    <label for="nav_header_color_8" class="border"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_9"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_9')"
                      name="navigation_header"
                      type="radio"
                      value="color_9"
                    />
                    <label for="nav_header_color_9"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_10"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_10')"
                      name="navigation_header"
                      type="radio"
                      value="color_10"
                    />
                    <label for="nav_header_color_10"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_11"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_11')"
                      name="navigation_header"
                      type="radio"
                      value="color_11"
                    />
                    <label for="nav_header_color_11"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_12"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_12')"
                      name="navigation_header"
                      type="radio"
                      value="color_12"
                    />
                    <label for="nav_header_color_12"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_13"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_13')"
                      name="navigation_header"
                      type="radio"
                      value="color_13"
                    />
                    <label for="nav_header_color_13"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_14"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_14')"
                      name="navigation_header"
                      type="radio"
                      value="color_14"
                    />
                    <label for="nav_header_color_14"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="nav_header_color_15"
                      v-model="navheaderBg1"
                      @change="changenavheaderBg('color_15')"
                      name="navigation_header"
                      type="radio"
                      value="color_15"
                    />
                    <label for="nav_header_color_15"></label>
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <p>Шапка</p>
                <div>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_1"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_1')"
                      name="header_bg"
                      type="radio"
                      value="color_1"
                    />
                    <label
                      for="header_color_1"
                      class="bg-label-pattern"
                    ></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_2"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_2')"
                      name="header_bg"
                      type="radio"
                      value="color_2"
                    />
                    <label for="header_color_2"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_3"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_3')"
                      name="header_bg"
                      type="radio"
                      value="color_3"
                    />
                    <label for="header_color_3"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_4"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_4')"
                      name="header_bg"
                      type="radio"
                      value="color_4"
                    />
                    <label for="header_color_4"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_5"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_5')"
                      name="header_bg"
                      type="radio"
                      value="color_5"
                    />
                    <label for="header_color_5"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_6"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_6')"
                      name="header_bg"
                      type="radio"
                      value="color_6"
                    />
                    <label for="header_color_6"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_7"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_7')"
                      name="header_bg"
                      type="radio"
                      value="color_7"
                    />
                    <label for="header_color_7"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_8"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_8')"
                      name="header_bg"
                      type="radio"
                      value="color_8"
                    />
                    <label for="header_color_8" class="border"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_9"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_9')"
                      name="header_bg"
                      type="radio"
                      value="color_9"
                    />
                    <label for="header_color_9"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_10"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_10')"
                      name="header_bg"
                      type="radio"
                      value="color_10"
                    />
                    <label for="header_color_10"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_11"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_11')"
                      name="header_bg"
                      type="radio"
                      value="color_11"
                    />
                    <label for="header_color_11"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_12"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_12')"
                      name="header_bg"
                      type="radio"
                      value="color_12"
                    />
                    <label for="header_color_12"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_13"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_13')"
                      name="header_bg"
                      type="radio"
                      value="color_13"
                    />
                    <label for="header_color_13"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_14"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_14')"
                      name="header_bg"
                      type="radio"
                      value="color_14"
                    />
                    <label for="header_color_14"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="header_color_15"
                      v-model="header_bg1"
                      @change="changeheader_bg('color_15')"
                      name="header_bg"
                      type="radio"
                      value="color_15"
                    />
                    <label for="header_color_15"></label>
                  </span>
                </div>
              </div>
              <div class="col-sm-6">
                <p>Цвет меню</p>
                <div>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_1"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_1')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_1"
                    />
                    <label
                      for="sidebar_color_1"
                      class="bg-label-pattern"
                    ></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_2"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_2')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_2"
                    />
                    <label for="sidebar_color_2"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_3"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_3')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_3"
                    />
                    <label for="sidebar_color_3"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_4"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_4')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_4"
                    />
                    <label for="sidebar_color_4"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_5"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_5')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_5"
                    />
                    <label for="sidebar_color_5"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_6"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_6')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_6"
                    />
                    <label for="sidebar_color_6"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_7"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_7')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_7"
                    />
                    <label for="sidebar_color_7"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_8"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_8')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_8"
                    />
                    <label for="sidebar_color_8" class="border"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_9"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_9')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_9"
                    />
                    <label for="sidebar_color_9"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_10"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_10')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_10"
                    />
                    <label for="sidebar_color_10"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_11"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_11')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_11"
                    />
                    <label for="sidebar_color_11"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_12"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_12')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_12"
                    />
                    <label for="sidebar_color_12"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_13"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_13')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_13"
                    />
                    <label for="sidebar_color_13"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_14"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_14')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_14"
                    />
                    <label for="sidebar_color_14"></label>
                  </span>
                  <span>
                    <input
                      class="chk-col-primary filled-in"
                      id="sidebar_color_15"
                      v-model="sidebarBg1"
                      @change="changesidebarBg('color_15')"
                      name="sidebar_bg"
                      type="radio"
                      value="color_15"
                    />
                    <label for="sidebar_color_15"></label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade position-relative" id="profile">
          <div class="admin-settings">
            <div class="row">
              <div class="col-sm-6">
                <p>Макет</p>
                <select
                  class="default-select wide form-control form-select"
                  id="theme_layout"
                  name="theme_layout"
                  @change="changelayout($event)"
                  v-model="layout1"
                >
                  <option value="vertical">Вертикальный</option>
                  <option value="horizontal">Горизонтальный</option>
                </select>
              </div>
              <div class="col-sm-6">
                <p>Позиция шапки сайта</p>
                <select
                  class="default-select wide form-control form-select"
                  id="header_position"
                  name="header_position"
                  @change="headerPositionChange($event)"
                  v-model="headerPosition1"
                >
                  <option value="static">Статичное</option>
                  <option value="fixed">Фиксированное</option>
                </select>
              </div>
              <div class="col-sm-6">
                <p>Боковая панель</p>
                <select
                  class="default-select wide form-control form-select"
                  id="sidebar_style"
                  name="sidebar_style"
                  @change="sidebarchange($event)"
                  v-model="sidebarStyle1"
                >
                  <option value="full">Полная</option>
                  <option value="mini">Мини</option>
                  <option value="compact">Компактная</option>
                  <option value="modern">Модерн</option>
                  <option value="overlay">Наложение</option>
                  <option value="icon-hover">Иконки</option>
                </select>
              </div>
              <div class="col-sm-6">
                <p>Позиция меню</p>
                <select
                  class="default-select wide form-control form-select"
                  id="sidebar_position"
                  name="sidebar_position"
                  @change="sidebarPositionChange($event)"
                  v-model="sidebarPosition1"
                >
                  <option value="static">Статичное</option>
                  <option value="fixed">Фиксированное</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade position-relative" id="contact">
          <div class="admin-settings">
            <div class="row">
              <div class="col-sm-6">
                <p>Весь сайт</p>
                <select
                  class="default-select wide form-control form-select"
                  id="container_layout"
                  name="container_layout"
                  @change="containerLayoutChange($event)"
                  v-model="containerLayout1"
                >
                  <option value="wide">На весь экран</option>
                  <option value="boxed">Небольшого размера</option>
                  <option value="wide-boxed">Среднего размера</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      primarycolor1: this.primarycolor,
      navheaderBg1: this.navheaderBg,
      header_bg1: this.header_bg,
      sidebarBg1: this.sidebarBg,
      layout1: this.layout,
      sidebarStyle1: this.sidebarStyle,
      headerPosition1: this.headerPosition,
      sidebarPosition1: this.sidebarPosition,
      containerLayout1: this.containerLayout,
    };
  },
  props: [
    "primarycolor",
    "changePrimaryColor",
    "navheaderBg",
    "changenavheaderBg",
    "header_bg",
    "changeheader_bg",
    "sidebarBg",
    "changesidebarBg",
    "changelayout",
    "layout",
    "sidebarStyle",
    "sidebarchange",
    "headerPositionChange",
    "headerPosition",
    "sidebarPositionChange",
    "sidebarPosition",
    "containerLayoutChange",
    "containerLayout",
  ],
};
</script>

