<template>
  <div id="marks">
    <div class="hk-sec-wrapper">
      <form>
        <div class="table-wrap">
          <div class="table-responsive">
            <table class="table mb-0 table-bordered wb">
              <thead>
                <tr>
                  <th>Предмет</th>
                  <th>Оценка</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in getdscrocenk" :key="i">
                  <td>{{ i + 1 }}. {{ e.discip }}</td>
                  <td>
                    <input
                      type="number"
                      min="3"
                      max="5"
                      :data-discip="e.id"
                      name="discip"
                      class="form-control form-control-sm"
                      @keyup="keyupmark(e.id)"
                      @change="keyupmark(e.id)"
                    />
                    <div class="invalid-feedback"></div>
                  </td>
                </tr>
                <tr>
                  <td class="text-primary font-weight-bold">
                    Среднее значение балла по аттестату
                  </td>
                  <td class="font-weight-bold" id="avg">{{ getavg }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button class="btn btn-primary mt-3" type="button" @click="marksupdate">
          Сохранить
        </button>
      </form>
    </div>
    <div class="hk-pg-header align-items-top mt-xl-50 mt-sm-30 mt-3">
      <div>
        <h2 class="hk-pg-title font-weight-600 mb-10">Инструкция</h2>
      </div>
    </div>
    <div class="hk-sec-wrapper">
      <p>
        Внесите оценки из документа об образовании. Количество и название
        предметов соответствуют правилам приёма. Средний балл Вашего аттестата
        берется только по указанным предметам выше. Если предмета указанного в
        таблице выше нет, в списке предметов, то тогда необходимо выставить все
        другие оценки, а по решению вопроса с отсутствующим необходимо
        обратиться в Приемную комиссию.
      </p>
    </div>
  </div>
</template>

<script>
import server from "@/assets/js/server";
export default {
  props: ["blocked", "loadchange", "logoutweb"],
  data() {
    return {
      getdscrocenk: [],
      getavg: "",
    };
  },
  mounted() {
    this.marks();
  },
  methods: {
    keyupmark(a) {
      let r = document.querySelector(`#marks table input[data-discip="${a}"]`);
      r.classList.remove("is-invalid");
       r.nextElementSibling.innerHTML= "";
      if ((r.value > 5 || r.value < 3) && r.value) {
        r.classList.add("is-invalid");
        r.nextElementSibling.innerHTML= "Значение от 3 до 5";
      }
    },
    marks() {
      this.loadchange(false);
      server.messages({}, "marks");

      server
        .submittoserver(server.urlsecond.abiturient.getdscrocenk)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getdscrocenk = res;
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
      server
        .submittoserver(server.urlsecond.abiturient.getavg)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          this.getavg = Math.round(res[0].av * 100) / 100 || "";
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
      server
        .submittoserver(server.urlsecond.abiturient.getocenk)
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          res.forEach((e) => {
            let t = e.ocenka == 0 ? "" : e.ocenka;
            server.dis(this.blocked, "marks");
            let r = document.querySelector(`#marks table input[data-discip="${e.discip}"]`);
            r.value = t;
            r.id = e.id;
          });
        })
        .catch(() => {
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
      server.dis(this.blocked, "marks");
    },
    marksupdate() {
      this.loadchange(false);
      server.dis(true, "marks");
      let oc = []; 
      let sum = 0;
      let sumcount = 0;
      document.querySelectorAll("#marks input").forEach(el=>{
        if (el.value>2 && el.value<6){
          oc.push({id: el.id, ocenka: el.value});
          sum += parseInt(el.value);
          sumcount++;
        }
        else{
          el.value = "";
        }
      });
      this.getavg = (sum /sumcount).toFixed(2) || 0;

      server
        .submittoserver(server.urlsecond.abiturient.setocenk, oc, "PUT")
        .then((res) => {
          if (res.error && res.error == 403) {
            this.logoutweb();
          }
          server.messages(res, "marks", [
            { mess: "message", class: "success" },
          ]);
        })
        .catch(() => {
          this.loadchange(true);
          server.toasts("Ошибка на сервере", "error");
        });
      this.loadchange(true);
    },
  },
};
</script>
